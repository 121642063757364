import {appEndPoint} from 'configs/appConfigs';
import {buildRequestUrl} from 'helpers/apiHelper';

const {END_POINT_MASTER_DATA} = appEndPoint;
const {END_POINT_THIRD_PARTY} = appEndPoint;

//#region FAQ
const faqUri = 'faq';
export const FAQ = {
  postLikeFaq: buildRequestUrl(END_POINT_MASTER_DATA, `${faqUri}/like`),
  postSearchFaq: buildRequestUrl(END_POINT_MASTER_DATA, `${faqUri}/search`),
};
//#endregion

//#region Terms and Condition
const termsConditionUri = 'terms-condition';
export const TERMS_CONDITION = {
  getTermsAndConditionDetails: buildRequestUrl(
    END_POINT_MASTER_DATA,
    `${termsConditionUri}/details`
  ),
};
//#endregion

//#region Feedback
const feedbackUri = 'feedback';
export const FEEDBACK = {
  getAllFeedbackCategories: buildRequestUrl(END_POINT_MASTER_DATA, `${feedbackUri}/category/all`),
};
//#endregion

//#region Checkin
export const CHECK_IN = {
  postCreateUserCheckin: buildRequestUrl(END_POINT_MASTER_DATA, `user-check-in/create`),
  getCurrentUserCheckin: buildRequestUrl(END_POINT_MASTER_DATA, `checkin/current`),
};
//#endregion

//#region Gift
const giftUri = 'gift';
export const GIFT = {
  getGiftZone: buildRequestUrl(END_POINT_MASTER_DATA, `${giftUri}/get-zone`),
  getMaxMinReward: buildRequestUrl(END_POINT_MASTER_DATA, `${giftUri}/reward/max-min`),
  getMaxMinRewardByMerchant: (merchantId) =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${giftUri}/reward/max-min/${merchantId}`),
  getGiftDetail: (giftId) => buildRequestUrl(END_POINT_MASTER_DATA, `${giftUri}/details/${giftId}`),
  postGiftsOnTopMobile: buildRequestUrl(END_POINT_MASTER_DATA, `${giftUri}/mobile/top`),
  postSearchGiftsByFilterSettings: buildRequestUrl(
    END_POINT_MASTER_DATA,
    `${giftUri}/search/filter-setting`
  ),
  postSearchGiftsMobile: buildRequestUrl(END_POINT_MASTER_DATA, `${giftUri}/search/mobile`),
  getGiftByKeyword: buildRequestUrl(END_POINT_MASTER_DATA, `${giftUri}/search-mobile/keyword`),
};
//#endregion

//#region Gift
const giftTypeUri = 'gift-type';
export const GIFT_TYPE = {
  getAllGiftTypes: buildRequestUrl(END_POINT_MASTER_DATA, `${giftTypeUri}/all`),
};
//#endregion

//#region Rating
const ratingUri = 'rating';
export const RATING = {
  postCreateRating: buildRequestUrl(END_POINT_MASTER_DATA, `${ratingUri}/create`),
  postSummarizeRating: buildRequestUrl(END_POINT_MASTER_DATA, `${ratingUri}/summarize`),
  postSearchRating: buildRequestUrl(END_POINT_MASTER_DATA, `${ratingUri}/search`),
};
//#endregion

//#region Sub Category
const subCategoryUri = 'sub-category';
export const SUB_CATEGORY = {
  postSearchSubCategory: buildRequestUrl(END_POINT_MASTER_DATA, `${subCategoryUri}/search`),
};
//#endregion

//#region Loyalty Program
const loyaltyProgramUri = 'loyalty-program';
export const LOYALTY_PROGRAM = {
  getLoyaltyProgramById: (programId) =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${loyaltyProgramUri}/${programId}`),
  getLoyaltyProgramDetailById: (programId) =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${loyaltyProgramUri}/details-for-mobile/${programId}`),
  getLoyaltyProgramsByMerchantId: (merchantId) =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${loyaltyProgramUri}/all/info/${merchantId}`),
  getLoyaltyProgramByKeyword: buildRequestUrl(
    END_POINT_MASTER_DATA,
    `${loyaltyProgramUri}/search-mobile/keyword`
  ),
};
//#endregion

//#region Membership Policy
const membershipPolicyUri = 'membership-policy';
export const MEMBERSHIP_POLICY = {
  getMembershipPolicyDetailByMerchantId: (merchantId) =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${membershipPolicyUri}/details/${merchantId}`),
};
//#endregion

//#region Category
const categoryUri = 'category';
export const CATEGORY = {
  getAllCategories: buildRequestUrl(END_POINT_MASTER_DATA, `${categoryUri}/all`),
};
//#endregion

//#region Merchant
const merchantUri = 'merchant';
export const MERCHANT = {
  getMerchantById: (merchantId) =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${merchantUri}/get/${merchantId}`),
  getMerchantDetailMobileById: (merchantId) =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${merchantUri}/get-mobile/${merchantId}`),
};
//#endregion

//#region Store
const storeUri = 'store';
export const STORE = {
  postSearchStore: buildRequestUrl(END_POINT_MASTER_DATA, `${storeUri}/merchant`),
  getStoreDetailByMerchant: (merchantId) =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${storeUri}/merchant/${merchantId}`, 0),
  postSearchStoreNearestUser: buildRequestUrl(END_POINT_MASTER_DATA, `${storeUri}/search/nearest-stores`,0),
  postSearchStores: buildRequestUrl(END_POINT_THIRD_PARTY, `v1/nuti-food/search-merchant-store`,0),
};
//#endregion

//#region Brand
const brandUri = 'brand';
export const BRAND = {
  getAllBrands: buildRequestUrl(END_POINT_MASTER_DATA, `${brandUri}/all`),
};
//#endregion
//#region Brand
const notificationDataUri = 'notification-data';
export const NOTIFICATION_DATA = {
  getAllNotificationTypes: buildRequestUrl(
    END_POINT_MASTER_DATA,
    `${notificationDataUri}/type/all`
  ),
};
//#endregion

//#region USER GUIDE
const userGuideUri = 'user-guide';
export const USER_GUIDE = {
  getUserGuideDetails: (displayLocation) =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${userGuideUri}/mobile/${displayLocation}`),
};
//#endregion

//#region Tier
const tierUri = 'tier';
export const TIER = {
  getTiersByProgram: (programId) =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${tierUri}/program/${programId}`),
  getTierDetailById: (tierId) =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${tierUri}/get-details/${tierId}`),
};
//#endregion

//#region Promotion
const promotionUri = 'promotion';
export const PROMOTION = {
  postSearchPromotionForGift: buildRequestUrl(
    END_POINT_MASTER_DATA,
    `${promotionUri}/search-for-gift`
  ),
  postSearchPromotionForUser: buildRequestUrl(
    END_POINT_MASTER_DATA,
    `${promotionUri}/search-for-user`
  ),
};
//#endregion

//#region Location
const locationUri = 'location';
export const LOCATION = {
  getProvinces: buildRequestUrl(END_POINT_MASTER_DATA, `${locationUri}/provinces`),
  getDistricts: buildRequestUrl(END_POINT_MASTER_DATA, `${locationUri}/districts`),
  getDistrictByProvinceCode: (provinceCode) =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${locationUri}/districts/${provinceCode}`),
  getWardsByDistrict: (id) => buildRequestUrl(END_POINT_MASTER_DATA, `${locationUri}/wards/${id}`),
};
//#endregion

//#region Location
const dpointVersionUri = 'dpoint-version';
export const DPOINT_VERSION = {
  getAppVersionForceUpdate: (params) =>
    buildRequestUrl(
      END_POINT_MASTER_DATA,
      `${dpointVersionUri}/last-version/${params?.deviceType || 1}`
    ),
};
//#endregion

//#region Exchange Reward
const exchangeRewardUri = 'exchange-reward';
export const EXCHANGE_REWARD = {
  getAllExchangeReward: () =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${exchangeRewardUri}/all-by-tier`),
  getAllExchangeRewardByMerchantId: () =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${exchangeRewardUri}/all-by-merchant`),
  checkCanViewScreen: (merchantId) =>
    buildRequestUrl(
      END_POINT_MASTER_DATA,
      `${exchangeRewardUri}/can-view-screen?merchantId=${merchantId}`
    ),
  getExchangeRewardRedeemGift: buildRequestUrl(
    END_POINT_MASTER_DATA,
    `${exchangeRewardUri}/exchange-reward-apply-for-user`
  ),
};

//#endregion

//#region Search Keyword
const searchUri = 'search-keyword';
export const SEARCH_KEYWORD = {
  getSearchPopularKeyword: () =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${searchUri}/popular-keyword`),
  getSearchHistoryKeyword: (userId) =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${searchUri}/history/${userId}`),
  deleteSearchHistoryKeyword: (userId) =>
    buildRequestUrl(END_POINT_MASTER_DATA, `${searchUri}/delete/${userId}`),
};

//#region Review
const reviewUri = 'review';
export const REVIEW = {
  getReviewByScreen: () => buildRequestUrl(END_POINT_MASTER_DATA, `${reviewUri}/get-review`),
  submitReview: () => buildRequestUrl(END_POINT_MASTER_DATA, `${reviewUri}/save-review`),
  markReviewAsShow: () => buildRequestUrl(END_POINT_MASTER_DATA, `${reviewUri}/user-read-review`),
};
//#endregion
