import {appEndPoint} from 'configs/appConfigs';
import {buildRequestUrl} from 'helpers/apiHelper';

const {END_POINT_CMS} = appEndPoint;

//#region Action Result
const actionResultUri = 'action-result';
export const ACTION_RESULT = {
  postInquire: buildRequestUrl(END_POINT_CMS, `${actionResultUri}/inquire`),
};
//#endregion

//#region Mission
const missionUri = 'mission';
export const MISSION = {
  getDetailsMissionByMissionId: buildRequestUrl(END_POINT_CMS, `${missionUri}/details`),
  getAllMissionType: buildRequestUrl(END_POINT_CMS, `${missionUri}/mission-type/all`),
  getAllQuestionType: buildRequestUrl(END_POINT_CMS, `${missionUri}/question-type/all`),
  postMission: buildRequestUrl(END_POINT_CMS, `${missionUri}/submit`),
  postCheckUserMission: buildRequestUrl(END_POINT_CMS, `${missionUri}/check-user-mission`),
  getMaxMinFilterContent: buildRequestUrl(END_POINT_CMS, `${missionUri}/reward/max-min`),
  getMaxMinFilterContentByMerchantId: buildRequestUrl(
    END_POINT_CMS,
    `${missionUri}/reward/max-min`
  ),
};
//#endregion

//#region Content
const contentUri = 'content';
export const CONTENT = {
  postSearchEarningSuggestion: buildRequestUrl(
    END_POINT_CMS,
    `${contentUri}/search-mobile/earning`
  ),
  postContentDetail: buildRequestUrl(END_POINT_CMS, `${contentUri}/details-mobile`),
  getAllContentMobile: buildRequestUrl(END_POINT_CMS, `${contentUri}/getallcontentmobile`),
  getZoneContent: buildRequestUrl(END_POINT_CMS, `${contentUri}/get-zone`),
  putUpdateViewContent: buildRequestUrl(END_POINT_CMS, `${contentUri}/update-view`),
  getAllContentType: buildRequestUrl(END_POINT_CMS, `${contentUri}/get-type`),
  postSearchMobileContent: buildRequestUrl(END_POINT_CMS, `${contentUri}/search-mobile`),
  postSubscribeContent: buildRequestUrl(END_POINT_CMS, `${contentUri}/subscribe`),
  getSearchContent: buildRequestUrl(END_POINT_CMS, `${contentUri}/search-mobile/keyword`),
};
//#endregion
