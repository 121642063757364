import React, {useEffect, useState} from 'react';
import Layout from 'components/layout';
import './styles.scss';
import {useDispatch, useSelector} from 'react-redux';
import {getUserProfileAction} from 'appRedux/actions/auth';
import {isLogInSelector, userInfoSelector} from 'appRedux/selectors/auth';
import {toggleEditModal, toggleQRCodeModal} from 'appRedux/slices/appSlice';
import {TAB_DATA} from './constanst';
import classNames from 'classnames';
import {
  getRewardHistoryTransactionsAction,
  getRewardInfoAction,
  getUserGiftAction,
} from 'appRedux/actions/app';
import {rewardInfoSelector} from 'appRedux/selectors/app';
import {signOutAction} from 'appRedux/actions/global';
import UserGifts from './components/userGift';
import HistoryReward from './components/historyReward';
import RewardOverview from './components/rewardOverview';
import UserInfo from './components/userInfo';
import {get} from 'lodash';
import {USER_GIFT_STATUSES} from 'constants/userGift';
import ContactButton from 'components/contactButton';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ROUTE_NAME} from 'constants/routes';
const userGiftParser = (item) => {
  const userGift = get(item, 'userGifts.0', {});
  return {...item, ...userGift};
};
const UserProfile = () => {
  const [searchParams, _] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector(userInfoSelector);
  const isLogIn = useSelector(isLogInSelector);
  const rewardInfo = useSelector(rewardInfoSelector);
  const [selectedTabIndex, setSelectedTabIndex] = useState(Number(searchParams.get('tab') || 0));
  const [historyPageIndex, setHistoryPageIndex] = useState(1);
  const [historyTotalPages, setHistoryTotalPages] = useState(0);
  const [historyTransactions, setHistoryTransactions] = useState([]);

  const [giftPageIndex, setGiftPageIndex] = useState(1);
  const [giftTotalPages, setGiftTotalPages] = useState(0);
  const [userGifts, setUserGifts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [userGiftStatus, setUserGiftStatus] = useState(USER_GIFT_STATUSES.EXCHANGED);

  const handleShowEditPopup = () => {
    dispatch(toggleEditModal(true));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [historyPageIndex, giftPageIndex]);
  useEffect(() => {
    dispatch(getUserProfileAction());
    dispatch(getRewardInfoAction());
    return () => {
      dispatch(toggleEditModal(false));
    };
  }, []);

  useEffect(() => {
    if (!isLogIn) {
      navigate(ROUTE_NAME.HOME);
    }
  }, [isLogIn]);

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    dispatch(
      getRewardHistoryTransactionsAction({
        body: {
          pageIndex: historyPageIndex,
          pageSize: 10,
          programIds: [process.env.REACT_APP_PROGRAM_ID],
          sortBy: [
            {
              fieldName: 'CreatedTime',
              ascending: false,
            },
          ],
        },
        callback: (res) => {
          const {data = [], numOfPages = 0} = res;
          setHistoryTotalPages(numOfPages);
          setHistoryTransactions(data);
        },
      })
    );
  }, [historyPageIndex]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getUserGiftAction({
        body: {
          pageIndex: giftPageIndex,
          pageSize: 12,
          merchantIds: [process.env.REACT_APP_MERCHANT_ID],
          status: userGiftStatus,
          sortBy: [
            {
              fieldName: 'CreatedTime',
              ascending: true,
            },
          ],
          userId: userInfo?.id,
        },
        callback: (error, res) => {
          setIsLoading(false);
          const {data = [], numOfPages = 0} = res;
          setGiftTotalPages(numOfPages);
          setUserGifts(data?.map(userGiftParser) || []);
        },
      })
    );
  }, [giftPageIndex, userGiftStatus]);

  useEffect(() => {
    setGiftPageIndex(1);
  }, [userGiftStatus]);

  useEffect(() => {
    if (selectedTabIndex == '1') {
      document
        .getElementById('scroller-gift-profile')
        .scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, []);
  const renderTabContent = () => {
    switch (selectedTabIndex) {
      case 0:
        return <RewardOverview rewardInfo={rewardInfo} />;
      case 1:
        return (
          <UserGifts
            currentPage={giftPageIndex}
            totalPages={giftTotalPages}
            onChangePage={setGiftPageIndex}
            data={userGifts}
            userGiftStatus={userGiftStatus}
            onChangeStatus={setUserGiftStatus}
            onClickUserGift={(userGift) => {
              dispatch(toggleQRCodeModal(userGift));
            }}
            isLoading={isLoading}
          />
        );
      case 2:
        return (
          <HistoryReward
            currentPage={historyPageIndex}
            totalPages={historyTotalPages}
            data={historyTransactions}
            onChangePage={setHistoryPageIndex}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Layout
      headerProps={{
        index: 4,
      }}
    >
      <div className="container-home">
        <div className="container ">
          <div className="columns">
            <div className="column is-4-tablet is-4-desktop is-3-fullhd">
              <UserInfo
                userInfo={userInfo}
                onSignOutClick={handleSignOut}
                onEditClick={handleShowEditPopup}
              />
            </div>
            <div className="column">
              <div className="tabs is-fullwidth is-primary">
                <ul>
                  {TAB_DATA.map((item) => (
                    <li
                      key={item.index}
                      className={classNames({'is-active': selectedTabIndex === item?.index})}
                    >
                      <a onClick={() => setSelectedTabIndex(item?.index)}>
                        <span className=" has-text-weight-semibold">{item.text}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {renderTabContent()}
            </div>
          </div>
          <div className="columns">
            <div className="column is-4-tablet is-4-desktop is-3-fullhd is-hidden-mobile"></div>
            {/* <div className="column">
            <ContactButton />
          </div> */}
          </div>
          <ContactButton />
        </div>
      </div>
    </Layout>
  );
};

export default UserProfile;
