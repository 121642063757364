import React, {useEffect} from 'react';
const Contact = () => {
  return (
    <>
      <div className="columns is-mobile clearfix">
        <div
          className="column"
          style={{
            textAlign: 'center',
            marginTop: 25,
          }}
        >
          <button className="button is-primary customer-text button-radius">
            <div className="customer-text-support">Hỗ trợ khách hàng</div>
            <span className="icon-text customer-text-icon">
              <span className="icon">
                <i className="fas fa-phone"></i>
              </span>
            </span>
            <div className="customer-text-phone">028 39 433 999</div>
          </button>
        </div>
      </div>
    </>
  );
};
export default Contact;
