import {CONTACT_INFO} from 'constants/app';
import React from 'react';

const ContactButton = (props) => {
  return (
    <div className="columns ">
      <div
        className="column"
        style={{
          textAlign: 'center',
          marginTop: 24,
        }}
      >
        <a
          href={`tel:${CONTACT_INFO.phone}`}
          className="button is-primary customer-text button-radius"
        >
          <div className="customer-text-support">Hỗ trợ khách hàng</div>
          <span className="icon-text customer-text-icon">
            <span className="icon">
              <i className="fas fa-phone"></i>
            </span>
          </span>
          <div className="customer-text-phone">{CONTACT_INFO.phone}</div>
        </a>
      </div>
    </div>
  );
};

export default ContactButton;
