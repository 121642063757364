import React, {useEffect} from 'react';
import Layout from '../../components/layout';
import Carousel from '../../components/carousel';
import './styles.scss';
// import Benner from 'images/FBCover.jpg';
import icon_download from 'images/icon_download.png';
import icon_tich_diem from 'images/icon_tich_diem.png';
import icon_uu_dai from 'images/icon_uu_dai.png';
import googlePlay from 'images/googlePlay.png';
import appstore from 'images/appstore.png';
import {toggleLoginModal} from 'appRedux/slices/appSlice';
import {isLogInSelector} from 'appRedux/selectors/auth';
import {useDispatch, useSelector} from 'react-redux';
import QuickLogin from 'components/quickLogin';
import {useNavigate, useParams} from 'react-router-dom';
import FAQS from 'components/FAQs';
import ContactButton from 'components/contactButton';
import bg_store from 'images/bg_store_v1.png';
import {ROUTE_NAME} from 'constants/routes';
import {IMAGES} from 'constants/assets';
import {CONTACT_INFO} from 'constants/app';

const HomePage = () => {
  const {phoneNumber} = useParams();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const isLogIn = useSelector(isLogInSelector);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    if (!!phoneNumber && !isLogIn) {
      dispatch(toggleLoginModal(true));
    }
  }, [isLogIn, phoneNumber]);

  const toggleCardState = (e) => {
    setExpanded(!expanded);
  };
  const [active, setActive] = React.useState('');
  const changing = () => {
    if (active == '') {
      setActive('is-active');
    } else {
      setActive('');
    }
  };
  const banner = [
    {
      src: IMAGES.ImgBanner,
      onClick: () => {
        // navigate(ROUTE_NAME.GAMES);
        window.open(CONTACT_INFO.zalo, '_blank', 'noopener, noreferrer');
      },
    },
  ];
  return (
    <Layout
      headerProps={{
        index: 0,
      }}
      userProfileModalProps={{
        phoneNumber,
      }}
    >
      <Carousel
        axis="horizontal"
        interval="2000"
        showThumbs={false}
        showStatus={false}
        images={banner}
      />

      <div className="container-home">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="">
                <div className="head-title head-title-top">CHƯƠNG TRÌNH NUTIPOINT</div>
                <div className="title is-4 has-text-centered">Thông tin chương trình</div>
                <p className="" style={{fontSize: 18}}>
                  Tiên phong ứng dụng tinh hoa, thành tựu khoa học, nghiên cứu và phát triển các
                  giải pháp dinh dưỡng chuẩn Âu đáp ứng nhu cầu và thể trạng đặc thù của trẻ em và
                  người tiêu dùng Việt Nam, Nutifood không ngừng tự hoàn thiện để nâng tầm trải
                  nghiệm mua sắm của khách hàng tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng.
                  <br />
                  <br />
                  Để tri ân sự ủng hộ nhiệt tình của khách hàng, Nutifood trân trọng giới thiệu
                  chương trình khách hàng thân thiết Nutipoint với những ưu đãi, đặc quyền hấp dẫn
                  dành riêng cho thành viên.
                </p>
                <div className="title is-4 has-text-centered">Hạng thành viên</div>
                <div className="tile is-ancestor is-ancestor-top-16">
                  <div className="tile is-parent">
                    <article className="tile is-child box box-cart box-cart-first">
                      <div className="title-ratings">Hạng đồng</div>
                      <p style={{textAlign: 'left'}}>
                        <li>
                          Bạn được tích <b>1 điểm</b> cho mỗi 1,000 VNĐ chi tiêu
                        </li>
                        <li>Được đổi các ưu đãi hấp dẫn từ Nutifood</li>
                      </p>
                    </article>
                  </div>
                  <div className="tile is-parent">
                    <article className="tile is-child box box-cart box-cart-second">
                      <div className="title-ratings">Hạng bạc</div>
                      <p style={{textAlign: 'left'}}>
                        <li>
                          Bạn được tích <b>1.2 điểm</b> cho mỗi 1,000 VNĐ chi tiêu
                        </li>
                        <li>Được đổi các ưu đãi hấp dẫn dành riêng cho hạng bạc từ Nutifood</li>
                        <li>Được mua hàng với giá ưu đãi dành riêng cho hạng bạc</li>
                      </p>
                    </article>
                  </div>
                  <div className="tile is-parent">
                    <article className="tile is-child box box-cart box-cart-third">
                      <div className="title-ratings">Hạng vàng</div>
                      <p style={{textAlign: 'left'}}>
                        <li>
                          Bạn được tích <b>1.5 điểm</b> cho mỗi 1,000 VNĐ chi tiêu
                        </li>
                        <li>Được đổi các ưu đãi hấp dẫn dành riêng cho hạng vàng từ Nutifood</li>
                        <li>Được mua hàng với giá ưu đãi dành riêng cho hạng vàng</li>
                        <li>
                          Được tham gia các chương trình rút thăm trúng thưởng giá trị từ Nutifood
                        </li>
                      </p>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isLogIn && (
            <>
              <QuickLogin fromHome={true} />
            </>
          )}
          <div className="columns mt-6">
            <div className="column column-group">
              <div className="phone-img-group">
                <img src={bg_store} className="phone-img" />
              </div>
              <div className="home-group-store-isfull">
                <div className="home-group-store">
                  <span className="home-icon">
                    <a
                      href="https://apps.apple.com/vn/app/dpoint-t%C3%ADch-%C4%91i%E1%BB%83m-%C4%91%E1%BB%95i-qu%C3%A0/id1598070223"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={appstore} className="home-store-img" />
                    </a>
                  </span>
                  <span>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.dgvdigital.dpoint"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={googlePlay} className="home-store-img" />
                    </a>
                  </span>
                </div>
              </div>
              {/* <div>
                <div>
                  <img src={phone} className="phone-img" />
                </div>
                <div className="home-group-store">
                  <span className="home-icon">
                    <a
                      href="https://apps.apple.com/vn/app/dpoint-t%C3%ADch-%C4%91i%E1%BB%83m-%C4%91%E1%BB%95i-qu%C3%A0/id1598070223"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={appstore} className="home-store-img" />
                    </a>
                  </span>
                  <span>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.dgvdigital.dpoint"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={googlePlay} className="home-store-img" />
                    </a>
                  </span>
                </div>
              </div> */}
              {/* <div className="group-download-qrcode">
                <div className="home-download-text">TẢI NGAY</div>
                <div>
                  <img src={qrcode} className="qrcode-img" />
                </div>
              </div> */}
            </div>
            <div
              className="column"
              style={{
                position: 'relative',
              }}
            >
              <div className="group-coins">
                <div className="has-text-offers">
                  Nhận ưu đãi hấp dẫn Nutipoint qua ứng dụng DPoint
                </div>
                <p>
                  Chỉ cần tải app DPoint - Tích điểm & đổi quả, bạn sẽ nhận được vô vàn ưu đãi,
                  không chỉ từ Nutifood mà còn từ rất nhiều thương hiệu hấp dẫn khác. Quét QR để tải
                  ngay nào!
                </p>

                <div className="icon-text icon-text-mobile">
                  <span className="icon home-icon-group">
                    <img src={icon_download} />
                  </span>
                  <span className="home-icon-group-text">Tải DPoint và đăng kí</span>
                </div>
                <div className="icon-text icon-text-mobile">
                  <span className="icon home-icon-group">
                    <img src={icon_tich_diem} />
                  </span>
                  <span className="home-icon-group-text">Tích Điểm thành viên</span>
                </div>
                <div className="icon-text icon-text-mobile">
                  <span className="icon home-icon-group">
                    <img src={icon_uu_dai} />
                  </span>
                  <span className="home-icon-group-text">Đổi ưu đãi hấp dẫn</span>
                </div>
              </div>
            </div>
          </div>
          <FAQS />
          <div className="mt-6">
            <ContactButton />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default HomePage;
