import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import rootReducer from '../appRedux/reducers';

import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist';
import {applyMiddleware} from 'redux';
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const authInterceptor =
  ({dispatch}) =>
  (next) =>
  (action) => {
    console.log('🚀 ~ file: index.js ~ line 20 ~ authInterceptor ~ action', action);
    if (action.status === 401) {
      // dispatch(actions.removeJwt());
    } else {
      next(action);
    }
  };
const store = configureStore({
  reducer: persistedReducer,
  // middleware: applyMiddleware([... authInterceptor]),
  // devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export default store;
