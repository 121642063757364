import {actionResultTransactionInquireApi} from 'apis/transaction';
import {actionResultContentInquireApi} from 'apis/cms';
import {actionResultInquireApi} from 'apis/endUser';
import {getResultGameApi} from 'apis/game';
export const RETRY_TYPES = {
  TRANSACTION: 'transaction',
  CMS: 'cms',
  AUTH: 'auth',
  GAME: 'game',
};
const delay = async (wait) => {
  return new Promise((resolve) => setTimeout(resolve, wait));
};

export const retryApi = async (receiptNoData, type = RETRY_TYPES.AUTH) => {
  const clientRetryAfterMs = receiptNoData.data?.clientRetryAfterMs || 1000;
  const maxNumberOfRetries = receiptNoData.data?.maxNumberOfRetries || 6;
  const nextClientRetryAfterMs = receiptNoData.data?.nextClientRetryAfterMs || 1500;
  const data = receiptNoData?.data;
  let count = 1;
  let result = {};
  await delay(clientRetryAfterMs);
  while (!result?.data?.isSuccess && count <= maxNumberOfRetries) {
    if (count > 1) await delay(nextClientRetryAfterMs);
    count++;
    switch (type) {
      case RETRY_TYPES.AUTH:
        result = await actionResultInquireApi(data);
        break;
      case RETRY_TYPES.CMS:
        result = await actionResultContentInquireApi(data);
        break;
      case RETRY_TYPES.GAME:
        result = await getResultGameApi(data);
        break;
      default:
        result = await actionResultTransactionInquireApi(data);
        break;
    }
  }
  return result;
};
