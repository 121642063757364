export const FAQ_DATA = [
  {
    title: 'Nutipoint là gì? Ai có thể tham gia chương trình này?',
    description: [
      'Nutipoint là chương trình khách hàng thân thiết của hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng. Khi tham gia chương trình này, khách hàng sẽ được tận hưởng những ưu đãi, đặc quyền hấp dẫn dành riêng cho thành viên.',
      'Tất cả khách hàng mua sắm và có đăng ký thông tin tham gia chương trình khách hàng thân thiết Nutipoint tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng toàn quốc hoặc trên website www.nutifoodshop.com đều có thể tham gia chương trình này. ',
    ],
  },
  {
    title: 'Điểm tích lũy là gì? Tôi có thể sử dụng điểm tích lũy như thế nào?',
    description: [
      'Tất cả thành viên chương trình khách hàng thân thiết Nutipoint mua hàng tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng toàn quốc hoặc trên website www.nutifoodshop.com sẽ được tích lũy điểm từ mỗi giao dịch.',
      'Doanh số tích lũy để tính điểm là tổng số tiền khách hàng thanh toán thực tế theo từng hóa đơn mua sắm tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng toàn quốc hoặc trên website www.nutifoodshop.com',
      'Khách hàng có thể đổi điểm tích lũy nhận quà tặng hấp dẫn từ chương trình. Số điểm tích lũy này sẽ được duy trì trong vòng 12 tháng kể từ khi phát sinh giao dịch & được ghi nhận trên hệ thống.',
    ],
  },
  {
    title:
      'Có bao nhiêu hạng thành viên trong chương trình? Tiêu chí xét hạng thành viên như thế nào?',
    description: [
      'Có 3 hạng thành viên trong chương trình:',
      'Khách hạng đồng: Tất cả khách hàng đăng ký thông tin đầy đủ & hợp lệ',
      'Khách hạng bạc: Khách hàng đạt doanh số mua hàng tích lũy trong 12 tháng kể từ khi đăng ký từ 6 triệu đến dưới 36 triệu',
      'Khách hạng vàng: Khách hàng đạt doanh số mua hàng tích lũy trong 12 tháng kể từ khi đăng ký từ 36 triệu trở lên',
    ],
  },
  {
    title: 'Cách thức duy trì hạng thành viên hàng năm như thế nào?',
    description: [
      'Khách hạng đồng: hạng thành viên được duy trì liên tục, không giới hạn thời gian',
      'Khách hạng bạc & Khách hạng vàng: hạng thành viên được duy trì trong vòng 12 tháng (kể từ khi lên hạng)',
      'Tại thời điểm hết hạn, nếu khách hàng bạc & vàng không đạt doanh số mua hàng tích lũy đủ tiêu chuẩn duy trì sẽ bị giảm xuống hạng thành viên tương ứng với doanh số tích lũy trong 12 tháng vừa qua hoặc bị giảm xuống hạng thành viên khách hạng đồng.',
    ],
  },
  {
    title: 'Tỷ lệ tích lũy điểm như thế nào?',
    description: [
      'Tỷ lệ tích lũy điểm từ giao dịch mua hàng tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng toàn quốc hoặc trên website www.nutifoodshop.com sẽ khác nhau tùy thuộc vào hạng thành viên của khách hàng:',
      'Khách hạng đồng: mỗi chi tiêu 1,000 VNĐ = 1 điểm',
      'Khách hạng bạc: mỗi chi tiêu 1,000 VNĐ = 1.2 điểm',
      'Khách hạng vàng: mỗi chi tiêu 1,000 VNĐ = 1.5 điểm',
    ],
  },
];
