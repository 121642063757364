import {appEndPoint} from 'configs/appConfigs';
import {buildRequestUrl} from 'helpers/apiHelper';

const {END_POINT_THIRD_PARTY} = appEndPoint;

const gifts = 'nuti-food/gifts';
// export const GIFTS = {
//   getGiftAll: (merchantId) => buildRequestUrl(END_POINT_THIRD_PARTY, `${gifts}/all/${merchantId}`),
//   getGiftByUser: buildRequestUrl(END_POINT_THIRD_PARTY, `${gifts}/all-by-user`),
// };

export const GIFTS = {
  getGiftAllByMerchant: (merchantId) =>
    buildRequestUrl(END_POINT_THIRD_PARTY, `${gifts}/all/${merchantId}`),

  getGiftDetail: (id) => buildRequestUrl(END_POINT_THIRD_PARTY, `nuti-food/gift/details/${id}`),
  getGiftViewDetail: (id) =>
    buildRequestUrl(END_POINT_THIRD_PARTY, `nuti-food/gift-anonymous/details/${id}`),

  getGiftByUser: buildRequestUrl(END_POINT_THIRD_PARTY, `${gifts}/all-by-user`),

  getGiftAllUserByMerchant: (merchantId) =>
    buildRequestUrl(END_POINT_THIRD_PARTY, `${gifts}/all-by-user?merchantId=${merchantId}`),
};
