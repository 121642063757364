import {FAQ_DATA} from 'constants/faq';
import React, {useEffect} from 'react';
import Collapsible from 'react-collapsible';

const FAQS = () => {
  return (
    <>
      <div className="title has-text-primary has-text-centered has-text-weight-bold mt-6">
        CÂU HỎI THƯỜNG GẶP
      </div>
      {FAQ_DATA.map((item, index) => {
        return (
          <Collapsible
            triggerClassName=" has-text-black"
            className=" has-text-black"
            key={`item_${index}`}
            open={index == 0 ? true : false}
            trigger={item.title}
          >
            {item.description.map((desc, i) => (
              <div key={`desc_${i}`}>{desc}</div>
            ))}
          </Collapsible>
        );
      })}
    </>
  );
};
export default FAQS;
