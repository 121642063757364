import React, {useEffect} from 'react';
import Layout from '../../components/layout';
import Carousel from '../../components/carousel';
import './styles.scss';
// import Benner from 'images/FBCover.jpg';
import image_default from 'images/image_default.png';
import QuickLogin from 'components/quickLogin';
import {
  getGiftAllByMerchant,
  getGiftAllUserByMerchant,
  getUserGiftAction,
} from 'appRedux/actions/app';
import {useDispatch, useSelector} from 'react-redux';
import {isLogInSelector, userInfoSelector} from 'appRedux/selectors/auth';
import ContactButton from 'components/contactButton';
import {useNavigate} from 'react-router-dom';
import {ROUTE_NAME} from 'constants/routes';
import {get} from 'lodash';
import moment from 'moment';
import {toggleQRCodeModal} from 'appRedux/slices/appSlice';
import {isMobile, isDesktop} from 'react-device-detect';
import {IMAGES} from 'constants/assets';
import {CONTACT_INFO} from 'constants/app';

const VoucherList = () => {
  const dispatch = useDispatch();
  const isLogIn = useSelector(isLogInSelector);
  const userInfo = useSelector(userInfoSelector);
  const [dataGift, setDataGift] = React.useState([]);
  const [dataGiftUser, setDataGiftUser] = React.useState([]);
  const [dataGiftChange, setDataGiftChange] = React.useState([]);
  const [dataGiftUnChange, setDataGiftUnChange] = React.useState([]);

  const [numberOfitemsShown, setNumberOfItemsToShown] = React.useState(15);
  const [numberChangeable, setNumberChangeable] = React.useState(15);
  const [numberUnchangeable, setNumberUnchangeable] = React.useState(6);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [userGifts, setUserGifts] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(isMobile ? 2 : 3);
  const userGiftParser = (item) => {
    const userGift = get(item, 'userGifts.0', {});
    return {...item, ...userGift};
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (isLogIn) {
      dispatch(
        getGiftAllUserByMerchant({
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          callback: (data) => {
            setDataGiftUser(data);
          },
        })
      );
    } else {
      dispatch(
        getGiftAllByMerchant({
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          callback: (data) => {
            setDataGift(data);
          },
        })
      );
    }
  }, [isLogIn]);
  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getUserGiftAction({
        body: {
          pageIndex: 1,
          pageSize: pageSize,
          merchantIds: [process.env.REACT_APP_MERCHANT_ID],
          status: 1,
          sortBy: [
            {
              fieldName: 'CreatedTime',
              ascending: true,
            },
          ],
          userId: userInfo?.id,
        },
        callback: (error, res) => {
          const {data = [], numOfPages = 0} = res;
          setUserGifts(data?.map(userGiftParser) || []);
        },
      })
    );
  }, []);
  const handleShowMore = () => {
    setNumberOfItemsToShown(dataGift.length);
  };
  const handleShowMoreChange = () => {
    setNumberChangeable(dataGiftUser.filter((f) => f.canRedeemByMerchantReward).length);
  };

  const handleShowMoreUnChange = () => {
    // if (numberUnchangeable + 1 <= dataGiftUser.filter((f) => !f.canRedeemByMerchantReward).length) {
    //   setNumberUnchangeable(numberUnchangeable + 5);
    // } else {
    //   setNumberUnchangeable(dataGiftUser.filter((f) => !f.canRedeemByMerchantReward).length);
    // }
    setNumberUnchangeable(dataGiftUser.filter((f) => !f.canRedeemByMerchantReward).length);
  };
  return (
    <Layout
      headerProps={{
        index: 1,
      }}
    >
      <Carousel
        axis="horizontal"
        interval="2000"
        showThumbs={false}
        showStatus={false}
        images={[
          {
            src: IMAGES.ImgBanner,
            onClick: () => {
              // navigate(ROUTE_NAME.GAMES);
              window.open(CONTACT_INFO.zalo, '_blank', 'noopener, noreferrer');
            },
          },
        ]}
      />
      <div className="container-home">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="head-title head-title-top">ĐỔI ĐIỂM LẤY VOUCHER HOẶC QUÀ TẶNG</div>
              <p style={{fontSize: 18}}>
                Đừng quên đổi điểm tích lũy nhận quà tặng hấp dẫn từ chương trình. Số điểm tích lũy
                sẽ được duy trì trong vòng 12 tháng kể từ khi phát sinh giao dịch được ghi nhận trên
                hệ thống.
              </p>
            </div>
          </div>
          {isLogIn ? (
            <>
              {userGifts.length > 0 && (
                <>
                  <div className="voucher-group-img">
                    <div style={{display: 'flex'}}>
                      <div className="gallery-title" style={{width: '60%', textAlign: 'left'}}>
                        Ưu đãi của tôi
                      </div>
                      <div className="gallery-title" style={{width: '40%', textAlign: 'right'}}>
                        <a
                          className="gallery-title"
                          style={{textAlign: 'right'}}
                          onClick={() => {
                            navigate('/user-profile?tab=1');
                          }}
                        >
                          Xem tất cả
                        </a>
                      </div>
                    </div>
                    <div
                      className="columns is-multiline"
                      style={{display: 'flex', marginBottom: 15}}
                    >
                      {userGifts.map((item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="column is-one-quarter-desktop-gift is-half-tablet"
                              style={{padding: 10}}
                              onClick={() => dispatch(toggleQRCodeModal(item))}
                            >
                              <a>
                                <div
                                  className="card-image"
                                  style={{
                                    position: 'relative',
                                  }}
                                >
                                  <figure className="image is-image-voucher">
                                    <img src={item?.image || image_default} alt="" />
                                  </figure>
                                  <div className="gift-group-tile">
                                    <div className="voucher-name">{item?.name}</div>
                                    <div className="voucher-discount">{item?.discount}</div>
                                    <div className="voucher-group-point">
                                      <span>
                                        <span className="voucher-point">
                                          {moment(item?.expiryDateTime).isValid() &&
                                            item?.expiryDateTime && (
                                              <span className="voucher-point">{`HSD: ${moment(
                                                item.expiryDateTime
                                              ).format('DD/MM/YYYY')}`}</span>
                                            )}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}

              {dataGiftUser?.filter((f) => f.canRedeemByMerchantReward && f.remainingGifts > 0)
                .length > 0 && (
                <div className="voucher-group-img">
                  <div className="gallery-title">Ưu đãi đổi được ngay</div>
                  <div className="columns is-multiline">
                    {dataGiftUser
                      ?.filter((f) => f.canRedeemByMerchantReward && f.remainingGifts > 0)
                      .slice(0, numberChangeable)
                      .map((item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="column is-one-quarter-desktop-gift is-half-tablet"
                              style={{padding: 10}}
                            >
                              <a href={`/gift/${item.id}`}>
                                <div
                                  className="card-image"
                                  style={{
                                    position: 'relative',
                                  }}
                                >
                                  <figure className="image is-image-voucher">
                                    {item?.thumbnailImage ? (
                                      <img src={item.thumbnailImage} alt="" />
                                    ) : (
                                      <img src={image_default} alt="" />
                                    )}
                                  </figure>

                                  <div className="gift-group-tile">
                                    <div className="voucher-name">{item?.name}</div>
                                    <div className="voucher-discount">{item?.discount}</div>
                                    <div className="voucher-group-point">
                                      <span>
                                        <span className="voucher-point">
                                          {item?.rewardValue.toLocaleString()} ĐIỂM
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              )}
              {numberChangeable <
                dataGiftUser?.filter((f) => f.canRedeemByMerchantReward).length && (
                <>
                  <div className="columns clearfix">
                    <div
                      className="column"
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <button className="button-view-more" onClick={handleShowMoreChange}>
                        Xem thêm
                      </button>
                    </div>
                  </div>
                </>
              )}
              <ContactButton />
              {dataGiftUser?.filter((f) => !f.canRedeemByMerchantReward || f.remainingGifts === 0)
                .length > 0 && (
                <div className="voucher-group-img">
                  <div className="gallery-title">Ưu đãi chưa đủ điều kiện đổi</div>
                  <div className="columns is-multiline">
                    {dataGiftUser
                      ?.filter((f) => !f.canRedeemByMerchantReward || f.remainingGifts === 0)
                      .slice(0, numberUnchangeable)
                      .map((item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="column is-one-quarter-desktop-gift is-half-tablet"
                              style={{padding: 10}}
                            >
                              <a href={`/gift/${item.id}`}>
                                <div
                                  className="card-image"
                                  style={{
                                    position: 'relative',
                                  }}
                                >
                                  <figure className="image is-image-voucher">
                                    {item?.thumbnailImage ? (
                                      <img src={item.thumbnailImage} alt="" />
                                    ) : (
                                      <img src={image_default} alt="" />
                                    )}
                                  </figure>

                                  <div className="gift-group-tile">
                                    <div className="voucher-name">{item?.name}</div>
                                    <div className="voucher-discount">{item?.discount}</div>
                                    <div className="voucher-group-point">
                                      <span>
                                        <span className="voucher-point">
                                          {item?.rewardValue.toLocaleString()} ĐIỂM
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </>
                        );
                      })}
                  </div>
                  {numberUnchangeable <
                    dataGiftUser?.filter((f) => !f.canRedeemByMerchantReward).length && (
                    <>
                      <div className="columns clearfix">
                        <div
                          className="column"
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <button className="button-view-more" onClick={handleShowMoreUnChange}>
                            Xem thêm
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <div className="voucher-group-img">
                {dataGift?.slice(0, numberOfitemsShown).map((item, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="column is-one-quarter-desktop-gift is-half-tablet"
                        style={{padding: 10}}
                      >
                        <a href={`/gift/${item.id}`}>
                          <div
                            className="card-image"
                            style={{
                              position: 'relative',
                            }}
                          >
                            <figure className="image is-image-voucher">
                              {item?.thumbnailImage ? (
                                <img src={item.thumbnailImage} alt="" />
                              ) : (
                                <img src={image_default} alt="" />
                              )}
                            </figure>

                            <div className="gift-group-tile">
                              <div className="voucher-name">{item?.name}</div>
                              <div className="voucher-discount">{item?.discount}</div>
                              <div className="voucher-group-point">
                                <span>
                                  <span className="voucher-point">
                                    {item?.rewardValue.toLocaleString()} ĐIỂM
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </>
                  );
                })}
              </div>
              {numberOfitemsShown < dataGift?.length && (
                <>
                  <div className="columns clearfix">
                    <div
                      className="column"
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <button className="button-view-more" onClick={handleShowMore}>
                        Xem thêm
                      </button>
                    </div>
                  </div>
                </>
              )}
              <QuickLogin fromHome={false} />

              <ContactButton />
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};
export default VoucherList;
