import {appEndPoint} from 'configs/appConfigs';
import {buildRequestUrl} from 'helpers/apiHelper';

const {END_POINT_GAME} = appEndPoint;
const {END_POINT_THIRD_PARTY} = appEndPoint;
//#region Game
const gameUri = 'game';
export const GAME = {
  getAllGames: buildRequestUrl(END_POINT_GAME, `${gameUri}/all`),
};
//#endregion
export const GAME_RESULT = {
  postInquire: buildRequestUrl(END_POINT_GAME, `action-result/inquire`),
};
//#region Game Category
const gameCategoryUri = 'game-category';
export const GAME_CATEGORY = {
  getAllGameCategories: buildRequestUrl(END_POINT_GAME, `${gameCategoryUri}/all`),
};
//#endregion

//#region Game Setting
const gameSettingUri = 'game-setting';
const nutiUri = 'v1/nuti-food';
export const GAME_SETTING = {
  postSearchGameSetting: buildRequestUrl(END_POINT_GAME, `${gameSettingUri}/mobile/search`),
  postGenerateCodeGame: buildRequestUrl(END_POINT_GAME, `${gameSettingUri}/generate-result`),
  getGameSettingDetailById: (gameSettingId) =>
    buildRequestUrl(END_POINT_GAME, `${gameSettingUri}/details/${gameSettingId}`),
  postSearchGameSettingLottery: buildRequestUrl(END_POINT_GAME, `${gameSettingUri}/lottery/search`),
  postSearchGameSettingHistory: buildRequestUrl(
    END_POINT_GAME,
    `${gameSettingUri}/mobile/history/search`
  ),
  postGameSettingHistory: buildRequestUrl(END_POINT_GAME, `${gameSettingUri}/history`),

  getGameSettingDetailByMerchant: (merchantId) =>
    buildRequestUrl(END_POINT_THIRD_PARTY, `${nutiUri}/game-setting-details/${merchantId}`, 0),
  getTurnOfPlaySetting: (gameSettingId) =>
    buildRequestUrl(
      END_POINT_THIRD_PARTY,
      `${nutiUri}/user-turn-of-play/game-setting/${gameSettingId}`,
      0
    ),
  getLuckDrawRewardDetail: buildRequestUrl(
    END_POINT_THIRD_PARTY,
    `${nutiUri}/lucky-draw-reward-detail/search`,
    0
  ),
  getTurnOfPlayGameSetting: buildRequestUrl(END_POINT_GAME, `${gameSettingUri}/generate-result`),
  postLuckyDrawDetailFirstPrize: buildRequestUrl(
    END_POINT_GAME,
    `lucky-draw-reward-detail/update`,
    0
  ),
  postRemainingReward: buildRequestUrl(
    END_POINT_THIRD_PARTY,
    `nuti-food/${gameSettingUri}/number-of-rewards`
  ),
};

//#endregion
