import React, {useState} from 'react';
import logo from 'images/logo.png';
import {Link} from 'react-router-dom';

import './styles.scss';
import {ROUTE_NAME} from 'constants/routes';
import {CONTACT_INFO} from 'constants/app';

const AppFooter = () => {
  return (
    <div className="footer has-background-primary clearfix">
      <div className="container">
        <div className=" columns">
          <div className="column">
            <div className=" is-size-3 has-text-white" style={{maxWidth: 252}}>
              <div className="content" style={{fontSize: 20, fontWeight: 700}}>
                CÔNG TY CỔ PHẦN
                <br />
                THỰC PHẨM DINH DƯỠNG
                <br />
                NUTIFOOD BÌNH DƯƠNG
                {/* <Link to={ROUTE_NAME.HOME}>
                  <a>
                    <img src={logo} width={160} alt="logo" />
                  </a>
                </Link> */}
              </div>
              {/* <div className="">
                <span className="icon mr-5 is-size-1">
                  <a
                    style={{color: 'unset'}}
                    href="https://www.facebook.com/Nutifoodngoinhadinhduong"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </span>
              </div> */}
            </div>
          </div>
          <div className="column">
            <div className="content has-text-white">
              <div className="title has-text-white has-text-weight-bold">Thông tin</div>
              <p>{CONTACT_INFO.info}</p>
            </div>
          </div>
          <div className="column is-1" />
          <div className="column ">
            <div className="content has-text-white">
              <div className="title has-text-white has-text-weight-bold">Liên hệ</div>
              <div>
                <span className="icon-text mb-4 is-block" style={{lineBreak: 'anywhere'}}>
                  <span className="icon">
                    <i className="fa-solid fa-phone"></i>
                  </span>
                  <span>{CONTACT_INFO.phone}</span>
                </span>
              </div>
              <div>
                <span className="icon-text mb-4 is-block" style={{lineBreak: 'anywhere'}}>
                  <span className="icon">
                    <i className="fa-solid fa-envelope"></i>
                  </span>
                  <span>{CONTACT_INFO.email}</span>
                </span>
              </div>
              <div>
                <span className="icon-text is-block mb-4" style={{lineBreak: 'anywhere'}}>
                  <span className="icon">
                    <i className="fa-solid fa-house"></i>
                  </span>
                  <span>{CONTACT_INFO.address}</span>
                </span>
              </div>
              <div>
                <span className="icon-text is-block" style={{lineBreak: 'anywhere'}}>
                  <span className="icon">
                    <i className="fa-brands fa-facebook"></i>
                  </span>
                  <span>{CONTACT_INFO.facebookAddress}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
