import {generateOTPAction} from 'appRedux/actions/auth';
import {userProfileModalSelector} from 'appRedux/selectors/app';
import {toggleModal} from 'appRedux/slices/appSlice';
import classNames from 'classnames';
import {MODAL_STATES} from 'components/UserProfileModal/constanst';
import {CONTACT_INFO} from 'constants/app';
import {OTP_TYPES} from 'constants/otp';
import {useFormik} from 'formik';
import {get} from 'lodash';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';

const requireText = 'Bắt buộc';
const LOGIN_FORM_SCHEME = Yup.object().shape({
  phoneNumber: Yup.string()
    .required(requireText)
    .matches(/^[0]/, 'Số điện thoại phải bắt đầu bằng số 0')
    .matches(
      /^[0][0-9]{9,9}\b|^[1-9][0-9]{9,9}\b|^[a-z][a-z0-9]{4,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/,
      'Số điện thoại không hợp lệ'
    ),
});

const QuickLoginGame = ({
  fromHome = true,
  text = 'Đăng nhập/Đăng ký để tham gia trò chơi ngay',
}) => {
  const dispatch = useDispatch();
  const userProfileModalData = useSelector(userProfileModalSelector);
  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    enableReinitialize: true,

    validationSchema: LOGIN_FORM_SCHEME,
    onSubmit: (values) => {
      handleGenerateOtp(values.phoneNumber);
    },
  });

  const handleGenerateOtp = (phoneNumber) => {
    if (!phoneNumber) return;
    dispatch(
      generateOTPAction({
        phoneNumber,
        callback: (otpType) => {
          let modalState = '';
          switch (otpType) {
            case OTP_TYPES.QUICK_LOGIN:
              // setModalState(MODAL_STATES.OTP);
              modalState = MODAL_STATES.OTP;
              break;
            case OTP_TYPES.REGISTER:
              modalState = MODAL_STATES.REGISTER;
              // setModalState(MODAL_STATES.REGISTER);
              break;

            default:
              break;
          }
          if (modalState) {
            dispatch(
              toggleModal({
                show: true,
                modalState,
                phoneNumber,
              })
            );
          }
        },
      })
    );
  };

  return (
    <div className="clearfix">
      <div className="columns">
        <div className="column mx-4">
          <div
            className="gift-group-member"
            style={{background: '#fff', border: '4px solid #08633D', opacity: 0.9}}
          >
            <div className="has-text-centered title is-3 " style={{fontSize: 28}}></div>

            <div className="field is-horizontal">
              <div className="field-body is-align-items-center input-game">
                <div className="field gift-field-label">
                  <div className="gift-group-member-label">{text}</div>
                </div>
                <div className="field is-grouped ">
                  <div className="control is-expanded">
                    <input
                      className="input"
                      type="tel"
                      pattern="^-?[0-9]\d*\.?\d*$"
                      placeholder="Nhập số điện thoại"
                      maxLength={10}
                      value={formik.values.phoneNumber}
                      onChange={(e) => {
                        formik.setFieldTouched('phoneNumber', true, true);
                        formik.setFieldValue('phoneNumber', e.target.value);
                      }}
                    />
                    {get(formik.touched, 'phoneNumber', false) &&
                      !!get(formik.errors, 'phoneNumber') && (
                        <p className="help mb-0 is-danger">
                          <span>{get(formik.errors, 'phoneNumber')}</span>
                        </p>
                      )}
                  </div>
                  <button
                    className={classNames('button is-primary', {
                      'is-loading': userProfileModalData?.isLoggingIn,
                    })}
                    style={{borderColor: fromHome ? 'white' : undefined}}
                    onClick={() => {
                      formik.submitForm();
                    }}
                    disabled={!formik.isValid}
                  >
                    Tiếp tục
                  </button>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickLoginGame;
