import {appEndPoint} from 'configs/appConfigs';
import {buildRequestUrl} from 'helpers/apiHelper';

const {END_POINT_TRANSACTION, END_POINT_MASTER_DATA} = appEndPoint;

//#region User Gift
const userGiftUri = 'user-gift';
export const USER_GIFT = {
  postGenerateUserGiftCode: buildRequestUrl(END_POINT_TRANSACTION, `${userGiftUri}/code/generate`),
  getUserGiftCodeDetail: (userGiftId) =>
    buildRequestUrl(END_POINT_TRANSACTION, `${userGiftUri}/detail-gift-code/${userGiftId}`),
  getRedeemedGiftDetail: (userGiftId) =>
    buildRequestUrl(END_POINT_TRANSACTION, `${userGiftUri}/detail/${userGiftId}`),
  putUseProductSerial: (userGiftId) =>
    buildRequestUrl(END_POINT_TRANSACTION, `${userGiftUri}/product-serial/used/${userGiftId}`),
  postSearchRedeemGift: buildRequestUrl(END_POINT_TRANSACTION, `${userGiftUri}/search`),
};
//#endregion

//#region Action Result
const actionResultUri = 'action-result';
export const ACTION_RESULT = {
  postInquire: buildRequestUrl(END_POINT_TRANSACTION, `${actionResultUri}/inquire`),
};
//#endregion

//#region Referral
const referralUri = 'referral';
export const REFERRAL = {
  getSumOfReferral: buildRequestUrl(END_POINT_TRANSACTION, `${referralUri}/sum`),
  postInviteReferral: buildRequestUrl(END_POINT_TRANSACTION, `${referralUri}/invite`),
  postInviteReferralLoyaltyProgram: buildRequestUrl(
    END_POINT_TRANSACTION,
    `${referralUri}/invite-loyalty`
  ),
  postReInviteReferral: buildRequestUrl(END_POINT_TRANSACTION, `${referralUri}/reinvite`),
  postSearchReferral: buildRequestUrl(END_POINT_TRANSACTION, `${referralUri}/search/own`),
  getInviteReferralByMerchant: (merchantId) =>
    `${END_POINT_TRANSACTION}/${referralUri}/all/${merchantId}`,
};
//#endregion

//#region Reward History
const rewardHistoryUri = 'reward-history';
export const REWARD_HISTORY = {
  postSearchLoyaltyProgram: buildRequestUrl(
    END_POINT_TRANSACTION,
    `${rewardHistoryUri}/search-program`
  ),
  postOwnRewardHistory: buildRequestUrl(END_POINT_TRANSACTION, `${rewardHistoryUri}/own`),
  getRewardHistoryById: (ownRewardHistoryId) =>
    buildRequestUrl(END_POINT_TRANSACTION, `${rewardHistoryUri}/${ownRewardHistoryId}`),
};
//#endregion

//#region Retail Transaction
const retailTransactionUri = 'retail-transaction';
export const RETAIL_TRANSACTION = {
  putUpdateRetailTransaction: buildRequestUrl(
    END_POINT_TRANSACTION,
    `${retailTransactionUri}/user/update`
  ),
  getRetailTransactionDetailById: (transactionId) =>
    buildRequestUrl(END_POINT_TRANSACTION, `${retailTransactionUri}/user/details/${transactionId}`),
  postCreateRetailFeedback: buildRequestUrl(
    END_POINT_TRANSACTION,
    `${retailTransactionUri}/user/create/retail-feedback`
  ),
};
//#endregion

//#region User Product
const userProductUri = 'user-product';
export const USER_PRODUCT = {
  getVerifyUserProduct: (exchangeCode) =>
    buildRequestUrl(END_POINT_TRANSACTION, `${userProductUri}/verify/${exchangeCode}`),
  postExchangeTransaction: buildRequestUrl(
    END_POINT_TRANSACTION,
    `${userProductUri}/exchange-transaction`
  ),
};
//#endregion

//#region Redeem Transaction
const redeemTransactionUri = 'redeem-transaction';
export const REDEEM_TRANSACTION = {
  postCreateRedeemGift: buildRequestUrl(
    END_POINT_TRANSACTION,
    `${redeemTransactionUri}/gift/create`,
    0
  ),
};
//#endregion

//#region Reward
const rewardUri = 'reward';
export const REWARD = {
  getUserReward: buildRequestUrl(END_POINT_TRANSACTION, `${rewardUri}/user`),
  getTransactionExpired: buildRequestUrl(END_POINT_TRANSACTION, `${rewardUri}/search-expiry`),
};

//#endregion

//#region ReferralLoyalty
const referralLoyaltyUri = 'referral-loyalty';
export const REFERRAL_LOYALTY = {
  postInvite: buildRequestUrl(END_POINT_TRANSACTION, `${referralLoyaltyUri}/invite`),
  postReInvite: buildRequestUrl(END_POINT_TRANSACTION, `${referralLoyaltyUri}/reinvite`),
};
//#endregion

//#region Referral Transaction
const referralTransactionUri = 'referral-transaction';
export const REFERRAL_TRANSACTION = {
  getReferralTransactionById: (transactionId) =>
    buildRequestUrl(END_POINT_TRANSACTION, `${referralTransactionUri}/details/${transactionId}`),
};
//#endregion

//#region Compute
const computeUri = 'compute';
export const COMPUTE = {
  postComputeGiftRedemption: buildRequestUrl(
    END_POINT_TRANSACTION,
    `${computeUri}/gift-redemption`
  ),
};
//#endregion

//#region Exchange Reward
const exchangeRewardUri = 'exchange-reward-transaction';
export const EXCHANGE_REWARD = {
  createExchangeReward: () => buildRequestUrl(END_POINT_TRANSACTION, `${exchangeRewardUri}/create`),
  getExchangeRewardDetail: (transactionId) =>
    buildRequestUrl(END_POINT_TRANSACTION, `${exchangeRewardUri}/details/${transactionId}`),
};
//#endregion
