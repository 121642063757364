import React from 'react';
import Layout from '../../components/layout';
import Select from 'react-select';
import {
  getProvinces,
  getDistrictByProvinceCodeAction,
  getWardByDistrictCodeAction,
  getStoreDetailByMerchant,
  getStoreNearestUserByMerchant,
  getStoresByMerchant,
} from 'appRedux/actions/app';
import {useDispatch, useSelector} from 'react-redux';
import Contact from 'components/contactButton';
import market from 'images/market.png';
import {Pagination} from 'react-bulma-components';
import phone from 'images/phone.png';
import filter from 'images/filter.png';
import './styles.scss';
import {Modal} from 'react-bulma-components';
import {isMobile} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';

const Store = () => {
  const dispatch = useDispatch();
  const [dataProvince, setDataProvince] = React.useState([]);
  const [dataDistrict, setDataDistrict] = React.useState([]);
  const [dataWard, setDataWard] = React.useState([]);
  const [dataFilter, setDataFilter] = React.useState({
    province: '',
    district: '',
    ward: '',
  });
  const [dataFilterMobile, setDataFilterMobile] = React.useState({
    province: '',
    district: '',
    ward: '',
  });

  const [storeList, setStoreList] = React.useState([]);
  const [storeListFilter, setStoreListFilter] = React.useState([]);
  const [currentPosition, setCurrentPosition] = React.useState({
    latitude: 0,
    longitude: 0,
  });
  const [pages, setPages] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isFetching, setIsFetching] = React.useState(false);
  const [perPage, setPerPage] = React.useState(0);
  const [allowGetPosition, setAllowGetPosition] = React.useState(false);
  const [showFilterStoreModal, setShowFilterStoreModal] = React.useState(false);
  const [totalStorePages, setTotalStorePages] = React.useState(0);
  const [currentStorePage, setCurrentStorePage] = React.useState(1);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const refreshPage = () => {
    navigate(0);
  };
  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(accept, denied, {
      enableHighAccuracy: true,
    });
    function accept(position) {
      setAllowGetPosition(true);
      setCurrentPosition({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
      dispatch(
        getStoreNearestUserByMerchant({
          body: {
            pageIndex: currentPage,
            pageSize: 5,
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            province: dataFilter.province,
            district: dataFilter.district,
            ward: dataFilter.ward,
          },
          callback: (data) => {
            setStoreList(data.data.data);
            setStoreListFilter(data.data.data);
          },
        })
      );
      dispatch(
        getProvinces({
          callback: (data) => {
            let provinces = [
              {
                shortName: 'a',
                value: '',
                label: 'Tất cả',
              },
            ];
            data.map((item) => {
              provinces.push({
                label: item.name,
                value: item.code,
                shortName: item.shortName,
              });
            });
            setDataProvince(provinces.sort((a, b) => a.shortName.localeCompare(b.shortName)));
          },
        })
      );
    }
    function denied() {
      setAllowGetPosition(false);
      dispatch(
        getProvinces({
          callback: (data) => {
            let provinces = [
              {
                shortName: 'a',
                value: '',
                label: 'Tất cả',
              },
            ];
            data.map((item) => {
              provinces.push({
                label: item.name,
                value: item.code,
                shortName: item.shortName,
              });
            });
            setDataProvince(provinces.sort((a, b) => a.shortName.localeCompare(b.shortName)));
          },
        })
      );
      dispatch(
        getStoresByMerchant({
          body: {
            pageIndex: currentStorePage,
            pageSize: 10,
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            province: dataFilter.province,
            district: dataFilter.district,
            ward: dataFilter.ward,
          },
          callback: (data) => {
            setStoreList(data.data.data);
            setStoreListFilter(data.data.data);
            setTotalStorePages(data.data.numOfPages);
          },
        })
      );
    }
  }, []);

  React.useEffect(() => {
    if (allowGetPosition) {
      dispatch(
        getStoreNearestUserByMerchant({
          body: {
            pageIndex: currentPage,
            pageSize: 5,
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            latitude: currentPosition.latitude,
            longitude: currentPosition.longitude,
            province: dataFilter.province,
            district: dataFilter.district,
            ward: dataFilter.ward,
          },
          callback: (data) => {
            setStoreList(data.data.data);
            setStoreListFilter(data.data.data);
          },
        })
      );
    } else {
      dispatch(
        getStoresByMerchant({
          body: {
            pageIndex: currentStorePage,
            pageSize: 10,
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            province: dataFilter.province,
            district: dataFilter.district,
            ward: dataFilter.ward,
          },
          callback: (data) => {
            setStoreList(data.data.data);
            setStoreListFilter(data.data.data);
            setTotalStorePages(data.data.numOfPages);
          },
        })
      );
    }
  }, [dataFilter?.province, dataFilter?.district, dataFilter?.ward, currentStorePage]);

  React.useEffect(() => {
    let unmounted = true;
    if (unmounted) {
      if (!isFetching) return;
      fetchMoreListItems();
    }

    return () => {
      unmounted = false;
    };
  }, [isFetching]);

  const getDistrictByProvinceId = async (provinceCode) => {
    dispatch(
      getDistrictByProvinceCodeAction({
        provinceCode: provinceCode,
        callback: (data) => {
          let districts = [
            {
              label: 'Bỏ chọn',
              value: '',
            },
          ];
          data.map((item) => {
            districts.push({
              label: item.name,
              value: item.code,
            });
          });
          setDataDistrict(districts);
        },
      })
    );
  };
  const getWardByDistrictId = async (districtCode) => {
    dispatch(
      getWardByDistrictCodeAction({
        districtCode: districtCode,
        callback: (data) => {
          let wards = [
            {
              label: 'Bỏ chọn',
              value: '',
            },
          ];
          data.map((item) => {
            wards.push({
              label: item.name,
              value: item.code,
            });
          });
          setDataWard(wards);
        },
      })
    );
  };
  const onChangeSelect = (name) => (event) => {
    setPerPage(0);
    // setCurrentPage(1);
    setPages(10);
    setCurrentStorePage(1);
    if (name == 'province') {
      setDataFilter({
        ...dataFilter,
        province: event.value,
        district: '',
      });
      if (event.value) {
        getDistrictByProvinceId(event.value);
        setStoreListFilter(storeList?.filter((item) => item.location.province == event.value));
      } else {
        setStoreListFilter(storeList);
      }
    }
    if (name == 'district') {
      setDataFilter({
        ...dataFilter,
        district: event.value,
        ward: '',
      });
      if (event.value) {
        getWardByDistrictId(event.value);
        setStoreListFilter(storeList?.filter((item) => item.location.district == event.value));
      } else {
        setStoreListFilter(
          storeList?.filter((item) => item.location.province == dataFilter.province)
        );
      }
    } else if (name == 'ward') {
      setDataFilter({
        ...dataFilter,
        ward: event.value,
      });
      if (event.value) {
        setStoreListFilter(storeList?.filter((item) => item.location.ward == event.value));
      } else {
        setStoreListFilter(
          storeList?.filter((item) => item.location.district == dataFilter.district)
        );
      }
    }
  };

  const onChangeSelectFilter = (name) => (event) => {
    setPerPage(0);
    setCurrentPage(1);
    setPages(10);
    if (name == 'province') {
      setDataFilter({
        ...dataFilter,
        province: event.value,
        district: '',
      });
      if (event.value) {
        getDistrictByProvinceId(event.value);
      }
    } else if (name == 'district') {
      setDataFilter({
        ...dataFilter,
        district: event.value,
        ward: '',
      });
      if (event.value) {
        getWardByDistrictId(event.value);
      }
    } else if (name == 'ward') {
      setDataFilter({
        ...dataFilter,
        ward: event.value,
      });
    }
  };

  const onChangeSelectFilterMobile = (name) => (event) => {
    setPerPage(0);
    setCurrentPage(1);
    setPages(10);
    if (name == 'province') {
      setDataFilterMobile({
        ...dataFilterMobile,
        province: event.value,
        district: '',
      });
      if (event.value) {
        getDistrictByProvinceId(event.value);
      }
    } else if (name == 'district') {
      setDataFilterMobile({
        ...dataFilterMobile,
        district: event.value,
        ward: '',
      });
      if (event.value) {
        getWardByDistrictId(event.value);
      }
    } else if (name == 'ward') {
      setDataFilterMobile({
        ...dataFilterMobile,
        ward: event.value,
      });
    }
  };

  const onChangePage = (page) => {
    setCurrentPage(page);
    setPages(page * 10);
    setPerPage(page * 10 - 10);
    window.scrollTo(0, 0);
  };

  const onChangeStorePage = (page) => {
    setCurrentStorePage(page);
  };

  const handleScroll = () => {
    if (
      Math.round(
        document.getElementById('scroll-store').scrollTop +
          document.getElementById('scroll-store').offsetHeight
      ) !== document.getElementById('scroll-store').scrollHeight ||
      isFetching
    )
      return;
    setIsFetching(true);
  };
  const fetchMoreListItems = () => {
    let pageIndex = currentPage + 1;
    dispatch(
      getStoreNearestUserByMerchant({
        body: {
          pageIndex: pageIndex,
          pageSize: 5,
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          latitude: currentPosition.latitude,
          longitude: currentPosition.longitude,
          province: dataFilter.province,
          district: dataFilter.district,
          ward: dataFilter.ward,
        },
        callback: (data) => {
          setStoreList([...storeList, ...data.data.data]);
          setStoreListFilter([...storeList, ...data.data.data]);
          setCurrentPage(pageIndex);
          setIsFetching(false);
        },
      })
    );
  };
  const handleFilter = (event) => {
    setShowFilterStoreModal(!showFilterStoreModal);
    dispatch(
      getStoreNearestUserByMerchant({
        body: {
          pageIndex: 1,
          pageSize: 5,
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          latitude: currentPosition.latitude,
          longitude: currentPosition.longitude,
          province: dataFilterMobile.province,
          district: dataFilterMobile.district,
          ward: dataFilterMobile.ward,
        },
        callback: (data) => {
          setStoreList(data.data.data);
          setStoreListFilter(data.data.data);
        },
      })
    );
  };
  const totalPages = Math.ceil(storeListFilter?.length / 10);
  return (
    <Layout
      headerProps={{
        index: 3,
      }}
    >
      {allowGetPosition ? (
        isMobile ? (
          <div className="container-home">
            <div className="container">
              <div className="head-title head-title-top">HỆ THỐNG CỬA HÀNG NUTIFOOD</div>
              <div className="columns">
                <div className="column">
                  <div
                    style={{textAlign: 'center', display: 'grid', gridTemplateColumns: 'auto auto'}}
                    className="column text-center"
                  >
                    <div style={{fontSize: 20, fontWeight: 60}}>
                      <span style={{}}>Cửa hàng Gần bạn</span>
                    </div>
                    <div
                      className="field"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row-reverse',
                        paddingTop: '4px',
                      }}
                      onClick={() => setShowFilterStoreModal(!showFilterStoreModal)}
                    >
                      <label className="label" style={{color: '#08633D'}}>
                        Lọc khu vực
                      </label>
                      <img
                        src={filter}
                        style={{height: 18, color: '#08633D', marginRight: '4px'}}
                      />
                    </div>
                  </div>
                  {storeListFilter.length > 0 && (
                    <div
                      className="card"
                      style={{
                        border: '1px solid #D2D2D2',
                      }}
                    >
                      <div className="card-content">
                        <div className="columns">
                          <div className="column">
                            <div
                              style={{height: 400, overflowX: 'hidden'}}
                              onScroll={handleScroll}
                              id="scroll-store"
                            >
                              {storeListFilter?.map((item, index) => {
                                return (
                                  <>
                                    <div className="columns is-border-column">
                                      <div className="column has-text-weight-semibold column-display-mobile-one">
                                        <div className="has-text-primary">
                                          {item?.name}{' '}
                                          <span style={{color: '#9E9D9C'}}>{item?.distance / 1000} km</span>
                                        </div>
                                        {item?.phone && (
                                          <div className="icon-text">
                                            <span className="icon" style={{color: '#08633D'}}>
                                              <img src={phone} style={{height: 18}} />
                                            </span>
                                            <span>{item?.phone}</span>
                                          </div>
                                        )}
                                      </div>
                                      <div className="column has-text-weight-semibold column-display-mobile">
                                        <div
                                          style={{
                                            position: 'relative',
                                          }}
                                        >
                                          {item?.location.address && (
                                            <div
                                              style={{
                                                position: 'absolute',
                                                top: '60%',
                                                transform: 'translate(0, -50%)',
                                              }}
                                            >
                                              <img
                                                src={market}
                                                style={{
                                                  height: 20,
                                                  width: 16,
                                                }}
                                              />
                                            </div>
                                          )}
                                          <div
                                            style={{
                                              marginLeft: 30,
                                            }}
                                          >
                                            <a
                                              href={
                                                'https://www.google.com/maps/dir/' +
                                                currentPosition.latitude +
                                                ',' +
                                                currentPosition.longitude +
                                                '/' +
                                                item.location.latitude +
                                                ',' +
                                                item.location.longitude
                                              }
                                              rel={'noreferrer'}
                                              target={'_blank'}
                                              style={{
                                                color: '#4a4a4a',
                                                textDecoration: 'underline',
                                              }}
                                            >
                                              {item?.location.address}
                                              {item?.location.wardName
                                                ? ', ' + item?.location.wardName
                                                : ''}
                                              {item?.location.districtName
                                                ? ', ' + item?.location.districtName
                                                : ''}
                                              {item?.location.provinceName
                                                ? ', ' + item?.location.provinceName
                                                : ''}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                              {isFetching && 'Fetching more list items...'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* <div
                    style={{
                      marginTop: 15,
                    }}
                  >
                    <Pagination
                      pages={pages}
                      current={currentPage}
                      showFirstLast
                      total={totalPages}
                      delta={2}
                      showPrevNext={false}
                      align={'center'}
                      onChange={(page) => onChangePage(page)}
                    />
                  </div> */}
                </div>

                {/*  */}
              </div>

              <Contact />
            </div>
          </div>
        ) : (
          <div className="container-home">
            <div className="container">
              <div className="head-title head-title-top">HỆ THỐNG CỬA HÀNG NUTIFOOD</div>
              <div className="columns">
                <div className="column is-5-tablet is-5-desktop is-3-fullhd is-margin-rigth-detail">
                  <div className="field">
                    <label className="label">Tỉnh/Thành</label>
                    <div className="control">
                      <Select
                        placeholder="Chọn Tỉnh/Thành"
                        options={dataProvince}
                        onChange={onChangeSelectFilter('province')}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Quận/Huyện</label>
                    <div className="control">
                      <Select
                        placeholder="Chọn Quận/Huyện"
                        options={dataDistrict}
                        onChange={onChangeSelectFilter('district')}
                        isDisabled={!dataFilter.province}
                        value={
                          dataFilter.district
                            ? dataDistrict.find((option) => option.value === dataFilter.district)
                            : ''
                        }
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Phường/Xã</label>
                    <div className="control">
                      <Select
                        placeholder="Chọn Phường/Xã"
                        options={dataWard}
                        onChange={onChangeSelectFilter('ward')}
                        isDisabled={!dataFilter.district}
                        value={
                          dataFilter.ward
                            ? dataWard.find((option) => option.value === dataFilter.ward)
                            : ''
                        }
                      />
                    </div>
                  </div>
                  <div style={{marginTop: 32, fontSize: 20, fontWeight: 600}}>
                    <span style={{}}>Gần bạn</span>
                    <span style={{float: 'right', color: '#08633D'}}>
                      {' '}
                      {storeListFilter?.length.toLocaleString()} cửa hàng
                    </span>
                  </div>
                </div>

                {storeListFilter?.length > 0 && (
                  <>
                    <div className="column">
                      <div
                        className="card"
                        style={{
                          border: '1px solid #D2D2D2',
                        }}
                      >
                        <div className="card-content">
                          <div className="columns">
                            <div className="column">
                              <div
                                style={{height: 340, overflowX: 'hidden'}}
                                onScroll={handleScroll}
                                id="scroll-store"
                              >
                                {storeListFilter?.map((item, index) => {
                                  return (
                                    <>
                                      <div className="columns is-border-column">
                                        <div className="column has-text-weight-semibold column-display-mobile-one">
                                          <div className="has-text-primary">
                                            {item?.name}{' '}
                                            <span style={{color: '#9E9D9C'}}>
                                              {item?.distance / 1000} km
                                            </span>
                                          </div>
                                          {item?.phone && (
                                            <div className="icon-text">
                                              <span className="icon" style={{color: '#08633D'}}>
                                                <img src={phone} style={{height: 18}} />
                                              </span>
                                              <span>{item?.phone}</span>
                                            </div>
                                          )}
                                        </div>
                                        <div className="column has-text-weight-semibold column-display-mobile">
                                          <div
                                            style={{
                                              position: 'relative',
                                            }}
                                          >
                                            {item?.location.address && (
                                              <div
                                                style={{
                                                  position: 'absolute',
                                                  top: '60%',
                                                  transform: 'translate(0, -50%)',
                                                }}
                                              >
                                                <img
                                                  src={market}
                                                  style={{
                                                    height: 20,
                                                    width: 16,
                                                  }}
                                                />
                                              </div>
                                            )}
                                            <div
                                              style={{
                                                marginLeft: 30,
                                              }}
                                            >
                                              <a
                                                href={
                                                  'https://www.google.com/maps/dir/' +
                                                  currentPosition.latitude +
                                                  ',' +
                                                  currentPosition.longitude +
                                                  '/' +
                                                  item.location.latitude +
                                                  ',' +
                                                  item.location.longitude
                                                }
                                                rel={'noreferrer'}
                                                target={'_blank'}
                                                style={{
                                                  color: '#4a4a4a',
                                                  textDecoration: 'underline',
                                                }}
                                              >
                                                {item?.location.address}
                                                {item?.location.wardName
                                                  ? ', ' + item?.location.wardName
                                                  : ''}
                                                {item?.location.districtName
                                                  ? ', ' + item?.location.districtName
                                                  : ''}
                                                {item?.location.provinceName
                                                  ? ', ' + item?.location.provinceName
                                                  : ''}
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: 15,
                        }}
                      >
                        <Pagination
                          pages={pages}
                          current={currentPage}
                          showFirstLast
                          total={totalPages}
                          delta={2}
                          showPrevNext={false}
                          align={'center'}
                          onChange={(page) => onChangePage(page)}
                        />
                      </div>
                    </div>
                  </>
                )}

                {/*  */}
              </div>

              <Contact />
            </div>
          </div>
        )
      ) : (
        <div className="container-home">
          <div className="container">
            <div className="head-title head-title-top">HỆ THỐNG CỬA HÀNG NUTIFOOD</div>
            <div className="columns">
              <div className="column is-5-tablet is-5-desktop is-3-fullhd is-margin-rigth-detail">
                {/* <div className="field">
                  <button
                    className="button is-fullwidth is-primary is-outlined"
                    style={{border: '1px solid #08633D', borderRadius: 45}}
                    onClick={refreshPage}
                  >
                    <span className="icon m-1">
                      <i className="fa fa-map-marker"></i>
                    </span>
                    Tìm cửa hàng gần tôi
                  </button>
                </div> */}
                <div className="field">
                  <label className="label">Tỉnh/Thành</label>
                  <div className="control">
                    <Select
                      placeholder="Chọn Tỉnh/Thành"
                      options={dataProvince}
                      onChange={onChangeSelect('province')}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Quận/Huyện</label>
                  <div className="control">
                    <Select
                      placeholder="Chọn Quận/Huyện"
                      options={dataDistrict}
                      onChange={onChangeSelect('district')}
                      isDisabled={!dataFilter.province}
                      value={
                        dataFilter.district
                          ? dataDistrict.find((option) => option.value === dataFilter.district)
                          : ''
                      }
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Phường/Xã</label>
                  <div className="control">
                    <Select
                      placeholder="Chọn Phường/Xã"
                      options={dataWard}
                      onChange={onChangeSelect('ward')}
                      isDisabled={!dataFilter.district}
                      value={
                        dataFilter.ward
                          ? dataWard.find((option) => option.value === dataFilter.ward)
                          : ''
                      }
                    />
                  </div>
                </div>
                <div style={{marginTop: 32, fontSize: 20, fontWeight: 600}}>
                  <span style={{}}>Gần bạn</span>
                  <span style={{float: 'right', color: '#08633D'}}>
                    {' '}
                    {storeListFilter?.length.toLocaleString()} cửa hàng
                  </span>
                </div>
              </div>

              {storeListFilter?.length > 0 && (
                <>
                  <div className="column">
                    <div
                      className="card"
                      style={{
                        border: '1px solid #D2D2D2',
                      }}
                    >
                      <div className="card-content">
                        <div className="columns">
                          <div className="column">
                            <div>
                              {storeListFilter?.slice(perPage, pages)?.map((item, index) => {
                                return (
                                  <>
                                    <div className="columns is-border-column">
                                      <div className="column has-text-weight-semibold column-display-mobile-one">
                                        <div className="has-text-primary">{item?.name}</div>
                                        {item?.phone && (
                                          <div className="icon-text">
                                            <span className="icon" style={{color: '#08633D'}}>
                                              <img src={phone} style={{height: 18}} />
                                            </span>
                                            <span>{item?.phone}</span>
                                          </div>
                                        )}
                                      </div>
                                      <div className="column has-text-weight-semibold column-display-mobile">
                                        <div
                                          style={{
                                            position: 'relative',
                                          }}
                                        >
                                          {item?.location.address && (
                                            <div
                                              style={{
                                                position: 'absolute',
                                                top: '60%',
                                                transform: 'translate(0, -50%)',
                                              }}
                                            >
                                              <img
                                                src={market}
                                                style={{
                                                  height: 20,
                                                  width: 16,
                                                }}
                                              />
                                            </div>
                                          )}
                                          <div
                                            style={{
                                              marginLeft: 30,
                                            }}
                                          >
                                            {item?.location.address}
                                            {item?.location.wardName
                                              ? ', ' + item?.location.wardName
                                              : ''}
                                            {item?.location.districtName
                                              ? ', ' + item?.location.districtName
                                              : ''}
                                            {item?.location.provinceName
                                              ? ', ' + item?.location.provinceName
                                              : ''}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: 15,
                      }}
                    >
                      <Pagination
                        pages={10}
                        current={currentStorePage}
                        showFirstLast
                        total={totalStorePages}
                        delta={2}
                        showPrevNext={false}
                        align={'center'}
                        onChange={(page) => onChangeStorePage(page)}
                      />
                    </div>
                  </div>
                </>
              )}

              {/*  */}
            </div>

            <Contact />
          </div>
        </div>
      )}
      <Modal show={showFilterStoreModal} closeOnBlur closeOnEsc showClose={false}>
        <Modal.Card style={{maxWidth: 400}}>
          <Modal.Card.Body style={{padding: 40, borderRadius: 16}}>
            <>
              <div className="columns">
                <a
                  className="label"
                  style={{float: 'right', color: 'red', position: 'absolute', top: 10, right: 10}}
                  onClick={() => {
                    setShowFilterStoreModal(!showFilterStoreModal);
                  }}
                >
                  <i className="fa fa-times fa-2x" aria-hidden="true"></i>
                </a>
                <div className="column">
                  <label className="label" style={{fontSize: 20, textAlign: 'center'}}>
                    Lọc dữ liệu cửa hàng
                  </label>
                </div>
              </div>
              <div className="columns">
                <div className="column is-12-tablet is-12-desktop is-12-fullhd is-margin-rigth-detail">
                  <div className="field">
                    <label className="label">Tỉnh/Thành</label>
                    <div className="control">
                      <Select
                        placeholder="Chọn Tỉnh/Thành"
                        options={dataProvince}
                        value={
                          dataFilterMobile.province
                            ? dataProvince.find(
                                (option) => option.value === dataFilterMobile.province
                              )
                            : ''
                        }
                        onChange={onChangeSelectFilterMobile('province')}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Quận/Huyện</label>
                    <div className="control">
                      <Select
                        placeholder="Chọn Quận/Huyện"
                        options={dataDistrict}
                        onChange={onChangeSelectFilterMobile('district')}
                        isDisabled={!dataFilterMobile.province}
                        value={
                          dataFilterMobile.district
                            ? dataDistrict.find(
                                (option) => option.value === dataFilterMobile.district
                              )
                            : ''
                        }
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Phường/Xã</label>
                    <div className="control">
                      <Select
                        placeholder="Chọn Phường/Xã"
                        options={dataWard}
                        onChange={onChangeSelectFilterMobile('ward')}
                        isDisabled={!dataFilterMobile.district}
                        value={
                          dataFilterMobile.ward
                            ? dataWard.find((option) => option.value === dataFilterMobile.ward)
                            : ''
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-12 has-text-centered">
                  <button className="button button-small is-primary" onClick={handleFilter}>
                    Tìm kiếm
                  </button>
                </div>
              </div>
            </>
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
    </Layout>
  );
};
export default Store;
