import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './styles.scss';
import {Modal} from 'react-bulma-components';
import {resetReferralInfo, resetModal as resetModalAction} from 'appRedux/slices/appSlice';
import {useFormik} from 'formik';
import classNames from 'classnames';
import OtpInput from 'react18-input-otp';
import moment from 'moment';
import {get} from 'lodash';

import {
  generateUserGiftCodeAction,
  getDistrictByProvinceCodeAction,
  getProvincesAction,
  getUserReferralInfoByPhoneNumber,
  getWardByDistrictCodeAction,
} from 'appRedux/actions/app';
import Select from 'react-select';
import {COLORS} from 'constants/colors';
import exportAsImage, {getSelectOptions} from 'helpers';
import {
  REGISTER_FORM_SCHEME,
  MODAL_STATES,
  REGISTER_INPUTS,
  MODAL_WIDTHS,
  LOGIN_FORM_SCHEME,
  EDIT_INPUTS,
  EDIT_FORM_SCHEME,
} from './constanst';
import {Link} from 'react-router-dom';
import {ROUTE_NAME} from 'constants/routes';
import {QRCodeSVG} from 'qrcode.react';
import iconCongrats from 'images/icon_congrats.png';
import {userInfoSelector} from 'appRedux/selectors/auth';
import {GENDER_OPTIONS} from 'constants/gender';
import {userProfileModalSelector} from 'appRedux/selectors/app';
import {
  generateOTPAction,
  registerAction,
  updateUserProfileAction,
  verifyOTPAction,
} from 'appRedux/actions/auth';
import Countdown from 'react-countdown';
import {OTP_TYPES} from 'constants/otp';
import {REFERRAL_TYPES} from 'constants/referral';
import {titleCase} from 'utils/stringUltils';
import Captcha from 'demos-react-captcha';
import ReCAPTCHA from 'react-google-recaptcha';
import {getUserProfileAction} from 'appRedux/actions/auth';
import info from 'images/icon_info.png';
import 'react-tooltip/dist/react-tooltip.css';
import {Tooltip as ReactTooltip} from 'react-tooltip';
const COUNTDOWN_DELAY = 30000;
const UserProfileModal = ({phoneNumber}) => {
  const userProfileModalData = useSelector(userProfileModalSelector);
  const show = userProfileModalData?.show;
  const provinces = useSelector((state) => state.app.masterData.provinces || []);
  const referralInfo = userProfileModalData?.referralInfo;
  const reduxModalState = userProfileModalData?.modalState;
  const userInfo = useSelector(userInfoSelector);
  const userGift = userProfileModalData?.userGift;
  const [modalState, setModalState] = useState('');

  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [otp, setOtp] = useState('');

  const [qrCodeData, setQRCodeData] = useState(null);
  const [showQRCodeData, setShowQRCodeData] = useState(null);

  const qrRef = useRef();
  const [countDownKey, setCountDownKey] = useState(0);
  const [countDownDate, setCountDownDate] = useState(Date.now());

  const TEST_SITE_KEY = '6LdI4SAiAAAAANpbDg3LuSai8xrOA0yTMte8vp7v';
  const DELAY = 1500;
  const recaptchaRef = React.useRef();
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const [tokenCaptcha, setTokenCaptcha] = useState(false);
  const [updateBirthday, setUpdateBirthday] = useState(false);
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(resetModalAction());
    dispatch(getUserProfileAction());
  };

  useEffect(() => {
    if (userGift && qrCodeData && userGift?.id === qrCodeData?.userGiftId) {
      setShowQRCodeData(qrCodeData);
    }
  }, [userGift, qrCodeData]);

  useEffect(() => {
    setModalState(reduxModalState);
    if (reduxModalState === MODAL_STATES.QR_CODE && userGift) {
      dispatch(
        generateUserGiftCodeAction({
          userGiftId: userGift.id,
          callback: (data) => {
            setQRCodeData(data);
          },
        })
      );
    }
    if (reduxModalState === MODAL_STATES.QR_CODE_VARNA && userGift) {
      dispatch(
        generateUserGiftCodeAction({
          userGiftId: userGift.id,
          callback: (data) => {
            setQRCodeData(data);
          },
        })
      );
    }
  }, [show]);

  useEffect(() => {
    if (modalState === MODAL_STATES.REGISTER) {
      setCountDownDate(Date.now() + COUNTDOWN_DELAY);
      setCountDownKey(countDownKey + 1);
    }
  }, [modalState]);

  useEffect(() => {
    if (show) {
      if (reduxModalState === MODAL_STATES.LOGIN && !!phoneNumber) {
        dispatch(getUserReferralInfoByPhoneNumber({phoneNumber}));
      } else if (reduxModalState === MODAL_STATES.EDIT) {
        dispatch(
          getDistrictByProvinceCodeAction({
            provinceCode: userInfo?.province,
            callback: (data) => {
              const items = getSelectOptions(data, 'code', 'name');
              setDistricts(items);
              editFormik.setFieldValue(
                'district',
                items.find((item) => item?.code === userInfo?.district)
              );
            },
          })
        );
        dispatch(
          getWardByDistrictCodeAction({
            districtCode: userInfo?.district,
            callback: (data) => {
              const items = getSelectOptions(data, 'code', 'name');
              setWards(items);
              editFormik.setFieldValue(
                'ward',
                items.find((item) => item?.code === userInfo?.ward) || null
              );
            },
          })
        );
      }
    }
    if (!show) {
      resetModal();
    } else if (!provinces?.length) {
      dispatch(getProvincesAction());
    }
  }, [show, provinces?.length, reduxModalState]);

  const onChangeProvince = (provinceCode) => {
    formik.setFieldValue('district', null);
    formik.setFieldValue('ward', null);
    editFormik.setFieldValue('district', null);
    editFormik.setFieldValue('ward', null);
    setDistricts([]);
    setWards([]);
    dispatch(
      getDistrictByProvinceCodeAction({
        provinceCode,
        callback: (data) => {
          setDistricts(getSelectOptions(data, 'code', 'name'));
        },
      })
    );
  };

  const onChangeDistrict = (districtCode) => {
    formik.setFieldValue('ward', null);
    editFormik.setFieldValue('ward', null);
    setWards([]);
    dispatch(
      getWardByDistrictCodeAction({
        districtCode,
        callback: (data) => {
          setWards(getSelectOptions(data, 'code', 'name'));
        },
      })
    );
  };

  const handleResendOTP = (phoneNumber) => {
    dispatch(
      generateOTPAction({
        phoneNumber,
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      otp: '',
      gender: null,
      fullName: '',
      dateOfBirth: moment().add(-25, 'y').format('YYYY-MM-DD'),
      email: '',
      province: null,
      district: null,
      ward: null,
      address: '',
      isAgreeTermsCondition: false,
    },
    enableReinitialize: true,

    validationSchema: REGISTER_FORM_SCHEME,
    onSubmit: (values) => {
      dispatch(
        registerAction({
          data: {
            ...values,
            fullName: titleCase(values?.fullName || ''),
            phoneNumber: getLoginPhoneNumber(),
            referralCode: referralInfo?.referralCode || '',
            referralPhoneNumber: phoneNumber || '',
            referralType: referralInfo ? REFERRAL_TYPES.VIA_LINK : '',
          },
          callback: () => {
            setModalState(MODAL_STATES.REGISTER_SUCCESS);
          },
        })
      );
    },
  });

  const editFormik = useFormik({
    initialValues: {
      fullName: userInfo?.fullName,
      dateOfBirth: userInfo?.dateOfBirth ? moment(userInfo?.dateOfBirth).format('YYYY-MM-DD') : '',
      gender: GENDER_OPTIONS.find((item) => item.value === userInfo?.gender),
      email: userInfo?.email,
      province: provinces.find((item) => item?.code === userInfo?.province) || null,
      district: null, // districts.find((item) => item?.code === userInfo?.district) || null,
      ward: null, //wards.find((item) => item?.code === userInfo?.ward) || null,
      address: userInfo?.address,
    },
    enableReinitialize: true,

    validationSchema: EDIT_FORM_SCHEME,
    onSubmit: (values) => {
      const payload = {
        fullName: titleCase(values?.fullName || ''),
        gender: values?.gender?.value,
        dateOfBirth: moment(values?.dateOfBirth).format('YYYY-MM-DD'),
        email: values?.email,
        province: values?.province?.value,
        district: values?.district?.value,
        ward: values?.ward?.value,
        address: values?.address,
      };
      dispatch(updateUserProfileAction(payload));
    },
  });

  const loginFormik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    enableReinitialize: true,

    validationSchema: LOGIN_FORM_SCHEME,
    onSubmit: (values) => {
      //if (!tokenCaptcha) return;
      handleGenerateOtp(values.phoneNumber, true);
    },
  });
  const asyncScriptOnLoad = () => {
    setRecaptchaLoaded(true);
  };
  const handleChangeCaptcha = (value) => {
    if (value != null) {
      setTokenCaptcha(true);
    }
  };
  const handCompleteUpdate = () => {
    if (updateBirthday) {
      setModalState(MODAL_STATES.COMPLETE_UPDATE);
    } else {
      editFormik.handleSubmit();
    }
  };
  const handleGenerateOtp = (phoneNumber, shouldCallback = false) => {
    if (!phoneNumber) return;
    dispatch(
      generateOTPAction({
        phoneNumber,
        callback: shouldCallback
          ? (otpType) => {
              switch (otpType) {
                case OTP_TYPES.QUICK_LOGIN:
                  setModalState(MODAL_STATES.OTP);
                  break;
                case OTP_TYPES.REGISTER:
                  setModalState(MODAL_STATES.REGISTER);
                  break;

                default:
                  break;
              }
            }
          : null,
      })
    );
  };

  const resetModal = () => {
    formik.resetForm();
    loginFormik.resetForm();
    editFormik.resetForm();
    setOtp('');
    setQRCodeData(null);
    setShowQRCodeData(null);
    setDistricts([]);
    setWards([]);
    dispatch(resetReferralInfo());
  };

  const handleSubmitOpt = () => {
    if (otp.length === 6) {
      dispatch(verifyOTPAction({phoneNumber: getLoginPhoneNumber(), otp, callback: () => {}}));
    }
  };

  const getLoginPhoneNumber = () => {
    return loginFormik.values.phoneNumber || userProfileModalData.phoneNumber;
  };

  const countDownRenderer = ({seconds, completed}) => {
    if (completed) {
      // Render a completed state
      return (
        <a
          className="link has-text-primary has-text-weight-medium is-underlined"
          onClick={() => {
            setCountDownDate(Date.now() + COUNTDOWN_DELAY);
            setCountDownKey(countDownKey + 1);
            handleGenerateOtp(getLoginPhoneNumber());
          }}
        >
          Gửi lại mã OTP
        </a>
      );
    } else {
      // Render a countdown
      return (
        <span>
          Gửi lại sau{' '}
          <span className=" has-text-warning has-text-weight-semibold">{`${seconds} giây`}</span>{' '}
          (tối đa 5 lần)
        </span>
      );
    }
  };

  const renderRegisterContent = () => {
    const locationOptions = {
      province: provinces,
      district: districts,
      ward: wards,
      gender: GENDER_OPTIONS,
    };
    return (
      <Modal.Card.Body className="hide-scroll" style={{padding: 40, borderRadius: 16}}>
        <div className="title is-4 has-text-centered has-text-primary has-text-weight-bold">
          ĐĂNG KÝ THÀNH VIÊN CHƯƠNG TRÌNH NUTIPOINT
        </div>
        {referralInfo && referralInfo?.fullName && (
          <div className="title is-6 has-text-centered has-text-black has-text-weight-bold">
            Bạn đã được Thành viên <span className="has-text-primary">{referralInfo.fullName}</span>{' '}
            mời đăng ký thành viên Chương trình Nutipoint
          </div>
        )}
        <div className="title is-6 has-text-weight-semibold mb-2">
          Số điện thoại đăng ký{' '}
          <span className="title is-5 has-text-primary has-text-weight-bold">
            {getLoginPhoneNumber()}
          </span>
        </div>
        <div
          onSubmit={(e) => {
            // e.preventDefault();
            // e.stopPropagation();
            // formik.handleSubmit();
          }}
        >
          <div className="columns is-multiline">
            {REGISTER_INPUTS.map(
              (
                {
                  type,
                  label,
                  placeholder,
                  fieldName,
                  isFull,
                  maxLength,
                  isRequired,
                  isOTP,
                  className,
                },
                index
              ) => {
                if (isOTP) {
                  return (
                    <>
                      <div className={'field column is-6'} key={`register_input_${index}`}>
                        <label className="label">
                          {label} {isRequired && <span className=" has-text-danger">*</span>}
                        </label>
                        <div className="control">
                          <input
                            className="input"
                            maxLength={maxLength}
                            value={get(formik.values, fieldName, '')}
                            type={'tel'}
                            placeholder={placeholder}
                            onChange={(e) => {
                              formik.setFieldTouched(fieldName, true, true);
                              formik.setFieldValue(fieldName, e.target.value);
                            }}
                          />
                        </div>
                        {get(formik.touched, fieldName, false) && !!get(formik.errors, fieldName) && (
                          <p className="help mb-0 is-danger">
                            <span>{get(formik.errors, fieldName)}</span>
                          </p>
                        )}
                      </div>
                      <div
                        className={classNames('field column is-6 is-align-items-flex-end is-flex', {
                          'is-align-items-center':
                            get(formik.touched, fieldName, false) &&
                            !!get(formik.errors, fieldName),
                        })}
                        key={`register_input_${index}`}
                      >
                        <Countdown
                          key={countDownKey}
                          date={countDownDate}
                          renderer={countDownRenderer}
                        />
                      </div>
                    </>
                  );
                }
                if (type === 'select') {
                  return (
                    <div className="field column is-6" key={`register_input_${index}`}>
                      <label className="label">
                        {label} {isRequired && <span className=" has-text-danger">*</span>}
                      </label>
                      <div className="control">
                        <Select
                          placeholder={label}
                          options={get(locationOptions, fieldName, [])}
                          key={`register_input_${index}`}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: COLORS.primary,
                              primary25: COLORS.primary25,
                              primary50: COLORS.primary50,
                              primary75: COLORS.primary75,
                            },
                          })}
                          onChange={(item) => {
                            formik.setFieldTouched(fieldName, true, true);
                            if (item?.value !== get(formik.values, fieldName, '')?.value) {
                              if (fieldName === 'province') {
                                onChangeProvince(item?.value);
                              } else if (fieldName === 'district') {
                                onChangeDistrict(item?.value);
                              }
                              formik.setFieldValue(fieldName, item);
                            }
                          }}
                          value={get(formik.values, fieldName, '')}
                        />
                      </div>
                      {get(formik.touched, fieldName, false) && !!get(formik.errors, fieldName) && (
                        <p className="help mb-0 is-danger">
                          <span>{get(formik.errors, fieldName)}</span>
                        </p>
                      )}
                    </div>
                  );
                }
                return (
                  <div
                    className={classNames('field column is-6', {'is-12': isFull})}
                    key={`register_input_${index}`}
                  >
                    <label className="label">
                      {label} {isRequired && <span className=" has-text-danger">*</span>}
                    </label>
                    <div className="control">
                      <input
                        className={`input ${className || ''}`}
                        maxLength={maxLength}
                        value={get(formik.values, fieldName, '')}
                        type={type}
                        placeholder={placeholder}
                        onChange={(e) => {
                          formik.setFieldTouched(fieldName, true, true);
                          formik.setFieldValue(fieldName, e.target.value);
                        }}
                      />
                    </div>
                    {get(formik.touched, fieldName, false) && !!get(formik.errors, fieldName) && (
                      <p className="help mb-0 is-danger">
                        <span>{get(formik.errors, fieldName)}</span>
                      </p>
                    )}
                  </div>
                );
              }
            )}
            <div className="column is-12 has-text-centered">
              <label className="checkbox">
                <input
                  checked={get(formik.values, 'isAgreeTermsCondition', false)}
                  type="checkbox"
                  className="is-primary"
                  onChange={(e) => {
                    formik.setFieldValue('isAgreeTermsCondition', e.target.checked, true);
                  }}
                />{' '}
                Tôi đã đọc và đồng ý với{' '}
                <a
                  onClick={() => {
                    setModalState(MODAL_STATES.CONDITIONS);
                  }}
                  className=" has-text-black is-underlined"
                >
                  điều khoản và điều kiện
                </a>
              </label>
            </div>
            <div className="column is-12 has-text-centered">
              <button
                className="button button-small mr-3 is-primary is-outlined is-disabled"
                onClick={handleCloseModal}
              >
                Quay về
              </button>
              <button
                className={classNames('button button-small is-primary', {
                  'is-loading': userProfileModalData?.isRegistering,
                })}
                onClick={() => {
                  // setModalState(MODAL_STATES.REGISTER_SUCCESS);
                  formik.submitForm();
                  // formik.handleSubmit();
                }}
                disabled={!formik.isValid}
              >
                Đăng ký
              </button>
            </div>
          </div>
        </div>
      </Modal.Card.Body>
    );
  };

  const renderEditProfileContent = () => {
    const locationOptions = {
      province: getSelectOptions(provinces, 'code', 'shortName'),
      district: districts,
      ward: wards,
      gender: GENDER_OPTIONS,
    };
    return (
      <Modal.Card.Body className="hide-scroll" style={{padding: 40, borderRadius: 16}}>
        <div className="title is-4 has-text-centered has-text-primary has-text-weight-bold">
          Sửa thông tin
        </div>
        <div>
          <div className="columns is-multiline">
            {EDIT_INPUTS.map(
              (
                {
                  type,
                  label,
                  placeholder,
                  fieldName,
                  isFull,
                  maxLength,
                  isRequired,
                  isDisabled,
                  className,
                  icon,
                },
                index
              ) => {
                if (type === 'select') {
                  return (
                    <div className="field column is-6" key={`edit_input_${index}`}>
                      <label className="label">
                        {label} {isRequired && <span className=" has-text-danger">*</span>}
                      </label>
                      <div className="control">
                        <Select
                          placeholder={label}
                          options={get(locationOptions, fieldName, [])}
                          key={`edit_input_${index}`}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: COLORS.primary,
                              primary25: COLORS.primary25,
                              primary50: COLORS.primary50,
                              primary75: COLORS.primary75,
                            },
                          })}
                          onChange={(item) => {
                            editFormik.setFieldTouched(fieldName, true, true);
                            if (item?.value !== get(editFormik.values, fieldName, '')?.value) {
                              if (fieldName === 'province') {
                                onChangeProvince(item?.value);
                              } else if (fieldName === 'district') {
                                onChangeDistrict(item?.value);
                              }
                              editFormik.setFieldValue(fieldName, item);
                            }
                          }}
                          value={get(editFormik.values, fieldName, '')}
                        />
                      </div>
                      {get(editFormik.touched, fieldName, false) &&
                        !!get(editFormik.errors, fieldName) && (
                          <p className="help mb-0 is-danger">
                            <span>{get(editFormik.errors, fieldName)}</span>
                          </p>
                        )}
                    </div>
                  );
                }
                return (
                  <div
                    className={classNames('field column is-6', {'is-12': isFull})}
                    key={`edit_input_${index}`}
                  >
                    <div className="icon-text">
                      {icon && (
                        <span className="icon has-text-info">
                          <i className="fas fa-info-circle" id="tooltip-info"></i>
                        </span>
                      )}
                      <label className="label">
                        {label} {isRequired && <span className=" has-text-danger">*</span>}
                      </label>
                    </div>
                    <div className="control">
                      <input
                        className={`input ${className || ''}`}
                        maxLength={maxLength}
                        value={get(editFormik.values, fieldName, '')}
                        type={type}
                        placeholder={placeholder}
                        onChange={(e) => {
                          editFormik.setFieldTouched(fieldName, true, true);
                          editFormik.setFieldValue(fieldName, e.target.value);
                          if (fieldName == 'dateOfBirth') {
                            if (
                              e.target.value != moment(userInfo.dateOfBirth).format('YYYY-MM-DD')
                            ) {
                              setUpdateBirthday(true);
                            }
                          }
                        }}
                        disabled={
                          fieldName == 'dateOfBirth' && userInfo.isUpdatedDateOfBirth ? true : false
                        }
                      />
                    </div>
                    {get(editFormik.touched, fieldName, false) &&
                      !!get(editFormik.errors, fieldName) && (
                        <p className="help mb-0 is-danger">
                          <span>{get(editFormik.errors, fieldName)}</span>
                        </p>
                      )}
                  </div>
                );
              }
            )}
            <div className="column is-12 has-text-centered">
              <button
                className="button button-small mr-3 is-primary is-outlined is-disabled"
                onClick={handleCloseModal}
              >
                Quay về
              </button>
              <button
                className={classNames('button button-small is-primary', {
                  'is-loading': userProfileModalData?.isUpdating,
                })}
                onClick={() => {
                  // editFormik.submitForm();
                  //editFormik.handleSubmit();
                  handCompleteUpdate();
                }}
                disabled={
                  !editFormik.isValid || !editFormik.dirty || userProfileModalData?.isUpdating
                }
              >
                Cập nhật
              </button>
              <ReactTooltip
                anchorId="tooltip-info"
                place="top"
                variant="info"
                content="Chỉ được đổi 1 lần duy nhất"
              />
            </div>
          </div>
        </div>
      </Modal.Card.Body>
    );
  };
  const renderCompleteUpdateContent = () => {
    return (
      <Modal.Card.Body className="hide-scroll" style={{padding: 40, borderRadius: 16}}>
        <div className="title is-4 has-text-centered  has-text-weight-bold ">
          Xác nhận thay đổi ngày sinh
        </div>
        <div className="has-text-centered has-text-weight-bold">
          Để có có nhiều hoạt động ý nghĩa nhất đến Quý khách,
        </div>
        <div className="has-text-centered has-text-weight-bold">
          thông tin ngày sinh chỉ được thay đôi <span style={{color: 'red'}}> 1 lần</span> duy nhất
        </div>
        <div className="has-text-centered has-text-weight-bold pt-2">
          Bạn muốn cập nhật ngày sinh sang{' '}
          <span className="has-text-primary ">
            {moment(get(editFormik.values, 'dateOfBirth', '')).format('DD/MM/YYYY')}
          </span>
        </div>
        <div className="column is-12 has-text-centered mt-5">
          <button
            className="button button-small mr-3 is-primary is-outlined is-disabled"
            onClick={handleCloseModal}
          >
            Quay về
          </button>
          <button
            className={classNames('button button-small is-primary', {
              'is-loading': userProfileModalData?.isUpdating,
            })}
            onClick={() => {
              editFormik.handleSubmit();
            }}
            disabled={!editFormik.isValid || !editFormik.dirty || userProfileModalData?.isUpdating}
          >
            Cập nhật
          </button>
        </div>
      </Modal.Card.Body>
    );
  };
  const renderRegisterSuccessContent = () => {
    return (
      <Modal.Card.Body className="hide-scroll" style={{padding: 40, borderRadius: 16}}>
        <div className="title is-4 has-text-centered has-text-primary has-text-weight-bold is-uppercase">
          Đăng ký thành công!
        </div>
        <div className="has-text-centered mb-5">
          <img src={iconCongrats} width={200} alt="logo" />
        </div>
        <div className="has-text-centered has-text-black has-text-weight-medium mb-5">
          Chào mừng bạn đến với{' '}
          <span className="has-text-primary">Chương Trình Thành Viên NUTIPOINT.</span> Hãy bắt đầu
          tích điểm để quy đổi vô vàn quà tặng!
        </div>
        <div>
          <div className="columns is-multiline">
            <div className="column is-12 has-text-centered">
              <Link to={ROUTE_NAME.GIFT}>
                <button className="button mr-3 is-primary  is-fullwidth" onClick={handleCloseModal}>
                  Xem Ưu đãi & Quà
                </button>
              </Link>
            </div>
            <div className="column is-12 has-text-centered">
              <Link to={ROUTE_NAME.USER_PROFILE}>
                <button
                  className="button is-primary is-outlined is-fullwidth"
                  onClick={handleCloseModal}
                >
                  Tài khoản
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Modal.Card.Body>
    );
  };

  const renderOtpContent = () => {
    return (
      <Modal.Card.Body className="hide-scroll" style={{padding: 40, borderRadius: 16}}>
        <div className="title is-4 has-text-centered has-text-primary has-text-weight-bold is-uppercase">
          Nhập mã xác thực OTP
        </div>
        <div className="has-text-centered has-text-black has-text-weight-medium mb-5">
          Nhập 6 số OTP được gửi tới{' '}
          <span className="has-text-primary">{getLoginPhoneNumber()}</span>
        </div>
        <div>
          <div className="columns is-multiline">
            <div className="column is-12">
              <div>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  isInputNum
                  inputStyle="inputStyle p-1 has-text-primary"
                  focusStyle="focusStyle"
                  shouldAutoFocus
                  onSubmit={handleSubmitOpt}
                  containerStyle="is-justify-content-space-between"
                />
              </div>
            </div>
            <div className="column is-12 has-text-centered">
              <a
                className="is-primary has-text-primary is-underlined"
                onClick={() => {
                  handleResendOTP(getLoginPhoneNumber());
                }}
              >
                <span>Gửi lại mã OTP</span>
              </a>
            </div>
            <div className="column is-12 has-text-centered">
              <button
                className={classNames('button mr-3 is-primary is-fullwidth', {
                  'is-loading': userProfileModalData?.isOTPVerifying,
                })}
                onClick={handleSubmitOpt}
              >
                Tiếp tục
              </button>
            </div>
          </div>
        </div>
      </Modal.Card.Body>
    );
  };

  const renderLoginContent = () => {
    return (
      <Modal.Card.Body className="hide-scroll" style={{padding: 40, borderRadius: 16}}>
        <div className="title is-4 has-text-centered has-text-primary has-text-weight-bold">
          Xin chào!
        </div>
        <div className="has-text-centered has-text-black mb-5">
          Vui lòng nhập số điện thoại để tiếp tục
        </div>
        <form
          onSubmit={() => {
            loginFormik.submitForm();
          }}
        >
          <ReCAPTCHA
            sitekey={TEST_SITE_KEY}
            ref={recaptchaRef}
            size="invisible"
            asyncScriptOnLoad={asyncScriptOnLoad}
            onChange={handleChangeCaptcha}
          />
          <div className="columns is-multiline">
            <div className="field column is-12">
              <div className="control has-icons-right">
                <input
                  className="input"
                  maxLength={10}
                  value={get(loginFormik.values, 'phoneNumber', '')}
                  type={'tel'}
                  placeholder={'Nhập số điện thoại'}
                  onChange={(e) => {
                    loginFormik.setFieldTouched('phoneNumber', true, true);
                    loginFormik.setFieldValue('phoneNumber', e.target.value);
                  }}
                />

                {!!loginFormik?.values?.phoneNumber?.length && (
                  <span
                    className="icon is-small is-right"
                    style={{
                      pointerEvents: 'all',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      loginFormik.resetForm();
                    }}
                  >
                    <i className="fa-solid fa-circle-xmark"></i>
                  </span>
                )}
              </div>
              {get(loginFormik.touched, 'phoneNumber', false) &&
                !!get(loginFormik.errors, 'phoneNumber') && (
                  <p className="help mb-0 is-danger">
                    <span>{get(loginFormik.errors, 'phoneNumber')}</span>
                  </p>
                )}
            </div>
            <div className="column is-12 has-text-centered">
              <button
                className={classNames('button mr-3 is-primary is-fullwidth', {
                  'is-loading': userProfileModalData?.isLoggingIn,
                })}
                onClick={(e) => {
                  // setModalState(MODAL_STATES.OTP);
                  e.preventDefault();
                  loginFormik.submitForm();
                  // loginFormik.handleSubmit();
                }}
                disabled={
                  !loginFormik.isValid || userProfileModalData?.isLoggingIn || !recaptchaLoaded
                }
              >
                Tiếp tục
              </button>
            </div>
          </div>
        </form>
      </Modal.Card.Body>
    );
  };
  const renderQRCodeContent = () => {
    return (
      <Modal.Card.Body className="hide-scroll" style={{padding: 40, borderRadius: 16}}>
        <>
          {userGift?.showTitle && (
            <div className="title is-4 has-text-centered has-text-primary has-text-weight-bold">
              Đổi thành công!
            </div>
          )}
          {true && (
            <div ref={qrRef}>
              <div className=" has-text-centered">
                <div className="mb-2">
                  Ưu đãi:{' '}
                  <span className="has-text-primary has-text-weight-semibold">
                    {userGift?.name}
                  </span>
                </div>
                <div className="mb-2">
                  Hạn sử dụng:{' '}
                  <span className=" has-text-primary has-text-weight-semibold">
                    {userGift?.expiryDateTime
                      ? moment(userGift.expiryDateTime).format('DD/MM/YYYY')
                      : ''}
                  </span>
                </div>
                <div className="mb-5 subtitle has-text-weight-normal has-text-grey-dark is-7">
                  Mã được gửi đến bạn qua SMS. Mã chỉ dùng được 1 lần.
                </div>
              </div>
              {showQRCodeData?.code && showQRCodeData?.userGiftId === userGift?.id && (
                <>
                  <div className=" is-flex is-justify-content-center">
                    <div className="p-2 has-background-primary" style={{borderRadius: 5}}>
                      <div
                        className="p-3 has-background-white"
                        style={{paddingBottom: 0, borderRadius: 5}}
                      >
                        <QRCodeSVG value={showQRCodeData?.code} />
                      </div>
                    </div>
                  </div>
                  <div className=" has-text-centered title is-4 mt-2 mb-4">
                    {showQRCodeData?.code}
                  </div>
                </>
              )}
            </div>
          )}
          <Link to={ROUTE_NAME.GIFT}>
            <button
              onClick={handleCloseModal}
              className={classNames('button is-primary is-fullwidth is-outlined mb-2', {})}
            >
              Đổi thêm ưu đãi
            </button>
          </Link>
          {userGift?.showProfile && (
            <Link to={`${ROUTE_NAME.USER_PROFILE}?tab=1`}>
              <button
                onClick={handleCloseModal}
                className={classNames('button is-primary is-fullwidth is-outlined mb-2', {})}
              >
                Xem ưu đãi của tôi
              </button>
            </Link>
          )}
          <button
            className={classNames('button is-primary is-fullwidth', {
              'is-loading': userProfileModalData?.isGeneratingQRCode,
            })}
            onClick={() => {
              exportAsImage(qrRef.current, `${userGift?.name || 'gift'}`);
            }}
            disabled={!loginFormik.isValid || userProfileModalData?.isLoggingIn}
          >
            Tải ảnh ưu đãi về máy
          </button>
          <div
            className=" has-text-centered"
            style={{
              paddingTop: 15,
            }}
          >
            <a
              href={`/giftview/${userGift?.giftId}`}
              style={{
                color: '#08633D',
                textDecoration: 'underline',
              }}
            >
              Xem chi tiết
            </a>
          </div>
        </>
      </Modal.Card.Body>
    );
  };

  const renderQRCodeContentVarna = () => {
    return (
      <Modal.Card.Body className="hide-scroll" style={{padding: 40, borderRadius: 16}}>
        <>
          <div className="title is-4 has-text-centered has-text-primary has-text-weight-bold">
            ĐỔI THÀNH CÔNG
          </div>
          {true && (
            <div ref={qrRef}>
              <div className=" has-text-centered">
                <div className="mb-2">
                  Ưu đãi:{' '}
                  <span className="has-text-primary has-text-weight-semibold">
                    {userGift?.name}
                  </span>
                </div>
                <div className="mb-2">
                  Hạn sử dụng:{' '}
                  <span className=" has-text-primary has-text-weight-semibold">
                    {userGift?.expiryDateTime
                      ? moment(userGift.expiryDateTime).format('DD/MM/YYYY')
                      : ''}
                  </span>
                </div>
                <div className="mb-5 subtitle has-text-weight-normal has-text-grey-dark is-7">
                  Bạn có thể gọi 028 394 33 999 hoặc ra hệ thống cửa hàng Ngôi nhà dinh dưỡng để
                  được hướng dẫn sử dụng
                </div>
              </div>
              {showQRCodeData?.code && showQRCodeData?.userGiftId === userGift?.id && (
                <>
                  <div className=" is-flex is-justify-content-center">
                    <div className="p-2 has-background-primary" style={{borderRadius: 5}}>
                      <div
                        className="p-3 has-background-white"
                        style={{paddingBottom: 0, borderRadius: 5}}
                      >
                        <QRCodeSVG value={showQRCodeData?.code} />
                      </div>
                    </div>
                  </div>
                  <div className=" has-text-centered title is-4 mt-2 mb-4">
                    {showQRCodeData?.code}
                  </div>
                </>
              )}
            </div>
          )}
          <Link to={`/varna-elite-club?tab=2`}>
            <button
              onClick={handleCloseModal}
              className={classNames('button is-primary is-fullwidth is-outlined mb-2', {})}
            >
              Về Quà đã đổi
            </button>
          </Link>
          <button
            className={classNames('button is-primary is-fullwidth', {
              'is-loading': userProfileModalData?.isGeneratingQRCode,
            })}
            onClick={() => {
              exportAsImage(qrRef.current, `${userGift?.name || 'gift'}`);
            }}
            disabled={!loginFormik.isValid || userProfileModalData?.isLoggingIn}
          >
            Tải ảnh ưu đãi về máy
          </button>
        </>
      </Modal.Card.Body>
    );
  };

  const renderConditionContent = () => {
    return (
      <Modal.Card.Body className="hide-scroll" style={{padding: 40, borderRadius: 16}}>
        <>
          <div className="title is-4 has-text-centered has-text-primary has-text-weight-bold is-uppercase">
            CHƯƠNG TRÌNH KHÁCH HÀNG THÂN THIẾT NUTIFOOD NGÔI NHÀ DINH DƯỠNG (NUTIPOINT)
          </div>
          <div
            className="columns hide-scroll"
            style={{maxHeight: 'calc(100vh - 300px)', overflow: 'auto'}}
          >
            <div className="column">
              <div className="card" style={{border: '1px solid #d2d2d2'}}>
                <div className="card-content" style={{fontSize: 12}}>
                  <div style={{color: '#08633D', fontWeight: 600, paddingBottom: 12}}>
                    1. THÔNG TIN CHƯƠNG TRÌNH
                  </div>
                  <p>
                    Tiên phong ứng dụng tinh hoa, thành tựu khoa học, nghiên cứu và phát triển các
                    giải pháp dinh dưỡng chuẩn u đáp ứng nhu cầu và thể trạng đặc thù của trẻ em và
                    người tiêu dùng Việt Nam, Nutifood không ngừng tự hoàn thiện để nâng tầm trải
                    nghiệm mua sắm của khách hàng tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh
                    Dưỡng.
                  </p>
                  <p>
                    Để tri ân sự ủng hộ nhiệt tình của khách hàng, Nutifood trân trọng giới thiệu
                    chương trình khách hàng thân thiết Nutipoint với những ưu đãi & đặc quyền hấp
                    dẫn dành riêng cho thành viên.
                  </p>
                  <div style={{color: '#08633D', fontWeight: 600, paddingBottom: 12}}>
                    2. ĐỐI TƯỢNG THAM GIA
                  </div>
                  <p>
                    Tất cả khách hàng mua sắm và có đăng ký thông tin tham gia chương trình khách
                    hàng thân thiết Nutipoint tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng
                    toàn quốc hoặc trên{' '}
                    <a href="https://nutifoodshop.com" target="_blank" rel="noopener noreferrer">
                      {' '}
                      website www.nutifoodshop.com
                    </a>
                  </p>
                  <div style={{color: '#08633D', fontWeight: 600, paddingBottom: 12}}>
                    3. CÁC LỢI ÍCH ĐẶC QUYỀN VÀ TIÊU CHÍ XÉT HẠNG THÀNH VIÊN
                  </div>
                  <p>
                    Tất cả thành viên chương trình khách hàng thân thiết Nutipoint tích lũy doanh số
                    mua hàng tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng toàn quốc hoặc trên
                    website www.nutifoodshop.com sẽ nhận được các đặc quyền sau đây:
                  </p>
                  <p>
                    Doanh số tích lũy để tính điểm là tổng số tiền khách hàng thanh toán thực tế
                    theo từng hóa đơn mua sắm tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng
                    toàn quốc hoặc trên website www.nutifoodshop.com
                  </p>
                  <p>
                    Hiệu lực voucher: khách hàng sử dụng voucher mua hàng tại hệ thống cửa hàng
                    Nutifood Ngôi Nhà Dinh Dưỡng toàn quốc hoặc trên website www.nutifoodshop.com,
                    trong thời gian voucher có hiệu lực.
                  </p>
                  <p>
                    Giá trị của voucher đã bao gồm thuế GTGT và không có giá trị quy đổi thành tiền
                    mặt.
                  </p>
                  <p>
                    Tất cả đặc quyền, thể lệ tham gia chương trình khách hàng thân thiết Nutipoint
                    có thể thay đổi theo nội dung được Nutifood thông báo chính thức tại từng thời
                    điểm.
                  </p>
                  <div style={{color: '#08633D', fontWeight: 600, paddingBottom: 12}}>
                    4. NGUYÊN TẮC BẢO MẬT
                  </div>
                  <p>
                    Khách hàng cần cung cấp thông tin (họ tên, ngày tháng năm sinh, số điện thoại,
                    địa chỉ, email) và đồng ý để Nutifood thu thập, lưu trữ, xử lý các thông tin
                    trên để phục vụ cho các chương trình: khách hàng thân thiết, Marketing và nâng
                    cao chất lượng sản phẩm/dịch vụ cho khách hàng của Nutifood.
                  </p>
                  <p>
                    Nutifood chỉ sử dụng thông tin khách hàng vào mục đích phục vụ cho các chương
                    trình: khách hàng thân thiết, Marketing và nâng cao chất lượng sản phẩm/dịch vụ
                    cho khách hàng của Nutifood.
                  </p>
                  <p>
                    Nutifood lưu giữ bảo mật và không tiết lộ thông tin khách hàng cho bên thứ ba.
                  </p>
                  <div style={{color: '#08633D', fontWeight: 600, paddingBottom: 12}}>
                    5. CÁC ĐIỀU KHOẢN KHÁC
                  </div>
                  <p>
                    Khách hàng có trách nhiệm cung cấp chính xác và đầy đủ thông tin cho nhân viên
                    để đăng ký hoặc khi thanh toán để được hưởng những ưu đãi, đặc quyền hấp dẫn
                    dành cho thành viên chương trình khách hàng thân thiết Nutipoint.
                  </p>
                  <p>Việc đăng ký thành viên là hoàn toàn miễn phí.</p>
                  <p>
                    Mỗi khách hàng được cấp 1 mã khách hàng duy nhất trong thời gian chương trình,
                    theo số điện thoại đăng ký.
                  </p>
                  <p>
                    Nutifood không chịu trách nhiệm khi khách hàng quên hoặc cung cấp sai thông tin
                    trong quá trình thanh toán dẫn đến việc không được hưởng những ưu đãi, đặc quyền
                    hấp dẫn dành cho thành viên chương trình khách hàng thân thiết Nutipoint.
                  </p>
                  <p>
                    Khách hàng tham gia chương trình khách hàng thân thiết Nutipoint được quyền tham
                    gia cùng lúc với các chương trình khuyến mãi khác.
                  </p>
                  <p>
                    Chương trình sẽ được Nutifood thông báo đến khách hàng thông qua kênh
                    Facebook/Website/Ấn phẩm tại cửa hàng.
                  </p>
                  <p>
                    Các nội dung ưu đãi, đặc quyền của chương trình chương trình khách hàng thân
                    thiết Nutipoint sẽ được Nutifood thông báo đến khách hàng thông qua kênh
                    Facebook/Website/Ấn phẩm tại cửa hàng.
                  </p>
                  <p>
                    Để được hưởng ưu đãi, đặc quyền của chương trình chương trình khách hàng thân
                    thiết Nutipoint, tại thời điểm thanh toán, khách hàng đọc họ tên & số điện thoại
                    để xác minh thành viên Nutipoint, nhân viên nhập số điện thoại khách vào máy
                    tính tiền sẽ được giảm giá theo chương trình trực tiếp trên hóa đơn hoặc thụ
                    hưởng các quyền lợi khác.
                  </p>
                  <p>
                    Điểm tích lũy sẽ được cập nhật trong vòng 48h kể từ khi phát sinh giao dịch tại
                    hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng toàn quốc. Đối với đơn hàng trên
                    website www.nutifoodshop.com, điểm tích lũy sẽ được cập nhật trong vòng 72h kể
                    từ khi đơn hàng được xác nhận Đã Giao Thành Công. Ngay sau khi điểm tích lũy
                    được cập nhật thành công, hạng thành viên của khách hàng nếu thỏa đủ điều kiện
                    lên hạng sẽ được cập nhật lên tương ứng.
                  </p>
                  <p>
                    Chương trình chỉ áp dụng tại hệ thống cửa hàng Nutifood Ngôi Nhà Dinh Dưỡng toàn
                    quốc và website www.nutifoodshop.com. Không áp dụng cho các đơn hàng trên sàn
                    thương mại điện tử.
                  </p>
                  <p>
                    Không được chuyển nhượng điểm và không được dùng điểm hoặc dùng các quyền lợi
                    thành viên để quy đổi thành tiền mặt dưới mọi hình thức
                  </p>
                  <div style={{color: '#08633D', fontWeight: 600, paddingBottom: 12}}>
                    6. LIÊN HỆ VỚI NUTIFOOD
                  </div>
                  <p>028 39 433 999</p>
                </div>
              </div>
            </div>
          </div>
          <div className=" has-text-centered">
            <button
              style={{
                background: '#fff',
                border: '1px solid #08633D',
                borderRadius: 5,
                paddingTop: 16,
                paddingBottom: 16,
                paddingLeft: 30,
                paddingRight: 30,
                color: '#08633D',
              }}
              onClick={() => {
                setModalState(MODAL_STATES.REGISTER);
              }}
            >
              Quay lại trang đăng ký
            </button>
          </div>
        </>
      </Modal.Card.Body>
    );
  };
  return (
    <Modal show={show} closeOnBlur closeOnEsc onClose={handleCloseModal}>
      <Modal.Card style={{maxWidth: get(MODAL_WIDTHS, modalState)}}>
        {modalState === MODAL_STATES.REGISTER && renderRegisterContent()}
        {modalState === MODAL_STATES.REGISTER_SUCCESS && renderRegisterSuccessContent()}
        {modalState === MODAL_STATES.LOGIN && renderLoginContent()}
        {modalState === MODAL_STATES.OTP && renderOtpContent()}
        {modalState === MODAL_STATES.EDIT && renderEditProfileContent()}
        {modalState === MODAL_STATES.QR_CODE && renderQRCodeContent()}
        {modalState === MODAL_STATES.CONDITIONS && renderConditionContent()}
        {modalState === MODAL_STATES.COMPLETE_UPDATE && renderCompleteUpdateContent()}
        {modalState === MODAL_STATES.QR_CODE_VARNA && renderQRCodeContentVarna()}
      </Modal.Card>
    </Modal>
  );
};

export default UserProfileModal;
