import {createSlice} from '@reduxjs/toolkit';

import {
  generateUserGiftCodeAction,
  getProvincesAction,
  getRewardInfoAction,
  getUserReferralInfoByPhoneNumber,
} from 'appRedux/actions/app';
import {
  generateOTPAction,
  registerAction,
  updateUserProfileAction,
  verifyOTPAction,
} from 'appRedux/actions/auth';
import {signOutAction} from 'appRedux/actions/global';
import {MODAL_STATES} from 'components/UserProfileModal/constanst';
import {toast} from 'react-toastify';

const initialState = {
  userProfileModal: {
    show: false,
    modalState: '',
    phoneNumber: '',
    userGift: null,
    referralInfo: null,
    isLoggingIn: false,
    isRegistering: false,
    isOTPVerifying: false,
    isUpdating: false,
    isGeneratingQRCode: false,
  },
  masterData: {
    provinces: [],
  },
  rewardInfo: {
    totalRewardsAvailable: 0,
    totalRewardsExpired: 0,
    totalRewardsUsed: 0,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleModal: (state, {payload}) => {
      state.userProfileModal.show = payload.show;
      state.userProfileModal.modalState = payload.modalState;
      state.userProfileModal.phoneNumber = payload.phoneNumber;
    },
    resetModal: (state) => {
      state.userProfileModal.show = false;
      state.userProfileModal.modalState = '';
      state.userProfileModal.phoneNumber = '';
    },
    toggleLoginModal: (state, {payload}) => {
      state.userProfileModal.show = payload;
      state.userProfileModal.modalState = payload ? MODAL_STATES.LOGIN : '';
    },
    toggleEditModal: (state, {payload}) => {
      state.userProfileModal.show = payload;
      state.userProfileModal.modalState = payload ? MODAL_STATES.EDIT : '';
    },
    toggleQRCodeModal: (state, {payload}) => {
      state.userProfileModal.show = !!payload;
      state.userProfileModal.modalState = payload ? MODAL_STATES.QR_CODE : '';
      state.userProfileModal.userGift = payload ? payload : null;
    },
    toggleQRCodeModalVarna: (state, {payload}) => {
      state.userProfileModal.show = !!payload;
      state.userProfileModal.modalState = payload ? MODAL_STATES.QR_CODE_VARNA : '';
      state.userProfileModal.userGift = payload ? payload : null;
    },
    resetReferralInfo: (state) => {
      state.userProfileModal.referralInfo = null;
    },
  },
  extraReducers: {
    [signOutAction]: (state, {payload}) => {
      if (payload?.showModal) {
        state.userProfileModal.show = true;
        state.userProfileModal.modalState = MODAL_STATES.LOGIN;
      }
    },
    [getProvincesAction.fulfilled]: (state, {payload}) => {
      state.masterData.provinces = payload;
    },
    [getUserReferralInfoByPhoneNumber.fulfilled]: (state, {payload}) => {
      state.userProfileModal.referralInfo = payload;
    },
    [updateUserProfileAction.pending]: (state) => {
      state.userProfileModal.isUpdating = true;
    },
    [updateUserProfileAction.fulfilled]: (state) => {
      state.userProfileModal.isUpdating = false;
      state.userProfileModal.show = false;
    },
    [updateUserProfileAction.rejected]: (state, {payload}) => {
      state.userProfileModal.isUpdating = false;
      toast(payload, {type: 'error'});
    },
    [getRewardInfoAction.fulfilled]: (state, {payload}) => {
      state.rewardInfo = payload;
    },
    [generateOTPAction.pending]: (state) => {
      state.userProfileModal.isLoggingIn = true;
    },
    [generateOTPAction.fulfilled]: (state) => {
      state.userProfileModal.isLoggingIn = false;
      toast('Mã OTP đã gửi thành công!', {type: 'success'});
    },
    [generateOTPAction.rejected]: (state, {payload}) => {
      state.userProfileModal.isLoggingIn = false;
      toast(payload, {type: 'error'});
    },
    [verifyOTPAction.pending]: (state, {payload}) => {
      state.userProfileModal.isOTPVerifying = true;
      // toast(payload, {type: 'error'});
    },
    [verifyOTPAction.fulfilled]: (state) => {
      state.userProfileModal.isOTPVerifying = false;
      state.userProfileModal.show = false;
    },
    [verifyOTPAction.rejected]: (state, {payload}) => {
      state.userProfileModal.isOTPVerifying = false;
      toast(payload, {type: 'error'});
    },
    [registerAction.pending]: (state) => {
      state.userProfileModal.isRegistering = true;
    },
    [registerAction.fulfilled]: (state) => {
      state.userProfileModal.isRegistering = false;
      // toast('!', {type: 'success'});
    },
    [registerAction.rejected]: (state, {payload}) => {
      state.userProfileModal.isRegistering = false;
      toast(payload, {type: 'error'});
    },
    [generateUserGiftCodeAction.pending]: (state, {payload}) => {
      state.userProfileModal.isGeneratingQRCode = true;
      // toast(payload, {type: 'error'});
    },
    [generateUserGiftCodeAction.fulfilled]: (state) => {
      state.userProfileModal.isGeneratingQRCode = false;
    },
    [generateUserGiftCodeAction.rejected]: (state, {payload}) => {
      state.userProfileModal.isGeneratingQRCode = false;
      toast(payload, {type: 'error'});
    },
  },
});

const {actions, reducer} = appSlice;

export const {
  toggleModal,
  toggleLoginModal,
  resetReferralInfo,
  toggleEditModal,
  toggleQRCodeModal,
  resetModal,
  toggleQRCodeModalVarna,
} = actions;

export default reducer;
