import utils from 'utils/apiUtils';
import {NUTI_FOOD} from './api-definitions';

export const getRewardInfoApi = async () => utils.post(NUTI_FOOD.getRewardInfo);

export const registerWithFullInfoApi = async (payload) =>
  utils.post(NUTI_FOOD.registerWithFullInfo, payload);

export const searchUserGiftApi = async (payload) => utils.post(NUTI_FOOD.searchUserGift, payload);

export const getTopMemberShipApi = (programId) => utils.get(NUTI_FOOD.getTopMemberShip(programId));
export const searchByUserVarnaApi = async (payload) =>
  utils.post(NUTI_FOOD.searchByUserVarna, payload);
// export const getUserRankingVarnaApi = (userId) => utils.get(NUTI_FOOD.getUserRankingVarna(userId));
export const getUserRankingVarnaApi = async (payload) =>
  utils.post(NUTI_FOOD.getUserRankingVarna, payload);
export const searchUserGiftVarnaApi = async (payload) =>
  utils.post(NUTI_FOOD.searchUserGiftVarna, payload);

export const createRedeemGiftVarnaApi = (payload) =>
  utils.post(NUTI_FOOD.postCreateRedeemGiftVarna, payload);

export const getZaloFollowedApi = (phoneNumber) =>
  utils.get(NUTI_FOOD.getZaloFollowed(phoneNumber));

export const getFreeTurnOfPlayApi = async (payload) =>
  utils.post(NUTI_FOOD.getFreeTurnOfPlay, payload);
