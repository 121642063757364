import classNames from 'classnames';
import GiftItem from 'components/giftItem';
import {USER_GIFT_STATUSES} from 'constants/userGift';
import React from 'react';
import {Pagination} from 'react-bulma-components';

const UserGifts = ({
  currentPage = 1,
  totalPages = 1,
  onChangePage = () => {},
  data = [],
  userGiftStatus = null,
  onChangeStatus = () => {},
  onClickUserGift = () => {},
  isLoading = false,
}) => {
  const userGiftStatusesData = [
    {
      key: USER_GIFT_STATUSES.EXCHANGED,
      text: 'Chưa sử dụng',
    },
    {
      key: USER_GIFT_STATUSES.USED,
      text: 'Đã sử dụng',
    },
    {
      key: USER_GIFT_STATUSES.EXPIRED,
      text: 'Hết hạn',
      isLast: true,
    },
  ];
  return (
    <div id="scroller-gift-profile">
      <div className="has-text-centered pb-4">
        {userGiftStatusesData.map((item) => (
          <button
            key={item.key}
            onClick={() => onChangeStatus(item.key)}
            className={classNames('button  has-text-weight-regular', {
              'mr-6 mr-6-mobile': !item.isLast,
              'is-primary': item.key === userGiftStatus,
            })}
          >
            {item.text}
          </button>
        ))}
      </div>
      <div className="columns is-multiline is-flex-mobile">
        {isLoading ? (
          <div className="column is-12 is-flex is-justify-content-center py-6">
            <div>
              <div className="loader is-loading" style={{width: 40, height: 40}}></div>
            </div>
          </div>
        ) : (
          data.map((item, index) => (
            <div
              key={`gift_item__${index}`}
              className={classNames('column is-6 is-3-desktop is-6-mobile mt-2 ', {
                'is-clickable': item.status === USER_GIFT_STATUSES.EXCHANGED,
              })}
              onClick={() => item.status === USER_GIFT_STATUSES.EXCHANGED && onClickUserGift(item)}
            >
              <GiftItem item={item} />
            </div>
          ))
        )}
      </div>
      <div>
        <Pagination
          current={currentPage}
          showFirstLast
          total={totalPages}
          delta={1}
          showPrevNext={false}
          align={'center'}
          onChange={onChangePage}
        />
      </div>
    </div>
  );
};

export default UserGifts;
