import {createAsyncThunk} from '@reduxjs/toolkit';
import {getLPReferralInfoByPhoneNumberApi} from 'apis/endUser';
import {
  getDistrictCodeApi,
  getProvincesApi,
  getStoreDetailByMerchantApi,
  getWardApi,
  getStoreNearestUserByMerchantApi,
  getStoresByMerchantApi,
} from 'apis/masterData';
import {
  getRewardInfoApi,
  searchUserGiftApi,
  getTopMemberShipApi,
  searchByUserVarnaApi,
  getUserRankingVarnaApi,
  searchUserGiftVarnaApi,
  createRedeemGiftVarnaApi,
  getZaloFollowedApi,
  getFreeTurnOfPlayApi,
} from 'apis/thirdParty';
import {
  generateCouponCodeApi,
  getRewardPointApi,
  createRedeemGiftApi,
  postReferralInviteApi,
  getRedeemedGiftDetailApi,
  getInviteReferralByMerchantApi,
} from 'apis/transaction';
import {getSelectOptions} from 'helpers';

import {
  getGiftAllByMerchantApi,
  getGiftAllUserByMerchantApi,
  getGiftDetailApi,
  getGiftViewDetailApi,
} from 'apis/gift';
import {
  getGameSettingDetailByMerchantApi,
  getTurnOfPlayApi,
  getLuckDrawRewardDetailApi,
  getTurnOfPlayGameSettingApi,
  postLuckyDrawDetailFirstPrizeApi,
  postRemainingRewardApi,
} from 'apis/game';

import {retryApi, RETRY_TYPES} from 'apis';
import {get} from 'lodash';

export const getProvincesAction = createAsyncThunk('app/getAllProvinces', async (callback) => {
  const res = await getProvincesApi();
  const data = getSelectOptions(res?.data || [], 'code', 'name');
  data.sort((a, b) => a.shortName.localeCompare(b.shortName));
  callback && callback(data);
  return data;
});

export const getDistrictByProvinceCodeAction = createAsyncThunk(
  'app/getDistrictByProvinceCode',
  async ({provinceCode = '', callback}) => {
    const res = await getDistrictCodeApi({provinceCode});
    const data = res?.data || [];
    callback && callback(data);
    return data;
  }
);

export const getWardByDistrictCodeAction = createAsyncThunk(
  'app/getWardByDistrictCode',
  async ({districtCode = '', callback}) => {
    const res = await getWardApi({id: districtCode});
    const data = res?.data || [];
    callback && callback(data);
    return data;
  }
);

export const getUserReferralInfoByPhoneNumber = createAsyncThunk(
  'app/getUserReferralInfo',
  async ({phoneNumber = '', callback}) => {
    const res = await getLPReferralInfoByPhoneNumberApi(phoneNumber);
    const data = res?.data || null;
    callback && callback(data);
    return data;
  }
);

export const getRewardInfoAction = createAsyncThunk(
  'app/getRewardInfo',
  async (payload = {callback: null}) => {
    const {callback} = payload;
    const res = (await getRewardInfoApi()) || {};
    const data = {...res, data: res?.data || []};
    callback && callback(data);
    return data;
  }
);

export const getRewardHistoryTransactionsAction = createAsyncThunk(
  'app/getRewardHistoryTransactions',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    const res = await getRewardPointApi({payload: body});
    const {data, isSuccess} = res || {};
    if (isSuccess && data) {
      callback && callback(data);
      return data;
    }
  }
);

export const getUserGiftAction = createAsyncThunk(
  'app/getUserGift',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    try {
      const res = await searchUserGiftApi(body);
      if (res) {
        const data = res?.data || [];
        callback && callback(null, data);
        return data;
      } else {
        callback && callback(new Error('Some thing went wrong!'), null);
        return null;
      }
    } catch (error) {
      callback && callback(error, null);
      return error;
    }
  }
);

export const getGiftAllByMerchant = createAsyncThunk(
  'app/getGiftAllByMerchant',
  async ({merchantId = '', callback}) => {
    const res = await getGiftAllByMerchantApi(merchantId);
    const data = res || [];
    callback && callback(data);
    return data;
  }
);

export const generateUserGiftCodeAction = createAsyncThunk(
  'app/generateUserGiftCode',
  async ({userGiftId, callback}, {rejectWithValue, fulfillWithValue}) => {
    try {
      const receiptData = await generateCouponCodeApi({payload: {userGiftId}});
      if (receiptData?.isSuccess && receiptData?.data) {
        const data = await retryApi(receiptData, RETRY_TYPES.TRANSACTION);
        if (data?.data?.isSuccess) {
          const codeData = data?.data?.data;
          callback && callback(codeData);
          return fulfillWithValue(codeData);
        } else {
          throw new Error('Quá trình tạo mã không thành công!');
        }
      } else {
        throw new Error('Quá trình tạo mã không thành công!');
      }
    } catch (error) {
      if (typeof error.response !== 'undefined') {
        if (error.response.status === 400) {
          throw new Error('Quá trình tạo mã không thành công!');
        }
      }
      // return rejectWithValue(error?.message || 'Quá trình tạo mã không thành công!');
    }
  }
);
export const getGiftAllUserByMerchant = createAsyncThunk(
  'app/getGiftAllUserByMerchantApi',
  async ({merchantId = '', callback}) => {
    const res = await getGiftAllUserByMerchantApi(merchantId);
    const data = res || [];
    callback && callback(data);
    return data;
  }
);
export const getGiftDetailById = createAsyncThunk(
  'app/getGiftDetail',
  async ({id = '', callback}) => {
    const res = await getGiftDetailApi(id);

    const data = res || [];
    callback && callback(data);
    return data;
  }
);
export const getGiftViewDetailById = createAsyncThunk(
  'app/getGiftViewDetail',
  async ({id = '', callback}) => {
    const res = await getGiftViewDetailApi(id);
    const data = res || [];
    callback && callback(data);
    return data;
  }
);

export const getStoreDetailByMerchant = createAsyncThunk(
  'app/getStoreDetailByMerchant',
  async ({merchantId = '', callback}) => {
    const res = await getStoreDetailByMerchantApi(merchantId);
    if (res) {
      callback && callback(res);
      return res;
    }
  }
);

export const getStoreNearestUserByMerchant = createAsyncThunk(
  'app/getStoreNearestUserByMerchant',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    const res = await getStoreNearestUserByMerchantApi(body);
    if (res) {
      callback && callback(res);
      return res;
    }
  }
);

export const getStoresByMerchant = createAsyncThunk(
  'app/getStoresByMerchant',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    const res = await getStoresByMerchantApi(body);
    if (res) {
      callback && callback(res);
      return res;
    }
  }
);

export const getProvinces = createAsyncThunk('app/getAllProvincesV1', async ({callback}) => {
  const res = await getProvincesApi();
  const data = res?.data || [];
  callback && callback(data);
  return data;
});

export const createRedeemGift = createAsyncThunk(
  'app/createRedeemGift',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    const receiptData = await createRedeemGiftApi(body);
    const generalError = new Error('Đổi ưu đãi không thành công');
    if (receiptData?.isSuccess && receiptData?.data) {
      const data = await retryApi(receiptData, RETRY_TYPES.TRANSACTION);

      const userGiftId = get(data, 'data.data.0');

      if (userGiftId) {
        const userGiftResponse = await getRedeemedGiftDetailApi({userGiftId});

        const userGift = get(userGiftResponse, 'data');
        if (userGift) {
          callback && callback(null, userGift);
          return userGift;
        } else {
          callback(generalError, null);
        }
      } else {
        callback(generalError, null);
      }
    } else {
      callback(generalError, null);
    }
  }
);

export const postReferralInvite = createAsyncThunk(
  'app/postReferralInvite',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    const res = await postReferralInviteApi(body);
    if (res) {
      callback && callback(res);
      return res;
    }
  }
);

export const getInviteReferralByMerchant = createAsyncThunk(
  'app/getInviteReferralByMerchant',
  async ({merchantId = '', callback}) => {
    const res = await getInviteReferralByMerchantApi(merchantId);
    const data = res || [];
    callback && callback(data);
    return data;
  }
);
export const getGameSettingDetailByMerchant = createAsyncThunk(
  'app/getGameSettingDetailByMerchant',
  async ({merchantId = '', callback}) => {
    try {
      const res = await getGameSettingDetailByMerchantApi({merchantId});
      if (res) {
        const data = res || [];
        callback && callback(null, data);
        return data;
      }
    } catch (error) {
      callback && callback(error, null);
    }
  }
);
export const getTurnOfPlayAction = createAsyncThunk(
  'app/getGameSettingDetailByMerchant',
  async ({gameSettingId = '', callback}) => {
    const res = await getTurnOfPlayApi(gameSettingId);
    const data = res || [];
    callback && callback(data);
    return data;
  }
);

export const getLuckDrawRewardDetailAction = createAsyncThunk(
  'app/getLuckDrawRewardDetailAction',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    try {
      const res = await getLuckDrawRewardDetailApi(body);
      if (res) {
        callback && callback(null, res);
        return res;
      } else {
        callback && callback(new Error('Some thing went wrong!'), null);
        return null;
      }
    } catch (error) {
      callback && callback(error, null);
      return error;
    }
  }
);
export const getResultGameAction = createAsyncThunk(
  'app/buyTurnOfPlayGameSettingAction',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    try {
      const receiptData = await getTurnOfPlayGameSettingApi(body);
      if (receiptData?.isSuccess && receiptData?.data) {
        const data = await retryApi(receiptData, RETRY_TYPES.GAME);
        if (data?.data?.isSuccess) {
          const result = data?.data;
          callback && callback(null, result);
        } else {
          callback && callback(data.data.detail, null);
        }
      } else {
        callback && callback(null);
      }
    } catch (error) {
      callback && callback(error, null);
    }
    return;
  }
);

export const createFirstPrizeOptionAction = createAsyncThunk(
  'app/createFirstPrizeOptionAction',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    const response = await postLuckyDrawDetailFirstPrizeApi(body);
    if (response?.isSuccess && response?.data) {
      callback && callback(response);
      return response;
    }
  }
);
export const getRemainingReward = createAsyncThunk(
  'app/getRemainingReward',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    const response = await postRemainingRewardApi(body);
    if (response?.isSuccess && response?.data) {
      callback && callback(response);
      return response;
    }
  }
);

export const getTopMemberShipAction = createAsyncThunk(
  'app/getTopMemberShip',
  async ({programId = '', callback}) => {
    const res = await getTopMemberShipApi(programId);
    const data = res || [];
    callback && callback(data);
    return data;
  }
);

export const searchByUserVarnaAction = createAsyncThunk(
  'app/searchByUserVarnaAction',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    const response = await searchByUserVarnaApi(body);
    const data = response || [];
    callback && callback(data);
    return data;
  }
);
export const getUserRankingVarnaAction = createAsyncThunk(
  'app/getUserRankingVarnaAction',
  // async ({userId = '', callback}) => {
  //   const res = await getUserRankingVarnaApi(userId);
  //   const data = res || [];
  //   callback && callback(data);
  //   return data;
  // }
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    const response = await getUserRankingVarnaApi(body);
    const data = response || [];
    callback && callback(data);
    return data;
  }
);

export const searchUserGiftVarnaAction = createAsyncThunk(
  'app/searchUserGiftVarnaAction',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    const response = await searchUserGiftVarnaApi(body);
    const data = response || [];
    callback && callback(data);
    return data;
  }
);

export const createRedeemGiftVarna = createAsyncThunk(
  'app/createRedeemGiftVarna',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    const receiptData = await createRedeemGiftVarnaApi(body);
    const generalError = new Error('Đổi ưu đãi không thành công');
    if (receiptData?.isSuccess && receiptData?.data) {
      // const data = await retryApi(receiptData, RETRY_TYPES.TRANSACTION);
      const userGiftId = receiptData.data.userGiftIds[0];
      if (userGiftId) {
        const userGiftResponse = await getRedeemedGiftDetailApi({userGiftId});
        const userGift = get(userGiftResponse, 'data');
        if (userGift) {
          callback && callback(null, userGift);
          return userGift;
        } else {
          callback(generalError, null);
        }
      } else {
        callback(generalError, null);
      }
    } else {
      callback(generalError, null);
    }
  }
);

export const getZaloFollowedAction = createAsyncThunk(
  'app/getZaloFollowedApi',
  async ({phoneNumber = '', callback}) => {
    const res = await getZaloFollowedApi(phoneNumber);
    const data = res;
    callback && callback(data);
    return data;
  }
);

export const getFreeTurnOfPlayAction = createAsyncThunk(
  'app/getFreeTurnOfPlayApi',
  async (payload = {body: {}, callback: null}) => {
    const {callback, body} = payload;
    try {
      const res = await getFreeTurnOfPlayApi(body);
      if (res) {
        callback && callback(null, res);
        return res;
      } else {
        callback && callback(new Error('Some thing went wrong!'), null);
        return null;
      }
    } catch (error) {
      callback && callback(error, null);
      return error;
    }
  }
);
