/**
 * Truncated character with ellipsis
 *
 * @param {string} content The content need truncated
 * @param {number} limit The limit for content truncated
 * @return {string} result after truncated
 */
export const truncatedCharacter = (content, limit = 25) => {
  if (!content || typeof content !== 'string') return '';
  if (content.length > limit) return `${content.substring(0, limit)}...`;
  return content;
};

export const checkForSpecialChar = (string) => {
  const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (specialChars.test(string)) {
    return true;
  }
  return false;
};

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};
