export const ROUTE_NAME = {
  HOME: '/',
  GIFT: '/gift',
  REFERRAL: '/referral',
  REFERRAL_PHONE_NUMBER: '/referral-phone-number',
  USER_PROFILE: '/user-profile',
  STORE: '/store',
  GAMES: '/game',
  VARNA: '/varna-elite-club',
};
