import {appEndPoint} from 'configs/appConfigs';
import {buildRequestUrl} from 'helpers/apiHelper';

const {END_POINT_THIRD_PARTY} = appEndPoint;

const nutiFood = 'nuti-food';
export const NUTI_FOOD = {
  getRewardInfo: buildRequestUrl(
    END_POINT_THIRD_PARTY,
    `${nutiFood}/reward-info/${process.env.REACT_APP_MERCHANT_ID}`
  ),
  registerWithFullInfo: buildRequestUrl(
    END_POINT_THIRD_PARTY,
    `${nutiFood}/register/landing-page/full-info`
  ),
  searchUserGift: buildRequestUrl(END_POINT_THIRD_PARTY, `${nutiFood}/user-gift/search`),
  getTopMemberShip: (programId) =>
    buildRequestUrl(END_POINT_THIRD_PARTY, `varna/top-membership-by-tier/${programId}`),

  searchByUserVarna: buildRequestUrl(
    END_POINT_THIRD_PARTY,
    `varna/transaction/retail-transaction/search-by-user`
  ),
  //getUserRankingVarna:(userId) => buildRequestUrl(END_POINT_THIRD_PARTY, `varna/user/with-ranking/${userId}`),
  getUserRankingVarna: buildRequestUrl(END_POINT_THIRD_PARTY, `varna/user/with-ranking`),
  searchUserGiftVarna: buildRequestUrl(END_POINT_THIRD_PARTY, `varna/user-gift/search`),
  postCreateRedeemGiftVarna: buildRequestUrl(
    END_POINT_THIRD_PARTY,
    `varna/redeem-transaction/create-from-web`
  ),

  getZaloFollowed: (phoneNumber) =>
    buildRequestUrl(END_POINT_THIRD_PARTY, `zalo/nutifood/check-followed/${phoneNumber}`),

  getFreeTurnOfPlay: buildRequestUrl(END_POINT_THIRD_PARTY, `nuti-food/free-turn-of-play/search`),
};

//end
