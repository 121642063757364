import {createAsyncThunk} from '@reduxjs/toolkit';
import {retryApi} from 'apis';
import {
  getUserInfoApi,
  otpGeneratedApi,
  otpNutiGeneratedApi,
  otpVerifyApi,
  updateUserInfoApi,
} from 'apis/endUser';
import {registerWithFullInfoApi} from 'apis/endUser';
import {setAuthToken} from 'utils/apiUtils';
import {localStorageSetItem} from 'utils/storage';

export const getUserProfileAction = createAsyncThunk('auth/getUserProfile', async () => {
  const res = await getUserInfoApi(process.env.REACT_APP_MERCHANT_ID);
  return res;
});

export const updateUserProfileAction = createAsyncThunk(
  'auth/updateUserProfile',
  async (payload, {rejectWithValue, fulfillWithValue, dispatch}) => {
    try {
      const receiptData = await updateUserInfoApi(payload);
      if (receiptData?.isSuccess && receiptData?.data) {
        const res = await retryApi(receiptData);
        if (res?.isSuccess) {
          dispatch(getUserProfileAction());
        }
        return fulfillWithValue({...payload});
      } else {
        throw new Error('Cập nhật thông tin thất bại!');
      }
    } catch (error) {
      return rejectWithValue(error?.message || 'Cập nhật thông tin thất bại!');
    }
  }
);

export const verifyOTPAction = createAsyncThunk(
  'auth/verifyOTP',
  async ({phoneNumber, otp, callback}, {rejectWithValue, fulfillWithValue, dispatch}) => {
    try {
      const params = {
        phoneNumber,
        otp,
        otpType: 'QUICK_LOGIN',
        clientType: 3,
        deviceId: 'string',
        deviceToken: 'string',
      };
      const data = await otpVerifyApi(params);

      if (data?.isSuccess && data?.data) {
        const res = data?.data;
        if (res?.accessToken) {
          setAuthToken(res?.accessToken);
          localStorageSetItem('accessToken', res?.accessToken);
          localStorageSetItem('refreshToken', res?.refreshToken);
        }
        callback && callback(res);
        dispatch(getUserProfileAction());
        return fulfillWithValue(res);
      } else {
        throw new Error('Mã OTP không hợp lệ');
      }
    } catch (error) {
      return rejectWithValue(error?.message || 'Mã OTP không hợp lệ');
    }
  }
);

export const generateOTPAction = createAsyncThunk(
  'auth/generateOTP',
  async (
    {phoneNumber, callback, requestType = 'NUTIFOOD'},
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const params = {
        phoneNumber,
        requestType,
        deviceId: 'web',
        deviceType: 1,
        userIPAddress: '',
        requiredMembershipOfMerchant: process.env.REACT_APP_MERCHANT_ID,
      };
      const data = await otpGeneratedApi(params);
      if (data?.isSuccess && data?.data) {
        const otpType = data?.data?.otpType;

        // callback && callback(otpType);
        // return fulfillWithValue(otpType);

        // const data = await retryApi(receiptData);

        callback && callback(otpType);
        return fulfillWithValue(otpType);
      } else {
        throw new Error('Gửi mã OTP không thành công!');
      }
    } catch (error) {
      return rejectWithValue(error?.message || 'Gửi mã OTP không thành công!');
    }
  }
);

export const registerAction = createAsyncThunk(
  'auth/register',
  async ({data, callback}, {rejectWithValue, fulfillWithValue, dispatch}) => {
    try {
      const params = {
        ...data,
        gender: data?.gender?.value,
        province: data?.province?.value,
        district: data?.district?.value,
        ward: data?.ward?.value,
        merchantId: process.env.REACT_APP_MERCHANT_ID,
        username: data?.phoneNumber,
      };
      const res = await registerWithFullInfoApi(params);
      const responseData = res?.data || {};
      const {accessToken, refreshToken} = responseData;
      if (accessToken) {
        setAuthToken(accessToken);
        localStorageSetItem('accessToken', accessToken);
        localStorageSetItem('refreshToken', refreshToken);
      }
      callback && callback(accessToken);
      dispatch(getUserProfileAction());
      return fulfillWithValue(accessToken);
    } catch (error) {
      return rejectWithValue(error?.message || 'Đăng ký không thành công!');
    }
  }
);
