import {Layout} from 'antd';
import React from 'react';
import './styles.scss';

import Header from '../header';
import Footer from 'components/footer';
import UserProfileModal from 'components/UserProfileModal';
const {Content} = Layout;
const layout = ({headerProps = {}, footerProps = {}, userProfileModalProps = {}, children}) => {
  return (
    <>
      <Header {...headerProps}></Header>
      <Content>{children}</Content>
      <Footer {...footerProps} />
      <UserProfileModal {...userProfileModalProps} />
    </>
  );
};

export default layout;
