import utils from 'utils/apiUtils';
import {GAME, GAME_CATEGORY, GAME_RESULT, GAME_SETTING} from './api-definitions';

export const getAllGameApi = () => utils.get(GAME.getAllGames);
export const getResultGameApi = (payload) => utils.post(GAME_RESULT.postInquire, payload);
export const getCategoryGameApi = () => utils.get(GAME_CATEGORY.getAllGameCategories);

export const searchGameSettingApi = (payload) => {
  const dataPost = {
    pageIndex: 1,
    pageSize: 20,
    categories: [],
    sortBy: [],
  };
  const data = {
    ...dataPost,
    ...payload,
  };
  return utils.post(GAME_SETTING.postSearchGameSetting, data);
};

export const generateCodeGameApi = (payload) =>
  utils.post(GAME_SETTING.postGenerateCodeGame, payload);

export const gameSettingDetailByIdApi = ({gameSettingId}) =>
  utils.get(GAME_SETTING.getGameSettingDetailById(gameSettingId));

export const gameSettingLotterySearchApi = (payload) =>
  utils.post(GAME_SETTING.postSearchGameSettingLottery, payload);

// game history
export const gameSettingHistorySearchApi = (payload) =>
  utils.post(GAME_SETTING.postSearchGameSettingHistory, payload);

export const getGameLuckyRotationHistoryApi = (payload) =>
  utils.post(GAME_SETTING.postGameSettingHistory, payload);

export const getGameSettingDetailByMerchantApi = ({merchantId}) =>
  utils.get(GAME_SETTING.getGameSettingDetailByMerchant(merchantId));

export const getTurnOfPlayApi = (gameSettingId) =>
  utils.get(GAME_SETTING.getTurnOfPlaySetting(gameSettingId));

export const getLuckDrawRewardDetailApi = (payload) =>
  utils.post(GAME_SETTING.getLuckDrawRewardDetail, payload);
export const getTurnOfPlayGameSettingApi = (payload) =>
  utils.post(GAME_SETTING.getTurnOfPlayGameSetting, payload);

export const postLuckyDrawDetailFirstPrizeApi = (payload) =>
  utils.post(GAME_SETTING.postLuckyDrawDetailFirstPrize, payload);

export const postRemainingRewardApi = (payload) =>
  utils.post(GAME_SETTING.postRemainingReward, payload);
