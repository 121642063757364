import {ROUTE_NAME} from 'constants/routes';

export const userInfoSelector = (state) => state.auth.userInfo;
export const isLogInSelector = (state) => state.auth.isLogIn;
export const referralLinkSelector = (state) => {
  const phoneNumber = state?.auth?.userInfo?.phoneNumber;
  return phoneNumber
    ? `${window.location.host}${ROUTE_NAME.REFERRAL_PHONE_NUMBER}/${phoneNumber}`
    : '';
};
