export const USER_GIFT_STATUSES = {
  EXCHANGED: 1,
  USED: 2,
  LOCKED: 3,
  EXPIRED: 9,
};

export const USER_GIFT_SOURCES = {
  NUTIFOOD_LANDINGPAGE: 'NUTIFOOD_LANDINGPAGE'
}
