import {appEnvironment} from 'configs/appConfigs';

export const buildRequestUrl = (baseUrl, path, version = '1') => {
  if (!version) return `${baseUrl}/${path}`;
  const useVersioningEnvironments = ['local', 'dev', 'prod', 'staging'];
  if (useVersioningEnvironments.includes(appEnvironment)) {
    return `${baseUrl}/v${version}/${path}`;
  }
  return `${baseUrl}/${path}`;
};
