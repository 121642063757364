import React, {useEffect} from 'react';
import Layout from '../../components/layout';
import Select from 'react-select';
import {
  getGiftDetailById,
  getGiftViewDetailById,
  getStoreDetailByMerchant,
  getProvinces,
  getDistrictByProvinceCodeAction,
  getWardByDistrictCodeAction,
  createRedeemGift,
  generateUserGiftCodeAction,
  getGiftAllUserByMerchant,
  getRewardInfoAction,
} from 'appRedux/actions/app';
import {useDispatch, useSelector} from 'react-redux';
import {isLogInSelector, userInfoSelector} from 'appRedux/selectors/auth';
import {Link, useParams} from 'react-router-dom';
import image_default from 'images/image_default.png';
import market from 'images/market.png';
import {toast} from 'react-toastify';
import {Modal} from 'react-bulma-components';
import {QRCodeSVG} from 'qrcode.react';
import moment from 'moment';
import exportAsImage, {getSelectOptions} from 'helpers';
import {useNavigate} from 'react-router-dom';
import Contact from 'components/contact';
import classNames from 'classnames';
import {toggleQRCodeModal} from 'appRedux/slices/appSlice';
import {ROUTE_NAME} from 'constants/routes';
import parse from 'html-react-parser';
import {rewardInfoSelector} from 'appRedux/selectors/app';
import {Pagination} from 'react-bulma-components';
import phone from 'images/phone.png';
import {USER_GIFT_SOURCES} from 'constants/userGift';
const VoucherDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useNavigate();
  const navigate = useNavigate();
  const isLogIn = useSelector(isLogInSelector);
  const [formData, setFormData] = React.useState({
    giftDetail: '',
    conditionDescription: '',
    useDescription: '',
    allStores: false,
    applicableStores: [],
  });
  const [storeList, setStoreList] = React.useState([]);
  const [storeListFilter, setStoreListFilter] = React.useState([]);
  const [dataProvince, setDataProvince] = React.useState([]);
  const [dataDistrict, setDataDistrict] = React.useState([]);
  const [dataWard, setDataWard] = React.useState([]);
  const [dataFiter, setDataFilter] = React.useState({});
  // const [showPopupSuccess, setShowPopupSusscee] = React.useState(false);
  // const qrRef = React.useRef();
  const [dataGiftUser, setDataGiftUser] = React.useState([]);
  const [numberShowMore, setNumberShowMore] = React.useState(6);
  // const [qrCodeData, setQRCodeData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const userInfo = useSelector(userInfoSelector);
  const rewardInfo = useSelector(rewardInfoSelector);
  const [pages, setPages] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(0);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (isLogIn) {
      dispatch(
        getGiftDetailById({
          id: params.id,
          callback: (data) => {
            setFormData(data);
            listStores(data);
          },
        })
      );
      dispatch(
        getGiftAllUserByMerchant({
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          callback: (data) => {
            setDataGiftUser(data);
          },
        })
      );
      dispatch(getRewardInfoAction());
    } else {
      dispatch(
        getGiftViewDetailById({
          id: params.id,
          callback: (data) => {
            setFormData(data);
            listStores(data);
          },
        })
      );
    }
  }, [isLogIn]);

  useEffect(() => {
    dispatch(
      getProvinces({
        callback: (data) => {
          let provinces = [
            {
              shortName: 'a',
              value: '',
              label: 'Tất cả',
            },
          ];
          data.map((item) => {
            provinces.push({
              label: item.name,
              value: item.code,
              shortName: item.shortName,
            });
          });
          setDataProvince(provinces.sort((a, b) => a.shortName.localeCompare(b.shortName)));
        },
      })
    );
  }, []);

  const listStores = (dataStore) => {
    if (dataStore?.allStores) {
      dispatch(
        getStoreDetailByMerchant({
          merchantId: process.env.REACT_APP_MERCHANT_ID,
          callback: (data) => {
            setStoreList(data.data);
            setStoreListFilter(data.data);
          },
        })
      );
    } else {
      setStoreListFilter(dataStore?.applicableStores);
      setStoreList(dataStore?.applicableStores);
    }
  };
  const onChangeSelect = (name) => (event) => {
    setPerPage(0);
    setCurrentPage(1);
    setPages(10);
    if (name == 'province') {
      setDataFilter({
        ...dataFiter,
        province: event.value,
        district: '',
      });
      if (event.value) {
        getDistrictByProvinceId(event.value);
        setStoreListFilter(
          storeList?.filter((item) => item.location && item.location.province == event.value)
        );
      } else {
        setStoreListFilter(storeList);
      }
    }
    if (name == 'district') {
      setDataFilter({
        ...dataFiter,
        district: event.value,
        ward: '',
      });
      if (event.value) {
        getWardByDistrictId(event.value);
        setStoreListFilter(
          storeList?.filter((item) => item?.location && item?.location.district == event.value)
        );
      } else {
        setStoreListFilter(
          storeList?.filter((item) => item.location.province == dataFiter.province)
        );
      }
    } else if (name == 'ward') {
      setDataFilter({
        ...dataFiter,
        ward: event.value,
      });
      if (event.value) {
        setStoreListFilter(
          storeList?.filter((item) => item.location && item.location.ward == event.value)
        );
      } else {
        setStoreListFilter(
          storeList?.filter((item) => item.location.district == dataFiter.district)
        );
      }
    }
  };
  const getDistrictByProvinceId = async (provinceCode) => {
    dispatch(
      getDistrictByProvinceCodeAction({
        provinceCode: provinceCode,
        callback: (data) => {
          let districts = [
            {
              label: 'Bỏ chọn',
              value: '',
            },
          ];
          data.map((item) => {
            districts.push({
              label: item.name,
              value: item.code,
            });
          });
          setDataDistrict(districts);
        },
      })
    );
  };
  const getWardByDistrictId = async (districtCode) => {
    dispatch(
      getWardByDistrictCodeAction({
        districtCode: districtCode,
        callback: (data) => {
          let wards = [
            {
              label: 'Bỏ chọn',
              value: '',
            },
          ];
          data.map((item) => {
            wards.push({
              label: item.name,
              value: item.code,
            });
          });
          setDataWard(wards);
        },
      })
    );
  };
  const handleConfirm = () => {
    setIsLoading(!isLoading);
    dispatch(
      createRedeemGift({
        body: {
          giftId: params.id,
          quantity: 1,
          rewardId: formData.rewardId,
          source: USER_GIFT_SOURCES.NUTIFOOD_LANDINGPAGE,
        },
        callback: (error, userGift) => {
          setIsLoading(false);
          if (error) {
            toast.error(error?.message || 'Không thành công! Vui lòng thử lại sau', {
              autoClose: 2000,
              position: 'top-center',
            });
          } else if (userGift) {
            dispatch(toggleQRCodeModal({...userGift, showTitle: true, showProfile: true}));
          }
        },
      })
    );
  };

  const handleShowMore = () => {
    setNumberShowMore(dataGiftUser.filter((f) => f.canRedeemByMerchantReward).length);
  };
  const onChangePage = (page) => {
    setCurrentPage(page);
    setPages(page * 10);
    setPerPage(page * 10 - 10);
  };
  const totalPages = Math.ceil(storeListFilter?.length / 10);
  return (
    <Layout headerProps={{index: 1}}>
      <nav className="breadcrumb px-6 py-3 breadcrumbs-display" aria-label="breadcrumbs">
        <ul>
          <li>
            <a href={ROUTE_NAME.GIFT}>Ưu đãi & Quà</a>
          </li>
          <li>
            <a href={ROUTE_NAME.GIFT}>
              {isLogIn && formData.canRedeemByMerchantReward
                ? 'Ưu đãi đổi được ngay'
                : 'Ưu đãi chưa đủ điều kiện đổi'}
            </a>
          </li>
          <li className="is-active">
            <a href="#" aria-current="page">
              Chi Tiết Ưu Đãi
            </a>
          </li>
        </ul>
      </nav>
      <div className="container-home">
        <div className="container">
          <div className="columns">
            <div className="column is-5-tablet is-5-desktop is-3-fullhd is-margin-rigth-detail">
              <div
                className="card"
                style={{
                  border: '1px solid #D2D2D2',
                  //maxWidth: 290,
                }}
              >
                <div className="card-image">
                  <div className="card-content">
                    <div
                      style={{
                        position: 'relative',
                      }}
                    >
                      <figure className="image is-image-voucher-detal">
                        {formData?.thumbnailImage ? (
                          <img
                            src={formData.thumbnailImage}
                            style={{
                              borderRadius: 24,
                            }}
                          />
                        ) : (
                          <img
                            src={image_default}
                            style={{
                              borderRadius: 24,
                            }}
                          />
                        )}
                      </figure>
                      <div className="gift-group-tile-detal">
                        <div className="voucher-name">{formData?.name}</div>
                        <div className="voucher-discount">{formData?.discount}</div>
                        <div className="voucher-group-point">
                          <span>
                            <span className="voucher-point">
                              {formData?.rewardValue ? formData?.rewardValue.toLocaleString() : 0}{' '}
                              ĐIỂM
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <table
                      className="table is-fullwidth"
                      style={{
                        marginTop: 24,
                        marginBottom: 0,
                      }}
                    >
                      <tr>
                        <td
                          className="pl-0 has-text-weight-semibold has-text-grey"
                          style={{
                            border: 'none',
                          }}
                        >
                          Số lượng đổi
                        </td>
                        <td
                          className="pr-0 has-text-weight-semibold has-text-primary"
                          style={{
                            textAlign: 'right',
                            border: 'none',
                          }}
                        >
                          1 Ưu đãi
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="pl-0 has-text-weight-semibold has-text-grey"
                          style={{
                            border: 'none',
                          }}
                        >
                          Tổng thanh toán
                        </td>
                        <td
                          className="pr-0 has-text-weight-semibold has-text-primary"
                          style={{
                            textAlign: 'right',
                            border: 'none',
                          }}
                        >
                          {formData?.rewardValue ? formData?.rewardValue.toLocaleString() : 0} điểm
                        </td>
                      </tr>
                    </table>

                    {formData.canRedeemByMerchantReward || isLogIn ? (
                      <>
                        <div className="title has-text-primary is-6 mt-3">
                          Bạn có:{' '}
                          {rewardInfo?.totalRewardsAvailable
                            ? rewardInfo?.totalRewardsAvailable.toLocaleString()
                            : 0}{' '}
                          điểm
                        </div>
                        <button
                          className={classNames(
                            formData.canRedeemByMerchantReward
                              ? 'button mr-3 is-primary is-fullwidth'
                              : 'button mr-3 primary-default is-fullwidth',
                            {
                              'is-loading': isLoading,
                            }
                          )}
                          style={{
                            borderRadius: 8,
                          }}
                          onClick={handleConfirm}
                          disabled={
                            !isLogIn ||
                            new Date(formData.expiryDateTime) < new Date() ||
                            !formData.canRedeemByMerchantReward ||
                            formData.remainingGifts === 0
                          }
                        >
                          {formData.remainingGifts === 0
                            ? 'QUÀ ĐÃ ĐƯỢC ĐỔI HẾT'
                            : formData.canRedeemByMerchantReward
                            ? 'XÁC NHẬN ĐỔI ƯU ĐÃI'
                            : 'CHƯA ĐỦ ĐIỀU KIỆN ĐỔI'}
                        </button>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            textAlign: 'center',
                            color: '#C62828',
                            fontSize: 14,
                            fontWeight: 600,
                            paddingTop: 16,
                          }}
                        >
                          Hãy đăng nhập để đổi ưu đãi này
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="title-text-has-name">Chi tiết quà tặng</div>
              {formData?.giftDetail ? parse(formData?.giftDetail) : ''}
              <div className="title-text-has-name">Hướng dẫn sử dụng</div>
              {formData?.useDescription ? parse(formData?.useDescription) : ''}
              <div className="title-text-has-name">Điều khoản sử dụng</div>
              {formData?.conditionDescription ? parse(formData?.conditionDescription) : ''}
              <div
                style={{
                  marginBottom: 18,
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                <span
                  style={{
                    color: '#343434',
                  }}
                >
                  Cửa hàng áp dụng
                </span>
                <span
                  style={{
                    float: 'right',
                    color: '#08633D',
                  }}
                >
                  {isLogIn && <>{storeListFilter?.length.toLocaleString()} cửa hàng</>}
                </span>
              </div>
              <div
                className="card"
                style={{
                  border: '1px solid #D2D2D2',
                }}
              >
                <div className="card-content">
                  <div className="columns">
                    <div className="column">
                      <div className="field">
                        <label className="label">Tỉnh/Thành</label>
                        <div className="control">
                          <Select
                            placeholder="Chọn Tỉnh/Thành"
                            options={dataProvince}
                            onChange={onChangeSelect('province')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="field">
                        <label className="label">Quận/Huyện </label>
                        <div className="control">
                          <Select
                            name="district"
                            id="district"
                            placeholder="Chọn Quận/Huyện"
                            onChange={onChangeSelect('district')}
                            options={dataDistrict}
                            isDisabled={!dataFiter.province}
                            value={
                              dataFiter.district
                                ? dataDistrict.find((option) => option.value === dataFiter.district)
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="field">
                        <label className="label">Phường/Xã</label>
                        <div className="control">
                          <Select
                            placeholder="Chọn Phường/Xã"
                            onChange={onChangeSelect('ward')}
                            options={dataWard}
                            isDisabled={!dataFiter.district}
                            value={
                              dataFiter.ward
                                ? dataWard.find((option) => option.value === dataFiter.ward)
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <div>
                        {storeListFilter?.slice(perPage, pages)?.map((item, index) => {
                          return (
                            <>
                              <div className="columns is-border-column">
                                <div className="column has-text-weight-semibold column-display-mobile-one">
                                  <div className="has-text-primary">{item?.name}</div>
                                  {item?.phone && (
                                    <div className="icon-text">
                                      <span className="icon" style={{color: '#08633D'}}>
                                        <img src={phone} style={{height: 18}} />
                                      </span>
                                      <span>{item?.phone}</span>
                                    </div>
                                  )}
                                </div>
                                <div className="column has-text-weight-semibold column-display-mobile">
                                  <div
                                    style={{
                                      position: 'relative',
                                    }}
                                  >
                                    {item?.location.address && (
                                      <div
                                        style={{
                                          position: 'absolute',
                                          top: '60%',
                                          transform: 'translate(0, -50%)',
                                        }}
                                      >
                                        <img
                                          src={market}
                                          style={{
                                            height: 20,
                                            width: 16,
                                          }}
                                        />
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        marginLeft: 30,
                                      }}
                                    >
                                      {item?.location.address}
                                      {item?.location.wardName
                                        ? ', ' + item?.location.wardName
                                        : ''}
                                      {item?.location.districtName
                                        ? ', ' + item?.location.districtName
                                        : ''}
                                      {item?.location.provinceName
                                        ? ', ' + item?.location.provinceName
                                        : ''}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                        <div
                          style={{
                            marginTop: 15,
                          }}
                        >
                          <Pagination
                            pages={pages}
                            current={currentPage}
                            showFirstLast
                            total={totalPages}
                            delta={2}
                            showPrevNext={false}
                            align={'center'}
                            onChange={(page) => onChangePage(page)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="voucher-group-img">
                {dataGiftUser?.filter((f) => f.canRedeemByMerchantReward).length > 0 && (
                  <div className="gallery-title">Ưu đãi đổi được ngay</div>
                )}
                <div className="columns is-multiline">
                  {dataGiftUser
                    ?.filter((f) => f.canRedeemByMerchantReward)
                    ?.slice(0, numberShowMore)
                    .map((item, index) => {
                      return (
                        <>
                          <div
                            key={index}
                            className="column is-one-quarter-desktop-gift is-half-tablet"
                            style={{padding: 10}}
                          >
                            <a href={`/gift/${item.id}`}>
                              <div
                                className="card-image"
                                style={{
                                  position: 'relative',
                                }}
                              >
                                <figure className="image is-image-voucher">
                                  {item?.thumbnailImage ? (
                                    <img src={item.thumbnailImage} alt="" />
                                  ) : (
                                    <img src={image_default} alt="" />
                                  )}
                                </figure>

                                <div className="gift-group-tile">
                                  <div className="voucher-name">{item?.name}</div>
                                  <div className="voucher-discount">{item?.discount}</div>
                                  <div className="voucher-group-point">
                                    <span>
                                      <span className="voucher-point">
                                        {item?.rewardValue.toLocaleString()} ĐIỂM
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </>
                      );
                    })}
                </div>
                {numberShowMore <
                  dataGiftUser?.filter((f) => f.canRedeemByMerchantReward).length && (
                  <>
                    <div className="columns clearfix">
                      <div
                        className="column"
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <button className="button-view-more" onClick={handleShowMore}>
                          Xem thêm
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <Contact />
        </div>
      </div>
    </Layout>
  );
};
export default VoucherDetail;
