import utils from 'utils/apiUtils';
import {GIFTS} from './api-definitions';

export const getGiftAllByMerchantApi = (merchantId) =>
  utils.get(GIFTS.getGiftAllByMerchant(merchantId));

export const getGiftAllUserByMerchantApi = (merchantId) =>
  utils.get(GIFTS.getGiftAllUserByMerchant(merchantId));

export const getGiftDetailApi = (id) => utils.get(GIFTS.getGiftDetail(id));
export const getGiftViewDetailApi = (id) => utils.get(GIFTS.getGiftViewDetail(id));

export const getGiftAllByUserApi = (payload) => {
  const dataPost = {
    pageIndex: 1,
    pageSize: 20,
    merchantId: '',
    sortBy: [],
  };
  const data = {
    ...dataPost,
    ...payload,
  };
  return utils.post(GIFTS.getGiftByUser, data);
};
