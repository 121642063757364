import React, {useState} from 'react';
import './styles.scss';
import image_default from 'images/image_default.png';
import {USER_GIFT_STATUSES} from 'constants/userGift';
import moment from 'moment';
const GiftItem = ({item = {}}) => {
  return (
    <div className="gift-item">
      <div className="card-image">
        <figure className="image is-image-voucher">
          <img src={item?.image || image_default} />
        </figure>
        <div className="card-content is-overlay voucher-group-title">
          {item?.name && <div className="voucher-name">{item.name}</div>}
          {item?.discount && <div className="voucher-discount">{item.discount}</div>}
          <div className="voucher-group-point">
            <span>
              {item?.status !== USER_GIFT_STATUSES.USED &&
                moment(item?.expiryDateTime).isValid() &&
                item?.expiryDateTime && (
                  <span className="voucher-point">{`HSD: ${moment(item.expiryDateTime).format(
                    'DD/MM/YYYY'
                  )}`}</span>
                )}
              {/* <span style={{color: '#fff'}}>|</span>
              <span className="voucher-rate">Hạng Bac</span> */}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftItem;
