export const GENDER_CONSTANTS = [
  {
    id: 1,
    name: 'Nam',
    val: 'M',
  },
  {
    id: 2,
    name: 'Nữ',
    val: 'F',
  },
  {
    id: 3,
    name: 'Khác',
    val: 'O',
  },
];
export const GENDER_OPTIONS = [
  {
    id: 1,
    label: 'Nam',
    value: 'M',
  },
  {
    id: 2,
    label: 'Nữ',
    value: 'F',
  },
  {
    id: 3,
    label: 'Khác',
    value: 'O',
  },
];
