import React from 'react';
import Layout from 'components/layout';

const PageNotFound = () => {
  return (
    <Layout>
      <section className="section is-medium">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column has-text-centered">
              <h1 className="title">404 Page Not Found</h1>
              <p className="subtitle">
                An unexpected error has occurred. Please contact the site owner.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PageNotFound;
