import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import PropTypes from 'prop-types';
import Benner from 'images/benner_3.jpg';
import classNames from 'classnames';

Index.propTypes = {
  axis: PropTypes.string,
  interval: PropTypes.string,
  showThumbs: PropTypes.bool,
  showStatus: PropTypes.bool,
  images: PropTypes,
  Array,
};
function Index(props) {
  const {axis, interval, showThumbs, showStatus, images} = props;
  return (
    <div
      style={{
        marginTop: 76,
        marginBottom: -78,
      }}
    >
      <Carousel
        autoPlay
        axis={axis}
        infiniteLoop
        interval={interval}
        showThumbs={showThumbs}
        showStatus={showStatus}
      >
        {images &&
          images.map((item) => (
            <div
              key={item}
              className={classNames({
                'is-clickable': !!item?.onClick,
              })}
              onClick={item?.onClick}
            >
              <img src={item?.src || item} />
            </div>
          ))}
      </Carousel>
    </div>
  );
}
export default Index;
