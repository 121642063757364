import utils from 'utils/apiUtils';
import {ACTION_RESULT, CONTENT, MISSION} from './api-definitions';
import _ from 'lodash';

export const actionResultContentInquireApi = (payload) =>
  utils.post(ACTION_RESULT.postInquire, payload);

export const getMissionDetailApi = ({missionId}) =>
  utils.get(`${MISSION.getDetailsMissionByMissionId}/${missionId}`);
export const getAllMissionTypeApi = () => utils.get(MISSION.getAllMissionType);
export const getAllQuestionTypeApi = () => utils.get(MISSION.getAllQuestionType);
export const postMissionApi = (payload) => utils.post(MISSION.postMission, payload);
export const checkUserMissionApi = (payload) => utils.post(MISSION.postCheckUserMission, payload);
export const getMaxMinFilterContentApi = () => utils.get(MISSION.getMaxMinFilterContent);
export const getMaxMinFilterContentByMerchantApi = ({merchantId}) =>
  utils.get(`${MISSION.getMaxMinFilterContentByMerchantId}/${merchantId}`);

export const searchEarningSuggestionApi = ({payload = {}}) => {
  const params = {
    pageIndex: 1,
    pageSize: 10,
    ...payload,
  };
  return utils.post(CONTENT.postSearchEarningSuggestion, params);
};
export const getContentDetailApi = ({contentId, missionId}) => {
  const params = {
    contentId,
    missionId,
  };
  return utils.post(CONTENT.postContentDetail, params);
};
export const getSearchContentApi = ({payload}) => {
  return utils.post(CONTENT.getSearchContent, payload);
};
export const getAllContentMobileApi = () => utils.get(CONTENT.getAllContentMobile);
export const getContentZoneApi = () => utils.get(CONTENT.getZoneContent);
export const updateCountContentViewApi = ({contentId}) =>
  utils.put(`${CONTENT.putUpdateViewContent}/${contentId}`);
export const getAllContentTypeApi = () => utils.get(CONTENT.getAllContentType);
export const filterContentApi = ({payload = {}}) => {
  const payloadParams = {...payload};

  if (_.isEmpty(payloadParams.sortBy)) {
    payloadParams.sortBy = [
      {
        fieldName: 'CreatedTime',
        ascending: false,
      },
    ];
  }
  const data = {
    pageIndex: 1,
    pageSize: 10,
    ...payloadParams,
  };
  return utils.post(CONTENT.postSearchMobileContent, data);
};
export const subscribeContentApi = ({contentId}) =>
  utils.post(`${CONTENT.postSubscribeContent}/${contentId}`);
