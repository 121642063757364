import React from 'react';
import {Pagination} from 'react-bulma-components';
import moment from 'moment';

const RewardOverview = ({rewardInfo = {}}) => {
  const rewardValues = [
    {text: 'Tổng điểm', value: rewardInfo?.totalRewardsAvailable, isActive: true},
    {text: 'Đã dùng', value: rewardInfo?.totalRewardsUsed},
    {text: 'Hết hạn', value: rewardInfo?.totalRewardsExpired},
  ];
  return (
    <>
      <div className="columns">
        {rewardValues.map((item, index) => {
          return (
            <div className="column is-4" key={index}>
              <div className="card">
                <div className="card-content">
                  <div className="title is-6 has-text-grey">{item.text}</div>
                  <div
                    className={`title has-text-weight-bold ${
                      item?.isActive ? 'has-text-primary' : 'has-text-grey'
                    }`}
                  >
                    {item.value}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div>
        <table className="table roundedCorners is-fullwidth is-hovered">
          <thead className=" has-background-primary">
            <tr>
              <th className=" has-text-white has-text-weight-semibold pl-5">Điểm sắp hết hạn</th>
              <th className=" has-text-warning has-text-weight-semibold has-text-right pr-5">
                {rewardInfo?.data?.reduce((prev, curr) => prev + curr?.totalValue || 0, 0)} điểm
              </th>
            </tr>
          </thead>
          {rewardInfo?.data?.map((item, index) => (
            <tr key={`table_key_${item?.id || index}`}>
              <td className="pl-5">{`Tháng ${moment(item.expiryDateTime).format('MM-YYYY')}`}</td>
              <td className="pr-5 has-text-right">{item?.totalValue}</td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
};

export default RewardOverview;
