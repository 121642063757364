import React, {useState} from 'react';
import logo from 'images/logo.png';

import './styles.scss';
import classNames from 'classnames';
import {ROUTE_NAME} from 'constants/routes';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {toggleLoginModal} from 'appRedux/slices/appSlice';
import {isLogInSelector, userInfoSelector} from 'appRedux/selectors/auth';

const AppHeader = ({index = 0}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);
  const isLogIn = useSelector(isLogInSelector);
  const handleShowLoginModal = () => dispatch(toggleLoginModal(true));
  const menuItems = [
    {
      id: 0,
      text: 'Về Nutipoint',
      path: ROUTE_NAME.HOME,
      show: true,
    },
    {id: 1, text: 'Ưu đãi & Quà', path: ROUTE_NAME.GIFT, show: true},
    {id: 2, text: 'Giới thiệu bạn', path: ROUTE_NAME.REFERRAL, show: true},
    // {id: 6, text: 'Trò chơi', path: ROUTE_NAME.GAMES, show: true},
    {id: 3, text: 'Cửa hàng', path: ROUTE_NAME.STORE, show: true},
    // {id: 7, text: 'Varna Elite Club', path: ROUTE_NAME.VARNA, show: true},
    {
      id: 4,
      text: 'Tài khoản',
      path: ROUTE_NAME.USER_PROFILE,
      isProfile: true,
      // icon: userInfo?.avatarThumbnail ? userInfo?.avatarThumbnail : 'fa-regular fa-circle-user',
      icon: 'fa-regular fa-circle-user',
      show: isLogIn,
    },
    {id: 5, text: 'Đăng nhập / đăng ký', path: '', show: !isLogIn},
  ];
  const [isMenuActive, setIsMenuActive] = useState(false);
  return (
    <nav
      className="navbar is-primary custom-navbar py-3 fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div
        className="container"
        style={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <div
          className="navbar-brand"
          style={{
            marginLeft: 0,
          }}
        >
          <div className="level-item">
            <Link to={ROUTE_NAME.HOME}>
              <img src={logo} width={160} alt="logo" />
            </Link>
          </div>
          {isMenuActive ? (
            <a
              role="button"
              className="navbar-burger custom-navbar-burger"
              aria-label="menu"
              aria-expanded="true"
              data-target="navbarBasicExample"
              onClick={() => setIsMenuActive(!isMenuActive)}
            >
              <i className="fas fa-angle-right fa-2x"></i>
            </a>
          ) : (
            <a
              role="button"
              className="navbar-burger custom-navbar-burger"
              aria-label="menu"
              aria-expanded="true"
              data-target="navbarBasicExample"
              onClick={() => setIsMenuActive(!isMenuActive)}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          )}
        </div>

        <div
          id="navbarBasicExample"
          className={classNames('navbar-menu custom-navbar-menu', {'is-active': isMenuActive})}
        >
          <div className="navbar-end">
            {menuItems
              .filter((item) => item?.show)
              .map((item) => {
                return item?.path ? (
                  <Link
                    to={item.path}
                    key={item?.id}
                    className={classNames('navbar-item custom-navbar-item', {
                      'is-active': item?.id === index,
                    })}
                  >
                    <div
                      className={classNames('has-text-weight-semibold is-size-5', {
                        'has-text-warning': item?.id === index,
                      })}
                    >
                      {item?.icon ? (
                        <div className="icon-text">
                          {/* {userInfo?.avatarThumbnail ? (
                          <img src={item.icon} alt="Placeholder image" className="avatar" />
                        ) : (
                          <span className="icon">
                            <i className={item.icon}></i>
                          </span>
                        )} */}
                          <span className="icon">
                            <i className={item.icon}></i>
                          </span>
                          <span>{item?.text}</span>
                        </div>
                      ) : (
                        <div>{item?.text}</div>
                      )}
                    </div>
                  </Link>
                ) : (
                  <div className="has-text-white auth-wrapper">
                    <a
                      className={classNames('navbar-item', {'is-active': item?.id === index})}
                      onClick={() => {
                        handleShowLoginModal();
                        setIsMenuActive(!isMenuActive);
                      }}
                    >
                      <div className=" has-text-weight-semibold is-size-5 has-text-white">
                        Đăng nhập
                      </div>
                    </a>
                    {'|'}
                    <a
                      className={classNames('navbar-item', {'is-active': item?.id === index})}
                      onClick={() => {
                        handleShowLoginModal();
                        setIsMenuActive(!isMenuActive);
                      }}
                    >
                      <div className="has-text-weight-semibold is-size-5 has-text-white">
                        Đăng ký
                      </div>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AppHeader;
