import React, {useEffect, useState} from 'react';
import moment from 'moment';
import './styless.scss';
import {Pagination} from 'react-bulma-components';
import {useNavigate, useSearchParams} from 'react-router-dom';
import lodash from 'lodash';
import groupBy from 'group-by-with-sum';
const Remaining = ({data = [], currentPage = 1, totalPages = 1, onChangePage = () => {}}) => {
  // const groups = groupBy(data, 'giftId,name', 'remainingReward').sort((a, b) =>
  //   a.remainingReward > b.remainingReward ? 1 : -1
  // );

  return (
    <div id="reward-gift">
      <div className="has-text-centered pt-0 pb-4 head-title">PHẦN THƯỞNG CÒN LẠI</div>
      <div className="columns mx-0">
        <div className="column">
          <div className="custom-table">
            <table className="table is-bordered-custom is-fullwidth mb-1">
              <tr>
                <th>Giải thưởng</th>
                <th>Số giải còn lại</th>
              </tr>
              {data?.map((item, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>{item.rewardName}</td>
                      <td width={170}>{item?.numberOfRewards?.toLocaleString()}</td>
                    </tr>
                  </>
                );
              })}
            </table>
            <div style={{fontStyle: 'italic', fontSize: 12, fontWeight: 50}}>
              Cập nhật vào 23:00 mỗi ngày
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Remaining;
