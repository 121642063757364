import {createSlice} from '@reduxjs/toolkit';
import {
  getUserProfileAction,
  updateUserProfileAction,
  verifyOTPAction,
} from 'appRedux/actions/auth';
import {signOutAction} from 'appRedux/actions/global';
import moment from 'moment';
import {setAuthToken} from 'utils/apiUtils';
// import {setAuthToken} from 'utils/apiUtils';

const initialState = {
  userInfo: {},
  accountInfo: {},
  isLogIn: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [getUserProfileAction.fulfilled]: (state, {payload}) => {
      if (payload?.data) {
        state.userInfo = payload?.data;
        state.isLogIn = true;
      }
    },
    // [updateUserProfileAction.fulfilled]: (state, {payload}) => {
    //   const info = payload || {};
    //   state.userInfo = {
    //     ...state.userInfo,
    //     ...info,
    //     dateOfBirth: moment(info?.dateOfBirth).toISOString(),
    //   };
    // },
    [verifyOTPAction.fulfilled]: (state, {payload}) => {
      state.accountInfo = payload?.data?.data;
      state.isLogIn = true;
    },
    [signOutAction]: (state) => {
      setAuthToken();
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      state.accountInfo = {};
      state.isLogIn = false;
      state.userInfo = {};
    },
  },
});

const {actions, reducer} = authSlice;
// export const {signOutAction} = actions;

export default reducer;
