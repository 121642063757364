export const TRANSACTION_TYPE = [
  {
    label: 'Tích điểm mua hàng',
    value: 'RETAIL',
  },
  {
    label: 'Giới thiệu bạn bè',
    value: 'REFERRAL',
  },
  {
    label: 'Đánh giá sản phẩm',
    value: 'RATING',
  },
  {
    label: 'Đổi quà',
    value: 'REDEEM_GIFT',
  },
  {
    label: 'Trò chơi',
    value: 'GAME',
  },
  {
    label: 'Đăng kí thành viên',
    value: 'MEMBERSHIP_REGISTRATION',
  },
  {
    label: 'Đổi điểm',
    value: 'REWARD_EXCHANGE',
  },
  {
    label: 'Nhiệm vụ',
    value: 'MISSION',
  },
  {
    label: 'Mua dãy số',
    value: 'BUYTICKET',
  },
  {
    label: 'Mã tích điểm',
    value: 'PRODUCT_EXCHANGE_CODE',
  },
  {
    label: 'Check in',
    value: 'CHECKIN',
  },
  {
    label: 'Mời bạn đăng ký LP',
    value: 'REFERRAL_LOYALTY',
  },
  {
    label: 'Tặng điểm mời bạn',
    value: 'REFERRAL_LOYALTY_AND_PURCHASE',
  },
  {
    label: 'Tặng điểm mua hàng thường xuyên',
    value: 'PURCHASE_PREVIOUS_MONTH',
  },
];
