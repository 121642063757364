import React from 'react';
import {Pagination} from 'react-bulma-components';
import {TRANSACTION_TYPE} from 'constants/transaction.type';
import moment from 'moment';

const HistoryReward = ({currentPage = 1, totalPages = 1, onChangePage = () => {}, data = []}) => {
  return (
    <>
      <table className="table roundedCorners is-fullwidth is-hovered">
        <thead className=" has-background-grey-light">
          <tr>
            <th className="has-text-black has-text-weight-semibold pl-5">ID</th>
            <th className="has-text-black has-text-weight-semibold">Giao dịch</th>
            <th className="has-text-black has-text-weight-semibold">Thời gian</th>
            <th className="has-text-black has-text-weight-semibold">Điểm</th>
            <th className="has-text-black has-text-weight-semibold">Ghi chú</th>
          </tr>
        </thead>
        {data?.map((item, index) => (
          <tr key={`table_key_${item?.id || index}`}>
            <td className="pl-5">{item?.transactionNo}</td>
            <td className="">
              {item?.transactionType && item?.transactionType === 'RETAIL'
                ? item?.value < 0
                  ? 'Trả hàng'
                  : 'Tích điểm mua hàng'
                : item?.transactionType !== 'RETAIL'
                ? TRANSACTION_TYPE.find((x) => x.value === item.transactionType)?.label ||
                  item?.description
                : item?.description}
            </td>
            <td className="">{moment(item?.activeDateTime).format('DD/MM/YYYY')}</td>
            <td className=" has-text-weight-semibold">{`${item?.value} Điểm`}</td>
            <td>{`${item?.description}`}</td>
          </tr>
        ))}
      </table>
      <Pagination
        current={currentPage}
        showFirstLast
        total={totalPages}
        delta={2}
        showPrevNext={false}
        align={'center'}
        onChange={onChangePage}
      />
    </>
  );
};

export default HistoryReward;
