import React, {useEffect} from 'react';
import './App.scss';
import {Provider} from 'react-redux';
import {Routes, Route} from 'react-router-dom';
import store, {persistor} from './stores';
import Layout from './components/layout';
import {ROUTE_NAME} from 'constants/routes';
import {PersistGate} from 'redux-persist/integration/react';
import PageNotFound from 'pages/404';
import ReferralPhoneNumber from 'pages/referralPhoneNumber';
import HomePage from 'pages/HomePage';
import ReferralPage from 'pages/Referral';
import UserProfile from 'pages/UserProfile';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GiftPage from 'pages/VoucherList';
import GiftDetail from 'pages/VoucherList/detail';
import {localStorageGetItem} from 'utils/storage';
import {setAuthToken} from 'utils/apiUtils';
import StorePage from 'pages/store';
import GamePage from 'pages/game';
import ViewGift from 'pages/VoucherList/gift';
import VarnaPage from 'pages/Varna';
function App() {
  useEffect(() => {
    const token = localStorageGetItem('accessToken');
    setAuthToken(token);
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <Routes>
            <Route path={ROUTE_NAME.HOME} element={<HomePage />} />
            <Route path={ROUTE_NAME.GIFT} element={<GiftPage />} />
            <Route path={ROUTE_NAME.REFERRAL} element={<ReferralPage />} />
            <Route path={ROUTE_NAME.USER_PROFILE} element={<UserProfile />} />
            <Route path={ROUTE_NAME.REFERRAL_PHONE_NUMBER}>
              <Route path=":phoneNumber" element={<HomePage />} />
            </Route>

            <Route path="*" element={<PageNotFound />} />
            <Route path="/gift/:id" element={<GiftDetail />} />
            <Route path="/giftview/:id" element={<ViewGift />} />
            <Route path={ROUTE_NAME.STORE} element={<StorePage />} />
            {/* <Route path={ROUTE_NAME.GAMES} element={<GamePage />} /> */}
            <Route path={ROUTE_NAME.VARNA} element={<VarnaPage />} />
          </Routes>
          <ToastContainer />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
