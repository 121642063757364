import {appEndPoint} from 'configs/appConfigs';
import {buildRequestUrl} from 'helpers/apiHelper';

const {END_POINT_AUTH} = appEndPoint;

//#region Action Result
const actionResultUri = 'action-result';
export const ACTION_RESULT = {
  postInquire: buildRequestUrl(END_POINT_AUTH, `${actionResultUri}/inquire`),
};
//#endregion

//#region Auth
const authUri = 'auth';
const recaptcha = 'recaptcha';
export const AUTH = {
  postQuickLogin: buildRequestUrl(END_POINT_AUTH, `${authUri}/quick-login`), // /auth/enduser/api/v1/auth/quick-login
  recaptchaLogin: buildRequestUrl(END_POINT_AUTH, `${recaptcha}/verify`),
};
//#endregion

//#region Otp
const otpUri = 'otp';
export const OTP = {
  postVerify: buildRequestUrl(END_POINT_AUTH, `${otpUri}/verify`, 2),
  postVerifyRegister: buildRequestUrl(END_POINT_AUTH, `${otpUri}/verify/register`),
  postGenerate: buildRequestUrl(END_POINT_AUTH, `${otpUri}/generate`, 2),
  postNutiGenerate: buildRequestUrl(END_POINT_AUTH, `${otpUri}/nutifood/generate`),
};
//#endregion

//#region Account
const accountUri = 'account';
export const ACCOUNT = {
  postTermsAgree: buildRequestUrl(END_POINT_AUTH, `${accountUri}/terms/agree`),
  postChangePassword: buildRequestUrl(END_POINT_AUTH, `${accountUri}/change-password`),
  postUpdateProfile: buildRequestUrl(END_POINT_AUTH, `${accountUri}/update-profile`),
  putUpdateAvatar: buildRequestUrl(END_POINT_AUTH, `${accountUri}/update-avatar`),
  postRegister: buildRequestUrl(END_POINT_AUTH, `${accountUri}/register`),
  postSetPassword: buildRequestUrl(END_POINT_AUTH, `${accountUri}/set-password`),
  postResetPassword: buildRequestUrl(END_POINT_AUTH, `${accountUri}/reset-password`),
  getProfile: (merchantId) =>
    buildRequestUrl(END_POINT_AUTH, `${accountUri}/get-profile/merchant/${merchantId}`, 0),
  putUpdateFirstLogin: buildRequestUrl(END_POINT_AUTH, `${accountUri}/first-login`),
  inactiveAccount: buildRequestUrl(END_POINT_AUTH, `${accountUri}/trigger-active-user`),
  registerWithFullInfo: buildRequestUrl(
    END_POINT_AUTH,
    `${accountUri}/register/landing-page/full-info`,
    0
  ),
};
//#endregion

//#region Filter Setting
const filterSettingUri = 'filter-setting';
export const FILTER_SETTING = {
  postSaveGiftFilterSetting: buildRequestUrl(END_POINT_AUTH, `${filterSettingUri}/gift/save`),
  getGiftFilterSetting: buildRequestUrl(END_POINT_AUTH, `${filterSettingUri}/gift/get`),
};
//#endregion

//#region Notification Setting
const notificationSettingUri = 'setting/notification';
export const NOTIFICATION_SETTING = {
  postUpdateNotificationSetting: buildRequestUrl(
    END_POINT_AUTH,
    `${notificationSettingUri}/update`
  ),
  getNotificationSetting: buildRequestUrl(END_POINT_AUTH, `${notificationSettingUri}/get`),
};
//#endregion

//#region Feedback
const feedbackUri = 'feedback';
export const FEEDBACK = {
  postSendFeedback: buildRequestUrl(END_POINT_AUTH, `${feedbackUri}/send`),
};
//#endregion

//#region Favorite Brand
const favoriteBrandUri = 'favorite/brand';
export const FAVORITE_BRAND = {
  getFavoriteBrand: buildRequestUrl(END_POINT_AUTH, `${favoriteBrandUri}/get`),
  postCreateFavoriteBrand: buildRequestUrl(END_POINT_AUTH, `${favoriteBrandUri}/create`),
  putRemoveFavoriteBrand: buildRequestUrl(END_POINT_AUTH, `${favoriteBrandUri}/remove`),
};
//#endregion

//#region Suggestion Brand
const suggestionBrandUri = 'suggestion/brand';
export const SUGGESTION_BRAND = {
  getAllSuggestionBrand: buildRequestUrl(END_POINT_AUTH, `${suggestionBrandUri}/all`),
  postCreateSuggestionBrand: buildRequestUrl(END_POINT_AUTH, `${suggestionBrandUri}/create`),
  deleteRemoveSuggestionBrand: buildRequestUrl(END_POINT_AUTH, `${suggestionBrandUri}/remove`),
  postUpdateSuggestionBrand: buildRequestUrl(END_POINT_AUTH, `${suggestionBrandUri}/update`),
};
//#endregion

//#region Membership
const membershipUri = 'membership';
export const MEMBERSHIP = {
  getMembershipDefault: buildRequestUrl(END_POINT_AUTH, `${membershipUri}/default`),
  postSearchMembership: buildRequestUrl(END_POINT_AUTH, `${membershipUri}/search`),
  postSearchAllMembership: buildRequestUrl(END_POINT_AUTH, `${membershipUri}/search-all`),
  postSearchUnRegisterdMembership: buildRequestUrl(
    END_POINT_AUTH,
    `${membershipUri}/not-registered/search`
  ),
  postRegisterMembership: buildRequestUrl(END_POINT_AUTH, `${membershipUri}/register`),
  getMerchantProgramByMerchantId: buildRequestUrl(
    END_POINT_AUTH,
    `${membershipUri}/merchant-program`
  ),
  postValidateReferralMembership: buildRequestUrl(
    END_POINT_AUTH,
    `${membershipUri}/referral/validate`
  ),
  getMembershipById: (id) => buildRequestUrl(END_POINT_AUTH, `${membershipUri}/get/${id}`),
  postUnsubscribe: buildRequestUrl(END_POINT_AUTH, `${membershipUri}/unsubscribe`),
  getInactiveMembership: (programId) =>
    buildRequestUrl(END_POINT_AUTH, `${membershipUri}/get-inactive/${programId}`),
};
//#endregion

//#region Notification
const notificationUri = 'notification';
export const NOTIFICATION = {
  putUpdateNotificationReadStatus: buildRequestUrl(
    END_POINT_AUTH,
    `${notificationUri}/read-status/update`
  ),
  putUpdateAllNotificationReadStatus: buildRequestUrl(
    END_POINT_AUTH,
    `${notificationUri}/read-status/update-all`
  ),
  getNotificationDetail: buildRequestUrl(END_POINT_AUTH, `${notificationUri}/detail`),
  postNotificationToken: buildRequestUrl(END_POINT_AUTH, `${notificationUri}/save-token`),
  postNotificationSearch: buildRequestUrl(END_POINT_AUTH, `${notificationUri}/search`),
  getNotificationVerifyUnread: buildRequestUrl(END_POINT_AUTH, `${notificationUri}/check-unread`),
  eventTracking: buildRequestUrl(END_POINT_AUTH, `${notificationUri}/tracking-noti`),
};
//#endregion

//#region Referral
const referralUri = 'referral';
export const REFERRAL = {
  getReferralInfo: buildRequestUrl(END_POINT_AUTH, `${referralUri}/info`),
  getReferralInfoByPhoneNumber: buildRequestUrl(END_POINT_AUTH, `${referralUri}/get-info`, 0),
  postCreateReferral: buildRequestUrl(END_POINT_AUTH, `${referralUri}/create`),
  getValidateReferralCode: buildRequestUrl(END_POINT_AUTH, `${referralUri}/validate`),
  getReferralDynamicLinkByProgramId: buildRequestUrl(END_POINT_AUTH, `${referralUri}/dynamic-link`),
};
//#endregion

//#region Contact
const contactUri = 'contact';
export const CONTACT = {
  postCreateContact: buildRequestUrl(END_POINT_AUTH, `${contactUri}/create`),
  postSearchContact: buildRequestUrl(END_POINT_AUTH, `${contactUri}/search`),
};
//#endregion

//#region UserMembershipActivity
const userMembershipActivityUri = 'usermembership-activity';
export const USER_MEMBERSHIP_ACTIVITY = {
  createUserMembershipActivity: buildRequestUrl(
    END_POINT_AUTH,
    `${userMembershipActivityUri}/create`
  ),
};
//#endregion
