import React, {useEffect, useRef} from 'react';
import Layout from '../../components/layout';
import './styles.scss';
import {useState} from 'react';
import classNames from 'classnames';
import {isMobile} from 'react-device-detect';
import {Pagination} from 'react-bulma-components';
import {
  getTopMemberShipAction,
  searchByUserVarnaAction,
  getUserRankingVarnaAction,
  searchUserGiftVarnaAction,
  getGiftAllUserByMerchant,
  getGiftAllByMerchant,
  getGiftDetailById,
  generateUserGiftCodeAction,
  createRedeemGiftVarna,
} from 'appRedux/actions/app';
import {useDispatch, useSelector} from 'react-redux';
import {constant} from 'lodash';
import {isLogInSelector, referralLinkSelector, userInfoSelector} from 'appRedux/selectors/auth';
import moment from 'moment';
import QuickLogin from 'components/quickLoginGame';
import memberShipImage from 'images/Hạng thành viên Varna Elite Club.png';
import {get} from 'lodash';
import {rewardInfoSelector} from 'appRedux/selectors/app';
import parse from 'html-react-parser';
import {toast} from 'react-toastify';
import {toggleQRCodeModalVarna} from 'appRedux/slices/appSlice';
import {userProfileModalSelector} from 'appRedux/selectors/app';
import {QRCodeSVG} from 'qrcode.react';
import exportAsImage, {getSelectOptions} from 'helpers';
import {useNavigate, useSearchParams, Link} from 'react-router-dom';
const TAB_DATA = [
  // {
  //   index: 0,
  //   text: 'Bảng xếp hạng',
  // },
  {
    index: 1,
    text: 'Lịch sử giao dịch',
  },
  {
    index: 2,
    text: 'Quà đã đổi',
  },
];
const Index = () => {
  const dispatch = useDispatch();
  const isLogIn = useSelector(isLogInSelector);
  const [pages, setPages] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [dataMemberShip, setDataMemberShip] = React.useState([]);
  const [pageIndex, setPageIndex] = React.useState(1);
  const userInfo = useSelector(userInfoSelector);
  const [dataHistory, setDataHistory] = React.useState([]);
  const [dataRanking, setDataRanking] = React.useState({});
  const [pageIndexGift, setPageIndexGift] = React.useState(1);
  const [dataUserGift, setDataUserGift] = React.useState([]);
  const [totalUserGift, setTotalUserGift] = React.useState(0);
  const [dataGift, setDataGift] = React.useState([]);
  const userGiftParser = (item) => {
    const userGift = get(item, 'userGifts.0', {});
    return {...item, ...userGift};
  };
  const [showPopupDetail, setShowPopupDetail] = useState(false);
  const [showPopupRule, setShowPopupRule] = React.useState(false);
  const [rowId, setRowId] = useState('');
  const [formData, setFormData] = React.useState({
    giftDetail: '',
    conditionDescription: '',
    useDescription: '',
    allStores: false,
    applicableStores: [],
  });
  const rewardInfo = useSelector(rewardInfoSelector);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [qrCodeData, setQRCodeData] = useState(null);
  const [showPopupDetailView, setShowPopupDetailView] = useState(false);
  const userProfileModalData = useSelector(userProfileModalSelector);
  const [userGiftDetail, setUserGiftDetail] = useState({
    giftDetail: '',
    conditionDescription: '',
    useDescription: '',
  });
  const [redeemGift, setRedeemGift] = useState({});
  const [showPopupRedeemGift, setShowPopupRedeemGift] = useState(false);
  const qrRef = useRef();
  const qrRef1 = useRef();
  const [searchParams, _] = useSearchParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState(Number(searchParams.get('tab') || 1));
  const [isComplete, setIsComplete] = useState(false);
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    dispatch(
      getGiftAllByMerchant({
        merchantId: process.env.REACT_APP_MERCHANT_ID_VARNA,
        callback: (data) => {
          setDataGift(data);
        },
      })
    );
    dispatch(
      getUserRankingVarnaAction({
        body: {
          userId: userInfo?.id,
          programId: process.env.REACT_APP_PROGRAM_ID_VARNA,
        },
        callback: (data) => {
          setDataRanking(data.data);
        },
      })
    );
  }, [userInfo, isComplete]);
  useEffect(() => {
    dispatch(
      searchByUserVarnaAction({
        body: {
          pageIndex: pageIndex,
          pageSize: 10,
          userId: userInfo?.id,
        },
        callback: (data) => {
          setTotalPages(data.data.numOfPages);
          setDataHistory(data?.data.data || []);
        },
      })
    );
  }, [pageIndex, userInfo, success]);
  useEffect(() => {
    dispatch(
      searchUserGiftVarnaAction({
        body: {
          pageIndex: pageIndexGift,
          pageSize: 10,
          userId: userInfo?.id,
          merchantIds: [process.env.REACT_APP_MERCHANT_ID_VARNA],
          sortBy: [
            {
              fieldName: 'CreatedTime',
              ascending: true,
            },
          ],
        },
        callback: (data) => {
          setTotalUserGift(data.data.numOfPages);
          setDataUserGift(data?.data.data.map(userGiftParser) || []);
        },
      })
    );
  }, [pageIndexGift, userInfo, success]);
  const onChangePage = (page) => {
    setPageIndex(page);
    document
      .getElementById('scroller-history')
      .scrollIntoView({behavior: 'smooth', block: 'center'});
  };
  const onChangePageGift = (page) => {
    setPageIndexGift(page);
    document
      .getElementById('scroller-history')
      .scrollIntoView({behavior: 'smooth', block: 'center'});
  };
  const handleDetail = (id) => {
    dispatch(
      getGiftDetailById({
        id: id,
        callback: (data) => {
          setFormData(data);
        },
      })
    );
  };
  const handleConfirm = () => {
    setIsLoading(!isLoading);
    dispatch(
      createRedeemGiftVarna({
        body: {
          giftId: formData.id,
          quantity: 1,
          rewardId: formData.rewardId,
        },
        callback: (error, userGift) => {
          setIsLoading(false);
          if (error) {
            toast.error(error?.message || 'Không thành công! Vui lòng thử lại sau', {
              autoClose: 2000,
              position: 'top-center',
            });
            setIsComplete(!isComplete);
          } else if (userGift) {
            setShowPopupDetail(false);
            setRedeemGift(userGift);
            setShowPopupRedeemGift(true);
            setIsComplete(!isComplete);
            setSuccess(!success);
            dispatch(
              generateUserGiftCodeAction({
                userGiftId: userGift.id,
                callback: (data) => {
                  setQRCodeData(data);
                },
              })
            );
          }
        },
      })
    );
  };
  const onClickUserGift = (item) => {
    setUserGiftDetail(item);
    dispatch(
      generateUserGiftCodeAction({
        userGiftId: item.id,
        callback: (data) => {
          setQRCodeData(data);
        },
      })
    );
    setShowPopupDetailView(true);
  };
  return (
    <Layout
      headerProps={{
        index: 7,
      }}
    >
      <div className="varna-home">
        <div>
          <div className="varna-bg">
            {!isLogIn ? (
              <>
                <img src="https://storage.googleapis.com/dpoint/IDP/230530_VARNA_ELITE_WEB_VARNA%20ELITE%20CLUB_%202.png" />
                <div className="container" style={{maxWidth: 900}}>
                  <div className="columns mx-0">
                    <div className="column has-text-centered">
                      <button
                        className="button-rule"
                        onClick={() => {
                          setShowPopupRule(true);
                        }}
                      >
                        XEM THỂ LỆ CHƯƠNG TRÌNH
                      </button>
                    </div>
                  </div>
                </div>
                <div className="container ">
                  <QuickLogin
                    fromHome={true}
                    text="Đăng nhập/Đăng ký để tham gia Varna Elite Club và nhận voucher quý giá"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="position-relative">
                  <img
                    src={
                      isMobile
                        ? 'https://storage.googleapis.com/dpoint/IDP/VARNA_ELITE_MOBILE_Card.png'
                        : 'https://storage.googleapis.com/dpoint/IDP/1606_optimized.2%403x.png'
                    }
                    style={{width: '100%'}}
                  />
                  {dataRanking && (
                    <>
                      <div className="position-absolute varna-card-text">Tích lũy</div>
                      <div className="position-absolute varna-card-reward">
                        {dataRanking?.rewardValue ? dataRanking?.rewardValue?.toLocaleString() : 0}{' '}
                        đ
                      </div>
                    </>
                  )}
                </div>
                <div style={{position: 'relative'}}>
                  <div className="varna-ticker-left">
                    <img src="https://storage.googleapis.com/dpoint/IDP/dong%20trung.png" />
                  </div>
                  <div className="varna-ticker-right">
                    <img src="https://storage.googleapis.com/dpoint/IDP/BIG%20SAM%20NGOC%20LINH%20(RIGHT).png" />
                  </div>
                  <div className="container" style={{maxWidth: 900}}>
                    <div>
                      <div className="columns mx-0">
                        <div className="column has-text-centered">
                          <button
                            className="button-rule"
                            onClick={() => {
                              setShowPopupRule(true);
                            }}
                          >
                            XEM THỂ LỆ CHƯƠNG TRÌNH
                          </button>
                        </div>
                      </div>
                      <div className="columns mx-0 is-multiline is-flex-mobile">
                        {dataGift?.map((item, index) => {
                          return (
                            <>
                              <div key={index} className="column is-4-desktop is-12-mobile">
                                <div className="has-text-centered varna-vocher">
                                  <img src={item.image} />
                                  {index == 0 && (
                                    <>
                                      {/* <img src="https://storage.googleapis.com/dpoint/IDP/HOANG%20KIM.png" /> */}
                                      <div className="varna-vocher-text">
                                        Khách hàng mua varna tích lũy đủ <span>12 triệu vnd</span>
                                      </div>
                                    </>
                                  )}
                                  {index == 1 && (
                                    <>
                                      {/* <img src="https://storage.googleapis.com/dpoint/IDP/BACH%20KIM.png" /> */}
                                      <div className="varna-vocher-text">
                                        Khách hàng mua varna tích lũy đủ <span>18 triệu vnd</span>
                                      </div>
                                    </>
                                  )}
                                  {index == 2 && (
                                    <>
                                      {/* <img src="https://storage.googleapis.com/dpoint/IDP/THANH%20XUAN.png" /> */}
                                      <div className="varna-vocher-text">
                                        Khách hàng mua varna tích lũy đủ <span>36 triệu vnd</span>
                                      </div>
                                    </>
                                  )}
                                  <div className="varna-vocher-qty">
                                    Số lượng còn lại:{' '}
                                    <span>{item.remainingGifts?.toLocaleString()}</span>
                                  </div>
                                  <button
                                    disabled={item.remainingGifts < 1 ? true : false}
                                    onClick={() => {
                                      setShowPopupDetail(true);
                                      handleDetail(item.id);
                                      setIsComplete(!isComplete);
                                      setQRCodeData(null);
                                    }}
                                  >
                                    ĐỔI NGAY
                                  </button>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container ">
                  <div className="columns mx-0" id="scroller-history">
                    <div className="column">
                      <div
                        className="tabs is-fullwidth"
                        style={{maxWidth: 506, marginLeft: 'auto', marginRight: 'auto'}}
                      >
                        <ul>
                          {TAB_DATA.map((item) => (
                            <li
                              key={item.index}
                              className={classNames({
                                'is-active': selectedTabIndex === item?.index,
                              })}
                            >
                              <a onClick={() => setSelectedTabIndex(item?.index)}>
                                <span style={{fontWeight: 800}}>{item.text}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {selectedTabIndex === 1 && (
                    <div className="columns mx-0">
                      <div className="column">
                        <div className="custom-table-varna">
                          <table className="table is-bordered-custom-varna is-fullwidth">
                            <tr>
                              <th>Mã đơn hàng</th>
                              <th>Thời gian</th>
                              <th>Giao dịch</th>
                              <th>Giá trị điểm</th>
                            </tr>

                            {dataHistory?.map((item, index) => {
                              return (
                                <>
                                  <tr key={index} style={{backgroundColor: '#fff'}}>
                                    <td>{item.transactionNo}</td>
                                    <td>
                                      {' '}
                                      {item?.activeDateTime
                                        ? moment(item?.activeDateTime).format('DD/MM/YYYY HH:mm:ss')
                                        : ''}
                                    </td>
                                    <td>
                                      {item.transactionType == 'REDEEM_GIFT' && <>Đổi quà</>}
                                      {item.transactionType == 'RETAIL' &&
                                        parseInt(item?.value) == 0 && <>Mua hàng không tích điểm</>}
                                      {item.transactionType == 'RETAIL' &&
                                        parseInt(item?.value) > 0 && <>Mua hàng tích điểm</>}
                                      {item.transactionType == 'RETAIL' &&
                                        parseInt(item?.value) < 0 && <>Trả hàng</>}
                                    </td>
                                    <td>
                                      {item.directionType === 2 ? '-' : ''}
                                      {Math.abs(item?.value)?.toLocaleString()}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </table>
                          <div className="pagination-varna">
                            <Pagination
                              pages={pages}
                              current={pageIndex}
                              showFirstLast
                              total={totalPages}
                              delta={2}
                              showPrevNext={false}
                              align={'center'}
                              onChange={(page) => onChangePage(page)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {selectedTabIndex === 2 && (
                    <div style={{maxWidth: 900, marginLeft: 'auto', marginRight: 'auto'}}>
                      <div className="columns is-multiline is-flex-mobile">
                        {dataUserGift?.map((item, index) => {
                          return (
                            <>
                              <div
                                className={classNames('column is-6-desktop is-12-mobile', {
                                  'is-opacity': item.status === 2 || item.status === 9,
                                })}
                              >
                                <div className="has-text-centered varna-gift-used">
                                  <div className="varna-gift-used-relative">
                                    <img
                                      src={item?.image}
                                      onClick={() => item.status === 1 && onClickUserGift(item)}
                                      style={{cursor: 'pointer'}}
                                    />
                                    {item.status === 2 && (
                                      <>
                                        <div className="varna-gift-used-absolute">
                                          <div className="has-text-centered has-text-weight-bold">
                                            ĐÃ SỬ DỤNG
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    {item.status === 9 && (
                                      <>
                                        <div className="varna-gift-used-absolute">
                                          <div className="has-text-centered">ĐÃ HẾT HẠN</div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="text-name">{item.name}</div>
                                  {item?.status !== 2 &&
                                    moment(item?.expiryDateTime).isValid() &&
                                    item?.expiryDateTime && (
                                      <div className="text-expiry">
                                        Hạn sử dụng:{' '}
                                        <span>
                                          {moment(item.expiryDateTime).format('DD/MM/YYYY')}
                                        </span>
                                      </div>
                                    )}

                                  <div className="text-expiry">
                                    Ngày đổi Voucher:{' '}
                                    <span>{moment(item.createdTime).format('DD/MM/YYYY')}</span>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <div className="pagination-varna">
                        <Pagination
                          pages={pages}
                          current={pageIndexGift}
                          showFirstLast
                          total={totalUserGift}
                          delta={2}
                          showPrevNext={false}
                          align={'center'}
                          onChange={(page) => onChangePageGift(page)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {isMobile && (
              <>
                <div className="columns mx-0">
                  <div className="column">
                    <div className="has-text-centered">
                      <img src="https://storage.googleapis.com/dpoint/IDP/sua.png" />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div style={{position: 'relative'}}>
              {!isMobile && (
                <div>
                  <div className="columns mx-0">
                    <div className="column">
                      <div className="varna-ticker-right-bottom ">
                        <img src="https://storage.googleapis.com/dpoint/IDP/footer%20right%401.5x.png" />
                      </div>
                      <div className="varna-ticker-left-bottom">
                        <img src="https://storage.googleapis.com/dpoint/IDP/footer%20left%401.5x.png" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="varna-footer-bg">
                <div className="container pb-4">
                  <div className="columns mx-0">
                    <div className="column" style={{color: '#fff'}}>
                      <div
                        className="has-text-centered"
                        style={{paddingTop: 70, fontSize: 16, fontWeight: 700}}
                      >
                        Đại sứ Värna Elite Club là...
                      </div>
                      <div className="has-text-centered pt-3">
                        Khách hàng (thành viên của Ngôi Nhà Dinh Dưỡng) và mua sản phẩm Värna (Sữa
                        bột và Sữa bột pha sẵn)
                      </div>
                      <div className="has-text-centered pt-1">
                        bất kỳ tại Ngôi nhà Dinh Dưỡng đều được đăng ký thẻ thành viên Värna Elite
                        Club.
                      </div>
                      <div
                        className="has-text-centered pt-5"
                        style={{paddingTop: 100, fontSize: 16, fontWeight: 700}}
                      >
                        Thời gian tích lũy mua sản phẩm Värna
                      </div>
                      <div className="has-text-centered pt-3">Từ 01/06/2023 đến hết 31/12/2023</div>
                      <div className="has-text-centered pt-1">
                        Thời gian thành viên kiểm tra giá trị tích lũy hiện tại trên hệ thống bắt
                        đầu 30/06/2023
                      </div>
                      <div className="mt-5 varna-footer-border"></div>
                      {/* <div className="has-text-centered pt-5">
                        <span style={{fontWeight: 700}}>* Lưu ý:</span> Khi khách hàng đạt định mức
                        nằm trong top quy định và đạt điểm tích lũy tối thiểu của hạng mức sẽ được
                        đổi Voucher
                      </div> */}
                      {/* <div className="has-text-centered pt-1">
                        Thời gian trao Voucher tối đa 15 ngày, sau khi đổi doanh số tích lũy tại
                        Ngôi Nhà Dinh Dưỡng
                      </div> */}
                      <div
                        className="has-text-centered pt-5"
                        style={{paddingTop: 100, fontSize: 16, fontWeight: 700}}
                      >
                        Hotline: (028) 394 33 999
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames('modal', {
          'is-active': showPopupDetail,
        })}
      >
        <div className="modal-background"></div>
        <div className="varna-modal-card">
          <div className="modal-card">
            <header
              className="modal-card-head pt-2 pr-2"
              style={{background: '#fff', border: 'none', textAlign: 'right', display: 'block'}}
            >
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  setShowPopupDetail(false);
                }}
              ></button>
            </header>
            <div className="modal-card-body pt-0">
              <div className="columns">
                <div className="column is-6-tablet is-6-desktop is-12-mobile">
                  <div
                    className="card"
                    style={{
                      border: '1px solid #D2D2D2',
                    }}
                  >
                    <div className="card-image">
                      <div
                        style={{
                          position: 'relative',
                        }}
                      >
                        <div className="card-content">
                          <figure className="image is-image-voucher-detal">
                            <img
                              src={formData.image}
                              // height={118}
                            />
                          </figure>
                          <table
                            className="table is-fullwidth"
                            style={{
                              marginTop: 24,
                              marginBottom: 0,
                            }}
                          >
                            <tr>
                              <td
                                className="pl-0 has-text-weight-semibold has-text-grey"
                                style={{
                                  border: 'none',
                                }}
                              >
                                Số lượng đổi
                              </td>
                              <td
                                className="pr-0"
                                style={{
                                  textAlign: 'right',
                                  border: 'none',
                                  fontSize: 16,
                                  color: '#1D1D1D',
                                  fontWeight: 600,
                                }}
                              >
                                1 Ưu đãi
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="pl-0 has-text-weight-semibold has-text-grey"
                                style={{
                                  border: 'none',
                                }}
                              >
                                Số điểm cần
                              </td>
                              <td
                                className="pr-0 has-text-primary"
                                style={{
                                  textAlign: 'right',
                                  border: 'none',
                                  fontWeight: 700,
                                }}
                              >
                                {formData?.rewardValue ? formData?.rewardValue.toLocaleString() : 0}
                                {'đ'}
                              </td>
                            </tr>
                          </table>
                          {formData.canRedeemByMerchantReward || isLogIn ? (
                            <>
                              <div className="mt-3 has-text-centered has-text-weight-bold">
                                Bạn có:{' '}
                                {dataRanking?.rewardValue
                                  ? dataRanking?.rewardValue?.toLocaleString()
                                  : 0}
                                {'đ'}
                              </div>
                              <button
                                className={classNames(
                                  formData.canRedeemByMerchantReward
                                    ? 'button mr-3 is-primary is-fullwidth mt-4'
                                    : 'button mr-3 primary-default is-fullwidth mt-4',
                                  {
                                    'is-loading': isLoading,
                                  }
                                )}
                                style={{
                                  borderRadius: 8,
                                  fontWeight: 600,
                                }}
                                onClick={handleConfirm}
                                disabled={
                                  !isLogIn ||
                                  new Date(formData.expiryDateTime) < new Date() ||
                                  !formData.canRedeemByMerchantReward ||
                                  formData.remainingGifts === 0 ||
                                  parseInt(dataRanking?.rewardValue) <
                                    parseInt(formData?.rewardValue)
                                }
                              >
                                {formData.remainingGifts === 0
                                  ? 'QUÀ ĐÃ ĐƯỢC ĐỔI HẾT'
                                  : formData.canRedeemByMerchantReward &&
                                    parseInt(dataRanking?.rewardValue) >=
                                      parseInt(formData?.rewardValue)
                                  ? 'XÁC NHẬN ĐỔI ƯU ĐÃI'
                                  : parseInt(dataRanking?.rewardValue) <
                                    parseInt(formData?.rewardValue)
                                  ? `Bạn cần thêm ${(
                                      parseInt(formData?.rewardValue) -
                                      parseInt(dataRanking?.rewardValue)
                                    ).toLocaleString()}đ để đổi quà`
                                  : 'CHƯA ĐỦ ĐIỀU KIỆN ĐỔI'}
                              </button>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  textAlign: 'center',
                                  color: '#C62828',
                                  fontSize: 14,
                                  fontWeight: 600,
                                  paddingTop: 16,
                                }}
                              >
                                Hãy đăng nhập để đổi ưu đãi này
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-6-tablet is-6-desktop is-12-mobile">
                  <div className="title-text-has-name">Chi tiết quà tặng</div>
                  {parse(formData?.giftDetail)}
                  <div className="title-text-has-name">Hướng dẫn sử dụng</div>
                  {parse(formData?.useDescription)}
                  <div className="title-text-has-name">Điều khoản sử dụng</div>
                  {parse(formData?.conditionDescription)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames('modal', {
          'is-active': showPopupDetailView,
        })}
      >
        <div className="modal-background"></div>
        <div className="varna-modal-card">
          <div className="modal-card">
            <header
              className="modal-card-head pt-2 pr-2"
              style={{background: '#fff', border: 'none', textAlign: 'right', display: 'block'}}
            >
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  setShowPopupDetailView(false);
                }}
              ></button>
            </header>
            <div className="modal-card-body pt-0">
              <div className="columns">
                <div className="column is-6-tablet is-6-desktop is-12-mobile">
                  <div
                    className="card"
                    style={{
                      border: '1px solid #D2D2D2',
                    }}
                  >
                    <div className="card-image">
                      <div
                        style={{
                          position: 'relative',
                        }}
                      >
                        <div className="card-content">
                          <figure className="image is-image-voucher-detal">
                            <img src={userGiftDetail.image} />
                          </figure>
                          <div ref={qrRef1}>
                            <div className=" has-text-centered">
                              <div className="has-text-weight-bold has-text-centered pt-5">
                                {userGiftDetail.name}
                              </div>
                              <div className="has-text-centered ">
                                Hạn sử dụng: {''}
                                <span style={{color: '#08633D'}}>
                                  {moment(userGiftDetail?.expiryDateTime).format('DD/MM/YYYY')}
                                </span>
                              </div>
                              <div className="has-text-centered ">
                                Ngày đổi: {''}
                                <span style={{color: '#08633D'}}>
                                  {moment(userGiftDetail?.createdTime).format('DD/MM/YYYY')}
                                </span>
                              </div>

                              <div className=" pt-5 is-flex is-justify-content-center">
                                <div
                                  className="p-2 has-background-primary"
                                  style={{borderRadius: 5}}
                                >
                                  <div
                                    className="p-3 has-background-white"
                                    style={{paddingBottom: 0, borderRadius: 5}}
                                  >
                                    <QRCodeSVG value={qrCodeData?.code} />
                                  </div>
                                </div>
                              </div>
                              <div className=" has-text-centered title is-4 mt-4 mb-4">
                                {qrCodeData?.code}
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              setShowPopupDetailView(false);
                            }}
                            className={classNames(
                              'button is-primary is-fullwidth is-outlined mb-2',
                              {}
                            )}
                          >
                            Xem quà đã đổi
                          </button>
                          <button
                            className={classNames('button is-primary is-fullwidth', {
                              'is-loading': userProfileModalData?.isGeneratingQRCode,
                            })}
                            onClick={() => {
                              exportAsImage(qrRef1.current, `${userGiftDetail?.name || 'gift'}`);
                            }}
                          >
                            Tải ảnh ưu đãi về máy
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-6-tablet is-6-desktop is-12-mobile">
                  <div className="title-text-has-name">Chi tiết quà tặng</div>
                  {parse(userGiftDetail?.giftDetail)}
                  <div className="title-text-has-name">Hướng dẫn sử dụng</div>
                  {parse(userGiftDetail?.useDescription)}
                  <div className="title-text-has-name">Điều khoản sử dụng</div>
                  {parse(userGiftDetail?.conditionDescription)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames('modal', {
          'is-active': showPopupRule,
        })}
      >
        <div className="modal-background"></div>
        <div className="modal-card">
          <header
            className="modal-card-head pt-2 pr-2"
            style={{background: '#fff', border: 'none', textAlign: 'right', display: 'block'}}
          >
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                setShowPopupRule(false);
              }}
            ></button>
          </header>
          <div className="modal-card-body pt-0">
            <div className="title is-4 has-text-centered has-text-weight-bold mb-3 has-text-primary">
              THỂ LỆ CHƯƠNG TRÌNH VARNA ELITE CLUB
            </div>
            <div className="title is-4 has-text-centered has-text-weight-bold mb-3 has-text-primary">
              "Tích Lũy Mua Varna, Nhận Quà Quý Giá"
            </div>
            <div className="has-text-weight-bold has-text-primary">
              1. Thời gian diễn ra: 1/6/2023 đến 31/12/2023
            </div>
            <div className="has-text-weight-bold has-text-primary">
              2. Đối tượng áp dụng: Toàn bộ khách hàng có mua sản phẩm Varna Sữa Bột và Sữa Bột Pha
              Sẵn Tại các cửa hàng Nutifood – Ngôi Nhà Dinh Dưỡng có tham gia chương trình thành
              viên Nutipoint
            </div>
            <div className="has-text-weight-bold has-text-primary">3. Cách thức tham gia:</div>

            <p style={{fontSize: '1em'}}>
              <li>
                Bước 1 Mua Hàng: Mỗi hóa đơn có sản phẩm Varna Sữa Bột và Sữa Bột Pha Sẵn sẽ được
                tích lũy doanh số mua hàng
              </li>
              <li>
                Bước 2 Đổi mã ưu đãi: Sau khi mua hàng & tích lũy doanh số đạt hạng mức quy định,
                Khách hàng sẽ đăng nhập để kiểm tra doanh số tích lũy tại:
                https://nutipoint.nutifoodshop.com/varna-elite-club (hoặc quét QR code trên thẻ) và
                thực hiện đổi thưởng tại hạng mức quy định khi đủ điều kiện đổi thưởng. Khách hàng
                tải ảnh đổi voucher về máy. Hiệu lực đến ngày 15 của tháng kế tiếp kể từ ngày đổi mã
                voucher.
              </li>
              <li>
                Bước 3 Đăng ký nhận voucher tại NNDD: Khách hàng đem ảnh đổi quà đã lưu bước 2, đến
                cửa hàng Ngôi Nhà Dinh Dưỡng, vào định kỳ đăng ký đổi voucher cuối tháng gần nhất,
                để đăng ký nhận voucher.
              </li>
              <li>
                Bước 4 Trao voucher: NNDD sẽ trao voucher cho người đăng ký tại Bước 3 sau 15 ngày
                nhận thông tin đăng ký
              </li>
              <div className="mt-2">
                Ví dụ: Khách hàng mua sản phẩm Varna Sữa Bột hoặc Sữa Bột Pha Sẵn sẽ được tích lũy
                giá trị mua hàng (gọi tăt là doanh số) khi đạt được 18 triệu vnđ mức doanh số theo
                hạng mức trúng thưởng 18 triệu nằm trong top khách hàng đầu tiên trúng & đăng ký đổi
                giải thưởng, sẽ vào https://nutipoint.nutifoodshop.com/varna-elite-club (hoặc quét
                QR code trên thẻ) để đăng nhập và thực hiện xác nhận đổi ưu đãi vào mỗi cuối tháng,
                số tiền tích lũy sau khi đổi sẽ trở về 0 (vì trừ doanh số hạng mức 18 triệu). Sau đó
                khách hàng chụp lại thông tin này để đem đến cửa hàng NNDD gần nhất để đăng ký nhận
                voucher (lưu ý mã đổi có giá trị 15 ngày sau khi xác nhận đổi ưu đãi, quá thời gian
                này khách hàng sẽ mất lượt nhận ưu đãi & mất doanh số tích lũy đã quy đổi), NNDD sẽ
                liên hệ để gửi voucher cho khách hàng trong 15 ngày để khách hàng sử dụng. Doanh số
                tích lũy & đổi thưởng sẽ được cập nhật vào mỗi cuối tháng
              </div>
            </p>
            <div className="has-text-weight-bold has-text-primary">4. Cơ cấu phần thưởng:</div>
            <p style={{fontSize: '1em'}}>
              <li> Voucher du lịch 6 triệu: được đổi khi tích luỹ đủ 36,000,000 đồng</li>
              <li> Voucher vé máy bay 3 triệu: được đổi khi tích luỹ đủ 18,000,000 đồng</li>
              <li> Voucher sức khoẻ 1 triệu: được đổi khi tích luỹ đủ 12,000,000 đồng</li>
            </p>
            <div className="has-text-weight-bold">Ghi chú:</div>
            <p style={{fontSize: '1em'}}>
              Khách trúng trúng giải nhất có thể chọn 1 trong 4 option sau:
              <ol style={{paddingLeft: 13}}>
                <li>
                  Sau khi khách hàng đổi voucher, mốc tích luỹ sẽ bị trừ đi tương ứng với voucher
                  được đổi
                </li>
                <li>
                  Các voucher không quy đổi thành tiền mặt và chương trình có thể kết thúc sớm khi
                  hết phần thưởng.
                </li>
              </ol>
            </p>
            <div className="has-text-weight-bold has-text-primary">5. Điều kiện & Điều khoản</div>
            <div className="pb-4">
              Nutifood/DPoint là bên duy nhất chịu trách nhiệm với các vấn đề liên quan đến giải
              thưởng của chương trình này. Nếu có bất cứ sai lệch giữa thông tin hiển thị trên ứng
              dụng của người chơi và hệ thống Nutipoint thì quyết định cuối cùng sẽ dựa trên thông
              tin từ hệ thống Nutipoint.
            </div>
            <div className="pb-4">
              Nutifood/DPoint có quyền từ chối, thu hồi một phần hoặc toàn bộ giải thưởng, ngưng
              quyền tham gia chương trình của khách hàng mà không cần thông báo trước hoặc cung cấp
              thông tin giải trình lý do từ chối trả thưởng trong trường hợp phát hiện khách hàng có
              dấu hiệu gian lận để trục lợi. Trong mọi trường hợp, Nutifood/DPoint có quyền đưa ra
              quyết định cuối cùng.
            </div>
            <div className="pb-4">
              <button
                className="button is-fullwidth is-primary is-outlined has-text-weight-semibold"
                style={{border: '1px solid #08633D', borderRadius: 5}}
                onClick={() => {
                  setShowPopupRule(false);
                }}
              >
                Đóng
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={classNames('modal', {
          'is-active': showPopupRedeemGift,
        })}
      >
        <div className="modal-background"></div>
        <div className="modal-card" style={{width: 420}}>
          <header
            className="modal-card-head pt-2 pr-2"
            style={{background: '#fff', border: 'none', textAlign: 'right', display: 'block'}}
          >
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                setShowPopupRedeemGift(false);
              }}
            ></button>
          </header>
          <div className="modal-card-body pt-0">
            <div className="title is-4 has-text-centered has-text-primary has-text-weight-bold">
              ĐỔI THÀNH CÔNG
            </div>
            {true && (
              <div ref={qrRef}>
                <div className=" has-text-centered">
                  <div className="mb-2">
                    Ưu đãi:{' '}
                    <span className="has-text-primary has-text-weight-semibold">
                      {redeemGift?.name}
                    </span>
                  </div>
                  <div className="mb-2">
                    Hạn sử dụng:{' '}
                    <span className=" has-text-primary has-text-weight-semibold">
                      {redeemGift?.expiryDateTime
                        ? moment(redeemGift.expiryDateTime).format('DD/MM/YYYY')
                        : ''}
                    </span>
                  </div>
                  <div className="mb-5 subtitle has-text-weight-normal has-text-grey-dark is-7">
                    Bạn có thể gọi 028 394 33 999 hoặc ra hệ thống cửa hàng Ngôi nhà dinh dưỡng để
                    được hướng dẫn sử dụng
                  </div>
                </div>
                <div className=" is-flex is-justify-content-center">
                  <div className="p-2 has-background-primary" style={{borderRadius: 5}}>
                    <div
                      className="p-3 has-background-white"
                      style={{paddingBottom: 0, borderRadius: 5}}
                    >
                      <QRCodeSVG value={qrCodeData?.code} />
                    </div>
                  </div>
                </div>
                <div className=" has-text-centered title is-4 mt-2 mb-4">{qrCodeData?.code}</div>
              </div>
            )}
            <button
              onClick={() => {
                setShowPopupRedeemGift(false);
                setSelectedTabIndex(2);
              }}
              className={classNames('button is-primary is-fullwidth is-outlined mb-2', {})}
            >
              Về Quà đã đổi
            </button>
            <button
              className={classNames('button is-primary is-fullwidth', {
                'is-loading': userProfileModalData?.isGeneratingQRCode,
              })}
              onClick={() => {
                exportAsImage(qrRef.current, `${redeemGift?.name || 'gift'}`);
              }}
              disabled={userProfileModalData?.isLoggingIn}
            >
              Tải ảnh ưu đãi về máy
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Index;
