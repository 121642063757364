export const TAB_DATA = [
  {
    index: 0,
    text: 'Điểm thành viên',
  },
  {
    index: 1,
    text: 'Ưu đãi của tôi',
  },
  {
    index: 2,
    text: 'Lịch sử điểm',
  },
];
